import { defineStore } from "pinia";
import { computed, ref } from "vue";
import _ from "lodash";
import { ABILITY_VALUES } from "@/common/constant";

export const usePermissionsStore = defineStore(
  "permissions",
  () => {
    const initialState = {
      Name: null,
      Description: null,
      PermissionGroups: [
        {
          Name: "Activity",
          Order: 3,
          PermissionItems: [
            {
              Name: "New Activity",
              Action: "new_activity_flag",
              Values: {
                ...ABILITY_VALUES,
                Enable: true,
              },
            },
            {
              Name: "Legacy Activity",
              Action: "old_activity_flag",
              Values: {
                ...ABILITY_VALUES,
                Enable: false,
              },
            },
            {
              Name: "Own Activity",
              Action: "own_activity",
              Values: {
                View: true,
                Share: false,
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
              },
            },
            {
              Name: "Others Activity",
              Action: "others_activity",
              Values: {
                View: false,
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
            {
              Name: "Same Queue",
              Action: "same_queue",
              Values: {
                View: false,
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
          ],
        },
        {
          Name: "Channels",
          Order: 5,
          PermissionItems: [
            {
              Name: "Voice",
              Action: "voice",
              Values: {
                All: false,
                Inbound: false,
                Outbound: false,
                Enable: false,
                Create: null,
                Download: null,
                Edit: null,
                Listen: null,
                Remove: null,
                Share: null,
                View: null,
              },
            },
            {
              Name: "Chat",
              Action: "chat",
              Values: {
                All: null,
                Inbound: null,
                Outbound: null,
                Enable: null,
                Create: null,
                Download: null,
                Edit: null,
                Listen: null,
                Remove: null,
                Share: null,
                View: null,
              },
            },
            {
              Name: "Social",
              Action: "social",
              Values: {
                All: null,
                Inbound: null,
                Outbound: null,
                Enable: null,
                Create: null,
                Download: null,
                Edit: null,
                Listen: null,
                Remove: null,
                Share: null,
                View: null,
              },
            },
            {
              Name: "SMS",
              Action: "sms",
              Values: {
                All: false,
                Inbound: false,
                Outbound: false,
                Enable: false,
                Create: null,
                Download: null,
                Edit: null,
                Listen: null,
                Remove: null,
                Share: null,
                View: null,
              },
            },
            {
              Name: "Video",
              Action: "video",
              Values: {
                All: null,
                Inbound: null,
                Outbound: null,
                Enable: null,
                Create: null,
                Download: null,
                Edit: null,
                Listen: null,
                Remove: null,
                Share: null,
                View: null,
              },
            },
            {
              Name: "Email",
              Action: "email",
              Values: {
                All: null,
                Inbound: null,
                Outbound: null,
                Enable: null,
                Create: null,
                Download: null,
                Edit: null,
                Listen: null,
                Remove: null,
                Share: null,
                View: null,
              },
            },
          ],
        },

        {
          Name: "Dashboard",
          Order: 6,
          PermissionItems: [
            {
              Name: "Home tab",
              Action: "dashboard_flag",
              Values: {
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
                Enable: true,
              },
            },
            {
              Name: "Agent Performance",
              Action: "my_performance",
              Values: {
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
                Enable: false,
              },
            },
            {
              Name: "My Recent Activity",
              Action: "my_recent_activity",
              Values: {
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
                Enable: false,
              },
            },
            {
              Name: "Wait Time",
              Action: "wait_time",
              Values: {
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
                Enable: false,
              },
            },
            {
              Name: "Agent Availability",
              Action: "agent_availability",
              Values: {
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
                Enable: false,
              },
            },
            {
              Name: "Contacts",
              Action: "contacts",
              Values: {
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
                Enable: false,
              },
            },
            {
              Name: "Contacts in Queue",
              Action: "contacts_in_queue",
              Values: {
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
                Enable: false,
              },
            },
          ],
        },
        {
          Name: "Quality / Analytics",
          Order: 4,
          PermissionItems: [
            {
              Name: "Recordings",
              Action: "recordings",
              Values: {
                Listen: null,
                Download: null,
                Share: null,
                All: null,
                Create: null,
                Edit: null,
                Enable: false,
                Inbound: null,
                Outbound: null,
                Remove: null,
                View: null,
              },
            },
            {
              Name: "Transcripts",
              Action: "transcripts",
              Values: {
                Listen: null,
                Download: null,
                Share: null,
                All: null,
                Create: null,
                Edit: null,
                Enable: false,
                Inbound: null,
                Outbound: null,
                Remove: null,
                View: null,
              },
            },
            {
              Name: "Analytics",
              Action: "analytics",
              Values: {
                Listen: null,
                Download: null,
                Share: null,
                All: null,
                Create: null,
                Edit: null,
                Enable: false,
                Inbound: null,
                Outbound: null,
                Remove: null,
                View: null,
              },
            },
          ],
        },
        {
          Name: "Routing",
          Order: 0,
          PermissionItems: [
            {
              Name: "Hours of operations",
              Action: "hours_of_operations",
              Values: {
                All: false,
                View: false,
                Edit: false,
                Create: false,
                Remove: false,
                Download: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
              },
            },
          ],
        },
        {
          Name: "Communication and Presence",
          Order: 1,
          PermissionItems: [
            {
              Name: "Team Status",
              Action: "team_status",
              Values: {
                Enable: false,
                All: null,
                Create: null,
                Download: null,
                Edit: false,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
              },
            },
            {
              Name: "Announcements",
              Action: "announcements",
              Values: {
                All: false,
                View: false,
                Edit: null,
                Create: false,
                Enable: null,
                Download: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
            {
              Name: "Raise Hand",
              Action: "raise_hand",
              Values: {
                Enable: false,
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
                View: null,
              },
            },
            {
              Name: "Directory",
              Action: "directory",
              Values: {
                All: false,
                View: false,
                Edit: false,
                Create: false,
                Enable: null,
                Download: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
            {
              Name: "Silent Monitor",
              Action: "silent_monitor",
              Values: {
                All: null,
                View: null,
                Edit: null,
                Create: null,
                Enable: false,
                Download: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
            {
              Name: "Change Outbound Queue",
              Action: "change_outbound_queue",
              Values: {
                All: null,
                View: null,
                Edit: null,
                Create: null,
                Enable: false,
                Download: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
            {
              Name: "Toggle Recording",
              Action: "toggle_recording",
              Values: {
                All: null,
                View: null,
                Edit: null,
                Create: null,
                Enable: false,
                Download: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
            {
              Name: "Inline History",
              Action: "inline_history",
              Values: {
                Download: null,
                Enable: false,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
                All: null,
                View: null,
                Edit: null,
                Create: null,
                Remove: null,
              },
            },
            {
              Name: "Smart replies",
              Action: "smart_replies",
              Values: {
                All: null,
                View: null,
                Edit: null,
                Create: null,
                Enable: false,
                Download: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
          ],
        },
        {
          Name: "Users and Permissions",
          Order: 2,
          PermissionItems: [
            {
              Name: "Users",
              Action: "users",
              Values: {
                Download: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
                All: false,
                View: false,
                Edit: false,
                Create: false,
                Remove: false,
              },
            },
            {
              Name: "Security Profiles",
              Action: "security_profiles",
              Values: {
                Download: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
                All: false,
                View: false,
                Edit: false,
                Create: false,
                Remove: false,
              },
            },
            {
              Name: "Integration",
              Action: "integration",
              Values: {
                Download: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
                All: null,
                View: false,
                Edit: false,
                Create: null,
                Remove: null,
              },
            },
            {
              Name: "CustomLinks",
              Action: "customLinks",
              Values: {
                All: false,
                Edit: false,
                Create: false,
                Remove: false,
              },
            },
            {
              Name: "General",
              Action: "general",
              Values: {
                Download: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
                All: null,
                View: false,
                Edit: false,
                Create: null,
                Remove: null,
              },
            },
            {
              Name: "Channel",
              Action: "channel",
              Values: {
                Download: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
                All: null,
                View: false,
                Edit: null,
                Create: null,
                Remove: null,
              },
            },
            {
              Name: "Reporting",
              Action: "reporting",
              Values: {
                Download: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
                All: null,
                View: false,
                Edit: null,
                Create: null,
                Remove: null,
              },
            },
            {
              Name: "Global Contact",
              Action: "global_contact",
              Values: {
                Download: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Share: null,
                All: null,
                View: null,
                Edit: false,
                Create: null,
                Remove: false,
              },
            },
            {
              Name: "Scripts",
              Action: "scripts",
              Values: {
                All: null,
                View: null,
                Edit: null,
                Create: null,
                Remove: null,
              },
            },
            {
              Name: "Post call surveys",
              Action: "post_call_surveys",
              Values: {
                All: null,
                View: null,
                Edit: null,
                Create: null,
                Remove: null,
              },
            },
          ],
        },
        {
          Name: "Agent Performance Permission",
          Order: 7,
          PermissionItems: [
            {
              Name: "Own Performance",
              Action: "own_performance",
              Values: {
                View: false,
                Share: false,
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
              },
            },
            {
              Name: "All Performance",
              Action: "others_performance",
              Values: {
                View: false,
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
            {
              Name: "Performance of Agents in My Queues",
              Action: "same_queue_performance",
              Values: {
                View: false,
                All: null,
                Create: null,
                Download: null,
                Edit: null,
                Enable: null,
                Inbound: null,
                Listen: null,
                Outbound: null,
                Remove: null,
                Share: null,
              },
            },
          ],
        },
      ],
    };
    const securityProfile = ref(initialState);

    const getInstanceAdmin = computed(() => {
      let SecurityProfile = _.cloneDeep(securityProfile.value);

      SecurityProfile.id = "16dac8f1-cd08-4220-bc64-1610b3drvoip";
      SecurityProfile.Name = "IAM@";
      SecurityProfile.Description = "IAM@";
      SecurityProfile.Realm = "instance";

      let obj = SecurityProfile.PermissionGroups;
      for (let x = 0; x < obj.length; x++) {
        let objChild = obj[x];
        for (let y = 0; y < objChild.PermissionItems.length; y++) {
          let objChildSpouse = objChild.PermissionItems[y];
          let spouseToys = objChildSpouse.Values;
          for (var k in spouseToys) {
            if (!_.isNull(spouseToys[k])) {
              spouseToys[k] = true;
            }
          }
        }
      }
      return SecurityProfile;
    });
    function updateState(value) {
      securityProfile.value = value.securityProfile;
    }
    return {
      //States
      securityProfile,
      //Getters
      getInstanceAdmin,
      //Mutations
      updateState,
    };
  },
  {
    persist: true,
  }
);
