import { defineStore } from "pinia";
import { ref } from "vue";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { version } from "../../package.json";
export const useLogsStore = defineStore(
  "logs",
  () => {
    const debugMode = ref(false);
    const datadog = ref(null);

    function addItem({ level, component, text }) {
      try {
        if (datadog.value) {
          if (level === "TRACE") level = "debug";
          datadog.value.logger.log(text, { component }, level?.toLowerCase());
        }
      } catch (err) {
        console.error(err);
      }
    }
    function addSocketLog({ level, messageContext, text }) {
      try {
        if (datadog.value) {
          if (level === "TRACE") level = "debug";
          if (level === "INFO") messageContext.error = "";

          messageContext.browserSessionID = datadogLogs.getInternalContext().session_id;
          messageContext.browserName = browserName;
          datadog.value.logger.log(text, { messageContext: messageContext }, level?.toLowerCase());
        }
      } catch (err) {
        console.error(err);
      }
    }

    function setLogger(payload) {
      if (
        process.env.VUE_APP_DEXTR_STAGE === "flex" ||
        process.env.VUE_APP_DEXTR_STAGE === "qa" ||
        process.env.VUE_APP_DEXTR_STAGE === "demo"
      ) {
        datadogLogs.init({
          clientToken: "pub69d1b148261fccaaf610ced069a0de37",
          site: "datadoghq.com",
          forwardErrorsToLogs: true,
          sampleRate: 100,
          version,
          env: process.env.VUE_APP_DEXTR_STAGE,
          service: "dextrflex",
          beforeSend: excludeIgnoredLogs,
        });

        datadogRum.init({
          applicationId: "6fa96766-cc37-46c4-964e-1bf6094c9e38",
          clientToken: "puba1038bb2fc87578c3d97b1e57551c921",
          site: "datadoghq.com",
          service: "dextrflex",
          env: process.env.VUE_APP_DEXTR_STAGE,
          enableExperimentalFeatures: ["clickmap"],
          version,
          sampleRate: 100,
          premiumSampleRate: 100,
          trackInteractions: true,
          defaultPrivacyLevel: "mask-user-input",
        });

        datadogRum.setUser(payload);
        datadogRum.startSessionReplayRecording();

        datadogLogs.setLoggerGlobalContext(payload);

        datadog.value = datadogLogs;

        replaceNativeLogger();
      }
    }

    const browserName = (function (agent) {
      switch (true) {
        case agent.indexOf("edge") > -1:
          return "MS Edge";
        case agent.indexOf("edg/") > -1:
          return "Edge ( chromium based)";
        case agent.indexOf("opr") > -1 && !!window.opr:
          return "Opera";
        case agent.indexOf("chrome") > -1 && !!window.chrome:
          return "Chrome";
        case agent.indexOf("trident") > -1:
          return "MS IE";
        case agent.indexOf("firefox") > -1:
          return "Mozilla Firefox";
        case agent.indexOf("safari") > -1:
          return "Safari";
        default:
          return "other";
      }
    })(window.navigator.userAgent.toLowerCase());

    function replaceNativeLogger() {
      let sendLog = (log, details, level) => {
        let parsedLog;

        try {
          parsedLog = log + " " + JSON.stringify(details);
        } catch (err) {
          parsedLog = log + " " + details;
        }

        return addItem(null, {
          level,
          component: "dextr-logger",
          text: parsedLog,
        });
      };

      // eslint-disable-next-line no-global-assign
      console = {
        ...console,
        log: (log, details) => sendLog(log, details, "debug"),
        warn: (log, details) => sendLog(log, details, "warning"),
        error: (log, details) => sendLog(log, details, "error"),
      };
    }

    function excludeIgnoredLogs(log) {
      let ignoredMessages = [
        "getDialableCountryCodes",
        "getRoutingProfileQueues",
        "getEndpoints",
        "getAgentPermissions",
        "getAgentStates",
        "getAgentSnapshot",
        "getAgentConfiguration",
        "Sending heartbeat",
        "Heartbeat response received",
        "Empty message",
        "Heartbeat sent",
        "subscrib",
      ];

      return log.message.length > 0 && !ignoredMessages.some((msg) => log.message?.includes(msg));
    }
    return {
      //States
      debugMode,
      datadog,
      //Actions
      addItem,
      addSocketLog,
      //Mutations
      setLogger,
    };
  },
  {
    persist: true,
  }
);
