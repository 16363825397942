import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import { isEmpty } from "lodash";
import { INSTANCE } from "@connectpath/common-frontend";
import {
  getQueueMetricsQuery,
  listSkillQueuesQuery,
  getSkillsListQuery,
  queueRebalancingTransferUserMoveMutation,
  queueRebalancingTransferUserCopyMutation,
  listSkillUsersQuery,
  createSkillMutation,
  assignSkillMutation,
  removeSkillMutation,
  listConnectBackupVersionsQuery,
  createConnectBackupVersionMutation,
  deleteConnectBackupVersionMutation,
  applyConnectBackupVersionMutation,
  queueRebalancingListLogQuery,
} from "@/common/services/graphql.service";
import { useAgentsStore } from "./agents";
import { useTeamStore } from "./team";
import { useDashboardStore } from "./dashboard";
import { useCurrentUserStore } from "./current-user";

function cleanStatusName(state) {
  if (state == null) {
    return "nullState";
  }
  if (state.includes(";remote")) {
    state = state.replace(";remote", "");
  }
  return state;
}

export const useQueueStore = defineStore(
  "queue",
  () => {
    const agentStore = useAgentsStore();
    const teamStore = useTeamStore();
    const dashboardStore = useDashboardStore();
    const currentUserStore = useCurrentUserStore();
    const list = ref({
      items: [],
      summary: [],
    });
    const rebalancedQueueList = ref({
      items: [],
    });
    const rebalancingQueueMetrics = ref({});
    const showZoomOutIcon = ref(false);
    const selectedQueue = ref({
      Name: "",
      Arn: "",
      Performance: null,
      AgentPerformance: null,
      CsatPerformance: null,
      TimeSeriesData: null,
      NewMetricData: null,
    });
    const selectedQueues = ref(null);
    const globalComp = ref(null);
    const selectedRebalancedQueue = ref({
      Name: "",
      Arn: "",
      Performance: null,
      AgentPerformance: null,
      TimeSeriesData: null,
      NewMetricData: null,
    });
    const selectedItem = ref({});
    const isLoading = ref(false);
    const contactsInQueue = ref([]);
    const liveLookContacts = ref([]);
    const overallAgentsPerformance = ref({
      items: [],
    });
    const backupVersionFiles = ref([]);
    const backupReminderIsEnabled = ref(true);

    const isLoadingGetter = computed(() => isLoading.value);
    const showZoomOutIconGetter = computed(() => showZoomOutIcon.value);
    const selectedQueuesAgents = computed(() => {
      let teamList = teamStore.list.items.map((u) => {
        return {
          ...u,
          StatusName: cleanStatusName(u.StatusName),
        };
      });

      if (selectedQueue.value.Name != "dextr-global-summary" || selectedQueue.value.Name == "") {
        return teamList.filter((agent) => {
          if (agent.Queues) {
            return agent.Queues.indexOf(selectedQueue.value.Arn) > -1;
          }
          return false;
        });
      }

      if (currentUserStore.securityProfile.Realm !== INSTANCE) {
        const agentQueues = agentStore.agent.allQueueARNs;
        return teamList.filter((teamAgent) => {
          if (teamAgent.Queues) {
            return agentQueues.some((item) => teamAgent.Queues.includes(item));
          }
          return false;
        });
      }
      return teamList;
    });
    const allQueuesAgents = computed(() => {
      let teamList = teamStore.list.items.map((u) => {
        return {
          ...u,
          StatusName: cleanStatusName(u.StatusName),
        };
      });

      return teamList;
    });
    const allAgentsPerformance = computed(() => {
      if (selectedQueue.value) {
        let queue = selectedQueue.value;

        if (queue) {
          let agentPerformanceArray = queue.AgentPerformance;

          let flattenedAgentPerformanceArray = [].concat.apply([], agentPerformanceArray);

          flattenedAgentPerformanceArray = flattenedAgentPerformanceArray.filter(Boolean);

          let selectedQueuesAgents = selectedQueuesAgents.value;

          let agentPerformance = [];

          let team = teamStore.list.items;

          if (team.length > 0 && flattenedAgentPerformanceArray.length > 0) {
            let agents = [...new Set(flattenedAgentPerformanceArray.map((record) => (record || {}).Username))];

            agents = agents.filter(() => Boolean);

            let StatusName, StatusStartTimestamp;

            let userData = {};

            for (const agent in agents) {
              let user = team.find((item) => item.Username === agents[agent]);

              if (user) {
                StatusName = cleanStatusName(user.StatusName);
                StatusStartTimestamp = user.StatusStartTimestamp;

                let agentMetrics = flattenedAgentPerformanceArray.find((record) => {
                  return record.Username === agents[agent];
                });

                if (user && user.IdentityInfo && user.Username && !user.Username.startsWith("@IAM")) {
                  userData = {
                    ...agentMetrics,
                    Name: `${user.IdentityInfo.FirstName} ${user.IdentityInfo.LastName}`,
                    StatusName,
                    StatusStartTimestamp,
                  };

                  agentPerformance.push(userData);
                }
              }
            }
          }
          let conformedUsers;

          conformedUsers = selectedQueuesAgents.map((user) => {
            if (user && user.IdentityInfo) {
              return {
                Username: user.Username,
                StatusName: user.StatusName,
                StatusStartTimestamp: user.StatusStartTimestamp,
                Name: `${user.IdentityInfo.FirstName} ${user.IdentityInfo.LastName}`,
                Missed: 0,
                AvgACW: 0,
                HandledCallbacks: 0,
                AHT: 0,
                HoldDuration: 0,
                Holds: 0,
                LongestHold: 0,
                HandledIn: 0,
                HandledOut: 0,
              };
            }
          });

          conformedUsers = conformedUsers.filter((user) => user && user.Username && !user.Username.startsWith("@IAM"));

          if (conformedUsers.length > 0) {
            conformedUsers.forEach((user) => {
              user.StatusName = cleanStatusName(user.StatusName);
              if (!agentPerformance.some((e) => e.Username === user.Username)) agentPerformance.push(user);
            });
          }

          agentPerformance = agentPerformance.filter((user) => !user.Name.startsWith("IAM"));
          queue.AgentPerformance = agentPerformance;

          if (!Array.isArray(queue.Performance)) {
            queue.Performance = [queue.Performance];
          }
          if (selectedItem.value) {
            queue.Item = selectedItem.value;
          }
          return queue;
        } else return {};
      }
      return {};
    });
    const selectedQueueMetric = computed(() => {
      if (selectedQueue.value) {
        let queue = selectedQueue.value;

        if (queue) {
          let agentPerformanceArray = queue.AgentPerformance;

          let flattenedAgentPerformanceArray = [].concat.apply([], agentPerformanceArray);

          flattenedAgentPerformanceArray = flattenedAgentPerformanceArray.filter(Boolean);

          let selectedQueuesAgents = selectedQueuesAgents.value;

          let agentPerformance = [];
          let team = teamStore.list.items;

          let agents = [...new Set(flattenedAgentPerformanceArray.map((record) => (record || {}).Username))];

          agents = agents.filter(() => Boolean);

          for (const agent in agents) {
            let user = team.find((item) => item.Username === agents[agent]);
            if (!user) {
              const missingTeam = {
                Username: agents[agent],
                StatusDuration: "undefined",
                StatusStartTimestamp: new Date().formatUTC,
                StatusName: "Offline",
                IdentityInfo: { Email: agents[agent], FirstName: agents[agent], LastName: "" },
              };
              team.push(missingTeam);
            }
          }

          if (team.length > 0 && flattenedAgentPerformanceArray.length > 0) {
            let StatusName, StatusStartTimestamp;

            let userData = {};

            for (const agent in agents) {
              let user = team.find((item) => item.Username === agents[agent]);
              if (user) {
                StatusName = cleanStatusName(user.StatusName);
                StatusStartTimestamp = user.StatusStartTimestamp;

                let agentMetrics = flattenedAgentPerformanceArray.find((record) => {
                  return record.Username === agents[agent];
                });

                if (user && user.IdentityInfo && user.Username && !user.Username.startsWith("@IAM")) {
                  userData = {
                    ...agentMetrics,
                    Name: `${user.IdentityInfo.FirstName} ${user.IdentityInfo.LastName}`,
                    StatusName,
                    StatusStartTimestamp,
                    Id: user.Id,
                  };

                  agentPerformance.push(userData);
                }
              }
            }
          }
          let conformedUsers;
          conformedUsers = selectedQueuesAgents.map((user) => {
            if (user && cleanStatusName(user.StatusName) !== "Offline" && user.IdentityInfo) {
              return {
                Id: user.Id,
                Username: user.Username,
                StatusName: user.StatusName,
                StatusStartTimestamp: user.StatusStartTimestamp,
                Name: `${user.IdentityInfo.FirstName} ${user.IdentityInfo.LastName}`,
                Missed: 0,
                AvgACW: 0,
                HandledCallbacks: 0,
                AHT: 0,
                HoldDuration: 0,
                Holds: 0,
                LongestHold: 0,
                HandledIn: 0,
                HandledOut: 0,
              };
            }
          });

          conformedUsers = conformedUsers.filter((user) => user && user.Username && !user.Username.startsWith("@IAM"));

          if (conformedUsers.length > 0) {
            conformedUsers.forEach((user) => {
              user.StatusName = cleanStatusName(user.StatusName);
              if (!agentPerformance.some((e) => e.Username === user.Username)) agentPerformance.push(user);
            });
          }

          agentPerformance = agentPerformance.filter((user) => !user.Name.startsWith("IAM"));

          queue.AgentPerformance = agentPerformance;

          if (!Array.isArray(queue.Performance)) {
            queue.Performance = [queue.Performance];
          }
          if (selectedItem.value) {
            queue.Item = selectedItem.value;
          }
          return queue;
        } else return {};
      }
      return {};
    });

    const agentsAvailable = computed(() => {
      return selectedQueuesAgents.value.filter((user) => user.StatusName === "Available").length;
    });

    const agentsOnline = computed(() => {
      let agentsOnline = selectedQueuesAgents.value.filter((user) => user.StatusName !== "Offline");
      return agentsOnline.length;
    });
    const agentsBusy = computed(() => {
      let busyStates = ["Busy", "PendingBusy", "CallingCustomer"];
      return selectedQueuesAgents.value.filter((user) => busyStates.includes(user.StatusName)).length;
    });

    const agentsWrapup = computed(() => {
      return selectedQueuesAgents.value.filter((user) => user.StatusName === "AfterContactWork").length;
    });

    const agentsRelease = computed(() => {
      let nonReleasedStates = ["Available", "Busy", "PendingBusy", "Offline", "CallingCustomer", "AfterContactWork"];
      return selectedQueuesAgents.value.filter((user) => !nonReleasedStates.includes(user.StatusName)).length;
    });

    const filteredRebalancedList = computed(() => rebalancedQueueList.value);
    const overAllAgentsGetter = computed(() => overallAgentsPerformance.value);
    const getBackupVersionFiles = computed(() => backupVersionFiles.value);
    const backupReminderIsEnabledGetter = computed(() => {
      const isDisabled = localStorage.getItem("backup-reminder-enabled");
      if (isDisabled) return false;
      return backupReminderIsEnabled.value;
    });
    const getRebalancingQueueMetrics = computed(() => rebalancingQueueMetrics.value);
    const getAllQueues = computed(() => list.value.items);
    const getSelectedQueue = computed(() => selectedQueue.value);

    function getQueueByQueueName({ queueName }) {
      const allQueues = getAllQueues.value;
      const queueFound = allQueues.find((queue) => queue.Name === queueName);
      if (isEmpty(queueFound)) return;
      return queueFound;
    }

    async function onFetchQueue({ Queues, InstanceId, DateRange, IsHistorical, showLoading }) {
      try {
        if (showLoading) {
          isLoadingMutation(true);
        }

        let Period = dashboardStore.selectedFilter.value;

        let result = await getQueueMetricsQuery({
          Queues,
          InstanceId,
          DateRange,
          IsHistorical,
          Period,
        });

        setQueueList(result?.getQueueMetrics);

        let { items, summary } = result?.getQueueMetrics;
        setQueueUpdatedData(summary);
        setQueueItemData(items);
        return Promise.resolve(result?.getQueueMetrics);
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      } finally {
        isLoadingMutation(false);
      }
    }

    async function onFetechAllQueuesData({ Queues, InstanceId, DateRange, IsHistorical, showLoading, SkillIds }) {
      try {
        if (showLoading) {
          isLoadingMutation(true);
        }

        let Period = dashboardStore.selectedFilter.value;

        let result = await listSkillQueuesQuery({
          Queues,
          InstanceId,
          DateRange,
          IsHistorical,
          Period,
          SkillIds,
        });

        let queueMetricsResult = await getQueueMetricsQuery({
          Queues,
          InstanceId,
          DateRange,
          IsHistorical,
          Period,
        });

        let summary = queueMetricsResult?.getQueueMetrics?.summary;
        if (summary) {
          result.listSkillQueues.summary = {
            ...result?.listSkillQueues.summary,
            ...queueMetricsResult?.getQueueMetrics?.summary,
            CsatPerformance: summary.CsatPerformance,
          };
        }
        setQueueUpdatedData(result?.listSkillQueues.summary);
        setRebalancedQueueList(result?.listSkillQueues);
        return Promise.resolve(result?.listSkillQueues);
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      } finally {
        isLoadingMutation(false);
      }
    }

    async function getSkillsList({ InstanceId }) {
      return await getSkillsListQuery({
        InstanceId,
      });
    }
    async function queueRebalancingTransferUserMove(input) {
      return await queueRebalancingTransferUserMoveMutation(input);
    }
    async function queueRebalancingTransferUserCopy(input) {
      return await queueRebalancingTransferUserCopyMutation(input);
    }
    async function onFetchAgents({ InstanceId, SkillIds }) {
      const res = await listSkillUsersQuery({ InstanceId, SkillIds });

      return res?.listSkillUsers;
    }

    async function createSkills(payload) {
      let result = await createSkillMutation(payload);

      return result;
    }

    async function addSkillsInQueues(payload) {
      let result = await assignSkillMutation(payload);
      return result;
    }
    async function addSkillFilters(payload) {
      let result = await listSkillUsersQuery(payload);

      return result;
    }

    async function addSkillFiltersForQueues(payload) {
      let result = await listSkillQueuesQuery(payload);
      return result;
    }

    async function deleteSkillsInQueues(payload) {
      let result = await removeSkillMutation(payload);

      return result;
    }

    async function onFetchBackupVersions() {
      isLoadingMutation(true);

      const { InstanceId } = agentStore.agentSummary;

      try {
        const {
          listConnectBackupVersions: { items },
        } = await listConnectBackupVersionsQuery({ InstanceId });

        setBackupVersionFiles(items);

        return items;
      } catch (error) {
        console.error(error);
      } finally {
        isLoadingMutation(false);
      }
    }

    async function createBackupVersion(payload) {
      try {
        await createConnectBackupVersionMutation(payload);

        onFetchBackupVersions();
      } catch (error) {
        console.error(error);
      }
    }
    async function deleteBackupVersion(payload) {
      const { VersionNumber } = payload;

      const oldFiles = getBackupVersionFiles.value;
      const newFiles = oldFiles.filter((file) => file.VersionNumber !== VersionNumber);

      setBackupVersionFiles(newFiles);

      try {
        await deleteConnectBackupVersionMutation(payload);

        onFetchBackupVersions();
      } catch (error) {
        console.error(error);
      }
    }

    async function applyBackupVersion(payload) {
      await applyConnectBackupVersionMutation(payload);
    }

    async function getStatsForRebalancing({ Queues, InstanceId, DateRange, IsHistorical }) {
      try {
        let result = await getQueueMetricsQuery({ Queues, InstanceId, DateRange, IsHistorical });

        setRebalancedQueueMetrics(result?.getQueueMetrics.summary.NewMetricData);
        return Promise.resolve(result?.getQueueMetrics.summary.NewMetricData);
      } catch (err) {
        console.error(err);
        return Promise.reject(err);
      }
    }

    async function queueRebalancingListLog(payload) {
      isLoadingMutation(true);

      const { InstanceId } = agentStore.agentSummary;

      const [startDate, endDate] = payload;

      const {
        queueRebalancingListLog: { Items },
      } = await queueRebalancingListLogQuery({
        input: {
          InstanceId,
          DateRange: [startDate, endDate],
        },
      });

      isLoadingMutation(false);

      return Items;
    }
    function addNewSelectedQueue(payload) {
      setSelectedQueue(payload);
    }

    function setLiveLookData(payload) {
      const { Contacts, Action } = payload;

      if (Contacts.length > 0) {
        let liveLookContacts = liveLookContacts.value;

        if (Action == "INSERT") {
          Contacts.forEach((contact) => {
            const index = liveLookContacts.findIndex((i) => i.id == contact.id);
            if (index < 0) {
              liveLookContacts.push(contact);
            }
          });
        } else if (Action == "REMOVE") {
          Contacts.forEach((contact) => {
            const index = liveLookContacts.findIndex((i) => i.id == contact.id);
            if (index > -1) {
              liveLookContacts.splice(index, 1);
            }
          });
        }

        liveLookContacts.value = liveLookContacts;
      }
    }

    function setQueueUpdatedData(payload) {
      selectedQueue.value = payload;
      if (selectedQueue.value.Name === "dextr-global-summary") {
        Vue.set(overallAgentsPerformance.value, "items", payload);
      }
    }

    function setQueueItemData(payload) {
      selectedItem.value = payload;
    }

    function setShowZoomOutIcon(payload) {
      showZoomOutIcon.value = payload;
    }
    function setSelectedQueue(payload) {
      if (payload) {
        selectedQueue.value = payload;
      }
    }

    function setSelectedRebalancedQueue(payload) {
      if (payload) {
        selectedRebalancedQueue.value = payload;
      }
    }

    function setOverAllAgents(payload) {
      overallAgentsPerformance.value = payload;
    }

    function setSelectedQueueItem(payload) {
      if (payload) {
        selectedItem.value = payload;
      }
    }

    function setQueueList(payload) {
      Vue.set(list.value, "items", payload.items);
    }

    function setRebalancedQueueList(payload) {
      Vue.set(rebalancedQueueList.value, "items", payload.items);
      Vue.set(rebalancedQueueList.value, "summary", payload.summary);
    }
    function isLoadingMutation(payload) {
      isLoading.value = payload;
    }
    function updateState(value) {
      list.value = value.list;
      selectedQueue.value = value.selectedQueue;
      selectedQueues.value.values = value.selectedQueues;
      isLoading.value = value.isLoading;
      globalComp.value = value.globalComp;
      contactsInQueue.value = value.contactsInQueue;
      liveLookContacts.value = value.liveLookContacts;
    }

    function setBackupVersionFiles(payload) {
      backupVersionFiles.value = payload;
    }
    function disableBackupReminder() {
      localStorage.setItem("backup-reminder-enabled", "false");
      backupReminderIsEnabled.value = false;
    }

    function setRebalancedQueueMetrics(payload) {
      rebalancingQueueMetrics.value = payload;
    }
    return {
      //States
      list,
      rebalancedQueueList,
      rebalancingQueueMetrics,
      showZoomOutIcon,
      selectedQueue,
      selectedQueues,
      globalComp,
      selectedRebalancedQueue,
      selectedItem,
      isLoading,
      contactsInQueue,
      liveLookContacts,
      overallAgentsPerformance,
      backupVersionFiles,
      backupReminderIsEnabled,
      //Getters
      isLoadingGetter,
      showZoomOutIconGetter,
      selectedQueuesAgents,
      allQueuesAgents,
      allAgentsPerformance,
      selectedQueueMetric,
      agentsAvailable,
      agentsOnline,
      agentsBusy,
      agentsWrapup,
      agentsRelease,
      filteredRebalancedList,
      overAllAgentsGetter,
      getBackupVersionFiles,
      backupReminderIsEnabledGetter,
      getRebalancingQueueMetrics,
      getAllQueues,
      getSelectedQueue,
      //Actions
      getQueueByQueueName,
      onFetchQueue,
      onFetechAllQueuesData,
      getSkillsList,
      queueRebalancingTransferUserMove,
      queueRebalancingTransferUserCopy,
      onFetchAgents,
      createSkills,
      addSkillsInQueues,
      addSkillFilters,
      addSkillFiltersForQueues,
      deleteSkillsInQueues,
      onFetchBackupVersions,
      createBackupVersion,
      deleteBackupVersion,
      applyBackupVersion,
      getStatsForRebalancing,
      queueRebalancingListLog,
      addNewSelectedQueue,
      //Mutations
      setLiveLookData,
      setQueueUpdatedData,
      setQueueItemData,
      setShowZoomOutIcon,
      setSelectedQueue,
      setSelectedRebalancedQueue,
      setOverAllAgents,
      setSelectedQueueItem,
      setQueueList,
      setRebalancedQueueList,
      isLoadingMutation,
      updateState,
      setBackupVersionFiles,
      disableBackupReminder,
      setRebalancedQueueMetrics,
    };
  },
  {
    persist: true,
  }
);
