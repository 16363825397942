import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import * as Peer from "simple-peer";
const Socket = () => import("@/common/socket.js");
import { PeerCallStatus } from "@/common/constant";
import OutgoingRingtone from "@/assets/Ringing_Phone-8.5decibels.mp3";
import availableTones from "@/assets/tones";
import tonesSecondary from "@/assets/tones-c";

const ac = new AudioContext();

import Favico from "favico.js";
import { Notice } from "view-design";
import _ from "lodash";

import { i18n } from "@/plugins/language";
import { USERS_INTEGRATIONS_LIMIT, USERS_DEFAULT_LIMIT, AGENT_TO_AGENT_CHIME_CALL } from "@connectpath/common";
import { getTimeObject } from "@/utils/time/getTimeObject";
import {
  newInitiatorCandidateMutation,
  updateUserMutation,
  newPeerCandidateMutation,
  listTeamQuery,
  listSkillUsersQuery,
  createActivityMutation,
  updateActivityMutation,
  updateFavoriteItemsMutation,
  listUsersIntegrationsQuery,
  onDeleteUserIntegrationsSubscription,
  onUpdateUserIntegrationsSubscription,
  onCreateUserIntegrationsSubscription,
} from "@/common/services/graphql.service";
import { useIntegrationsStore } from "./integrations";
import { useCurrentUserStore } from "./current-user";
import { useLogsStore } from "./logs";
import { useChatStore } from "./chat";
import { useAgentsStore } from "./agents";
import { useActivityStore } from "./activity";
import { useApiStore } from "./api";
import { useMediaDevicesStore } from "./mediaDevices";
import { useIndexStore } from ".";
import { useAgentCallStore } from "./agent-call";

const favicon = new Favico({
  fontFamily: "Ionicons",
  animation: "none",
});

const getNetworkTraversal = (creds) => {
  return creds.iceServers;
};
const sortUsersByFullName = ({ users }) => {
  return users.sort((a, b) => {
    let nameA = a.IdentityInfo
      ? `${a.IdentityInfo.FirstName?.toUpperCase()}${a.IdentityInfo.LastName?.toUpperCase()}`
      : `${a.FirstName?.toUpperCase()}${a.LastName?.toUpperCase()}`;
    let nameB = b.IdentityInfo
      ? `${b.IdentityInfo.FirstName?.toUpperCase()}${b.IdentityInfo.LastName?.toUpperCase()}`
      : `${b.FirstName?.toUpperCase()}${b.LastName?.toUpperCase()}`;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const useTeamStore = defineStore(
  "team",
  () => {
    const integrationsStore = useIntegrationsStore();
    const logStore = useLogsStore();
    const chatStore = useChatStore();
    const agentStore = useAgentsStore();
    const currentUserStore = useCurrentUserStore();
    const activityStore = useActivityStore();
    const apiStore = useApiStore();
    const mediaDeviceStore = useMediaDevicesStore();
    const indexStore = useIndexStore();
    const agentCallStore = useAgentCallStore();
    const unreadList = ref([]);
    const updatedUnread = ref(false);
    const userName = ref("");
    const previousUserName = ref("");
    const favoriteList = ref([]);
    const list = ref({
      items: [],
    });
    const allAgentsList = ref({
      items: [],
    });
    const isLoadConnect = ref(false);
    const spectate = ref({
      user: null,
      isConnected: false,
      type: "",
    });
    const showVoiceCallModalFlag = ref(false);
    const callMissedFlag = ref(true);
    const callRejectedFlag = ref(true);
    const callPlacedFlag = ref(false);
    const answerProcessLoader = ref(false);
    const voiceCallData = ref(null);
    const voiceCallDuration = ref(null);
    const voiceCallDurationCounterIntervalId = ref(null);
    const voiceCallDurationAgentToAgent = ref(0);
    const voiceCallDurationAgentToAgentCounterIntervalId = ref(null);
    const currentStream = ref(null);
    const inComingRingtone = ref(null);
    const secondaryIncomingRingtone = ref(null);
    const outgoingRingtone = ref(null);
    const callStatus = ref(PeerCallStatus.InitialState);
    const currentCTRId = ref(null);
    const range = ref(null);
    const callId = ref(null);
    const DisconnectTimestamp = ref(null);
    const timeOutId = ref(null);
    const showErrorMessage = ref(false);
    const errorMessage = ref(null);
    const coturnCreds = ref({});
    const monitorInit = ref({});
    const unreadStatus = ref(true);
    const externalUsersIntegrationsList = ref([]);
    const allUsers = ref([]);
    const totalAmountOfUsers = ref(0);
    const totalAmountOfUsersIntegration = ref(0);
    const countUserTeam = ref(0);
    const pageNumber = ref(0);
    const teamCursor = ref(null);
    const teamIntegrationCursor = ref(null);
    const listToSearch = ref(null);
    const recentUsersRecently = ref([]);
    const singleUser = ref([]);
    const favoriteUsers = ref([]);

    const allExternalUsersFromIntegrations = computed(() => {
      return sortUsersByFullName({ users: externalUsersIntegrationsList.value });
    });
    const listUsersWithoutCurrentUser = computed(() => {
      if (!list.value?.items?.length) return [];
      return list.value.items.filter((user) => user.Username && user.Username !== agentStore.agentUsername);
    });
    const allUsersList = computed(() => {
      const isTeamsIntegrationActive = integrationsStore.getIsTeamIntegrationActive;
      if (!isTeamsIntegrationActive) return sortUsersByFullName({ users: listUsersWithoutCurrentUser.value });
      const externalUsersIntegrationsListWithoutCurrentUser = externalUsersIntegrationsListWithoutCurrentUser.value;
      const emailsFromDextrUsers = new Set(listUsersWithoutCurrentUser.value.map((obj) => obj.IdentityInfo.Email));
      const filteredExternalUsers = externalUsersIntegrationsListWithoutCurrentUser.filter(
        (obj) => !emailsFromDextrUsers.has(obj.Email)
      );
      const filteredExternalUsersSortedByFullName = sortUsersByFullName({ users: filteredExternalUsers });
      const allowedQtyExternalUsers = filteredExternalUsersSortedByFullName.slice(
        0,
        USERS_INTEGRATIONS_LIMIT - listUsersWithoutCurrentUser.value.length
      );
      const allUsers = [...listUsersWithoutCurrentUser.value, ...allowedQtyExternalUsers];
      return sortUsersByFullName({ users: allUsers });
    });

    const externalUsersIntegrationsListWithoutCurrentUser = computed(() => {
      const currentUser = currentUserStore;
      return externalUsersIntegrationsList.value.filter((obj) => obj.Email !== currentUser?.IdentityInfo.Email);
    });

    const isConnectingInitiator = computed(() => isLoadConnect.value);
    const spectating = computed(() => spectate.value);
    const voiceCallDataGetter = computed(() => voiceCallData.value);
    const getAnswerProcessLoader = computed(() => answerProcessLoader.value);
    const teamList = computed(() => list.value?.items || []);
    const team = computed(() => {
      if (list.value) {
        if (list.value.items) {
          const theTeam = list.value.items.filter((user) => {
            if (user.Username) {
              return user.Username && user.Username !== agentStore.agentUsername;
            }
          });

          let data = theTeam.sort((a, b) => {
            let nameA = a.Username.toUpperCase();
            let nameB = b.Username.toUpperCase();
            return nameA < nameB ? -1 : 1;
          });

          return data;
        }
      }

      return [];
    });
    const getAllAgentsList = computed(() => allAgentsList.value.items);
    const getAgentByUsername = (Username) => {
      return _.get(list.value, "items", []).find((agent) => agent.Username === Username);
    };
    const getCallStatus = computed(() => callStatus.value);
    const getUserName = computed(() => userName.value);
    const getTeamIntegratiopCursor = computed(() => teamIntegrationCursor.value);
    const getTeamCursor = computed(() => teamCursor.value);

    const getTotalAmountOfUsers = computed(() => {
      const totalOfUsers = totalAmountOfUsersIntegration.value + totalAmountOfUsers.value;
      return totalAmountOfUsers.value > countUserTeam.value ? totalOfUsers : countUserTeam.value;
    });
    const getTotalAmountOfUsersIntegrations = computed(() => totalAmountOfUsersIntegration.value);
    const getRecordsPerPage = computed(() => {
      const maxCount = totalAmountOfUsers.value > countUserTeam.value ? totalAmountOfUsers.value : countUserTeam.value;
      return Math.round(maxCount / 6);
    });
    const getPageNumber = computed(() => pageNumber.value);
    const getRecentUsersRecently = computed(() => recentUsersRecently.value);
    const getFavoriteUsers = computed(() => favoriteUsers.value);
    const getSingleUser = computed(() => singleUser.value);

    function setExternalUsersFromIntegrationsList(payload) {
      setExternalUsersFromIntegrationsListMutation(payload);
    }
    async function setupVoiceCall(payload) {
      const Username = currentUserStore.getProfileData.Username;
      let voiceData = {
        instanceId: payload.InstanceId,
        instanceAlias: payload.instanceAlias,
        callee: payload.Username,
        initiator: Username,
        token: payload.token,
        callUUID: payload.uuid,
        action: "setupVoiceCall",
      };
      setCurrentCallId(voiceData.callUUID);

      const logItemPayload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        messageContext: {
          data: voiceData,
          source: "setupVoiceCall",
        },
      };

      try {
        setVoiceCallModalFlag(true);
        setVoiceCallData(voiceData);
        voiceData.callUUID = payload.uuid;
        setCallStatus(PeerCallStatus.Connecting);

        let iceServers = getNetworkTraversal(coturnCreds.value);

        let local_stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });

        setCurrentStream(local_stream);

        const params = {
          initiator: true,
          trickle: false,
          streams: [local_stream],
          config: {
            iceServers: iceServers,
          },
        };
        window.vPeerInitiator = new Peer(params);

        window.vPeerInitiator.on("signal", async (data) => {
          logItemPayload.level = "INFO";
          logItemPayload.messageContext.remoteOffer = data;
          logItemPayload.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [setupVoiceCall] - Signal recieved from remote peer - Initiator.`;
          logStore.addSocketLog(logItemPayload);
          if (data.type === "offer") {
            voiceData["OfferSDP"] = JSON.stringify(data);

            try {
              let requestData = {
                action: "setupVoiceCall",
                ...voiceData,
              };
              Socket.send(JSON.stringify(requestData));
              playOutgoingRingtone();
              setCallPlacedFlag(true);
            } catch (error) {
              console.error("Error in setupVoiceCall Emit: ", error);
              stopOutgoingRingtone();
            }
          } else if (data.candidate) {
            return await newInitiatorCandidateMutation({
              ConversationId: voiceData.callUUID,
              Candidate: data.candidate,
            });
          }
        });

        window.vPeerInitiator.on("error", function (error) {
          showErrorMessageAction("An error has occurred while establishing the call.");
          stopOutgoingRingtone();
          destroyVoiceCall();
          logItemPayload.level = "ERROR";
          logItemPayload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
          logItemPayload.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [${voiceData.action}] - Error occured in the initiators's peer connection - Initiator.`;
          logStore.addSocketLog(logItemPayload);
        });

        window.vPeerInitiator.on("connect", function () {
          window.vPeerInitiator.send("hey peer, how is it going?");
          stopOutgoingRingtone();
          spectate.value.isConnected = false;
          setCallMissedFlag(false);

          destroyCallTimer();
          startVoiceCallDurationCounter("AgentToAgent");
          setCallStatus(PeerCallStatus.Connected);

          logItemPayload.level = "INFO";
          logItemPayload.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [${voiceData.action}] - Peer connection readied for a peer call - Initiator.`;
          logStore.addSocketLog(logItemPayload);

          const callerCTR = {
            Channel: "voice",
            InitiationMethod: "OUTBOUND",
            InitiationTimestamp: getTimeObject().offsetToISOString(),
            Type: "on-net",
            Username: Username,
            CustomerEndpoint: payload.Username,
            InstanceId: payload.InstanceId,
          };
          createCTR(callerCTR);
        });

        window.vPeerInitiator.on("stream", function (stream) {
          window.mediaTech.audioElement.srcObject = stream;
          window.mediaTech.audioElement.play();
          logItemPayload.level = "INFO";
          logItemPayload.messageContext.stream = stream;
          logItemPayload.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [${voiceData.action}] - Recieved the remote stream for peer call - Initiator.`;
          logStore.addSocketLog(logItemPayload);
        });

        window.vPeerInitiator.on("close", () => {
          stopOutgoingRingtone();
          logItemPayload.level = "INFO";
          logItemPayload.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [${voiceData.action}] - Peer connection has been closed - Initiator.`;
          logStore.addSocketLog(logItemPayload);
          if (!callMissedFlag.value) {
            if (range.value !== null && currentCTRId.value !== null) {
              const updateCTR = {
                id: currentCTRId.value,
                range: range.value,
                InstanceId: payload.InstanceId,
                DisconnectTimestamp: getTimeObject().offsetToISOString(),
              };
              updateCTR(updateCTR);
              setDisconnectTimestamp(null);
            } else {
              setDisconnectTimestamp(getTimeObject().offsetToISOString());
            }
          } else {
            const callerCTR = {
              Channel: "voice",
              InitiationMethod: callRejectedFlag.value ? "REJECTED" : "MISSED",
              InitiationTimestamp: getTimeObject().offsetToISOString(),
              Type: "on-net",
              Duration: 0,
              Username: Username,
              CustomerEndpoint: payload.Username,
              InstanceId: payload.InstanceId,
              DisconnectTimestamp: getTimeObject().offsetToISOString(),
            };
            createCTR(callerCTR);
          }
          setCallMissedFlag(true);
          callRejectedFlag.value = true;
          destroyVoiceCall();
          if (window.vPeers) {
            destroyPeer();
          }
        });
      } catch (error) {
        logItemPayload.level = "ERROR";
        logItemPayload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        logItemPayload.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [${voiceData.action}] - Error occured in setupVoiceCall.`;
        logStore.addSocketLog(logItemPayload);
      }
    }

    function destroyInitiator(payload) {
      stopOutgoingRingtone();
      let requestData = {
        action: "deleteMonitor",
        instanceId: window.special_token.payload["custom:InstanceId"],
        token: Socket.specialToken?.encodedToken,
        initiator: currentUserStore.getProfileData?.Username,
        callee: spectate.value.user?.Username,
        endedBy: "initiator",
      };
      if (payload.type === "whisperCoach" || payload === "whisperCoach") {
        Socket.send(JSON.stringify(requestData));
        if (window.mPeerInitiator?._channel?.readyState === "open") {
          window.mPeerInitiator.send("destroyCoach");
        }
        deleteMonitorInitiator();
      } else if (payload.type === "silentMonitor") {
        Socket.send(JSON.stringify(requestData));
        if (window.mPeerInitiator?._channel?.readyState === "open") {
          window.mPeerInitiator.send("destroyMonitor");
        }
        deleteMonitorInitiator();
      }
    }

    function deleteMonitorInitiator() {
      setTimeout(() => {
        if (window.mPeerInitiator) {
          window.mPeerInitiator.destroy();
          window.mPeerInitiator = null;
        }
      }, 500);
      setCallStatus(PeerCallStatus.InitialState);
      setMonitorInit({});
      spectate.value.user = null;
      spectate.value.type = "";
      spectate.value.isConnected = false;
      isLoadConnect.value = false;
    }

    function destroyVoiceCall() {
      if (window.vPeerInitiator) {
        window.vPeerInitiator.destroy();
        window.vPeerInitiator = null;
      }
      spectate.value.user = null;
      spectate.value.isConnected = false;
      isLoadConnect.value = false;
      stopVoiceCallDurationCounter("AgentToAgent");
      stopOutgoingRingtone();
      destroyCallTimer();
      setCallStatus(PeerCallStatus.InitialState);
      setVoiceCallData(null);
    }

    function destroyPeer() {
      if (window.vPeers) {
        window.vPeers.destroy();
        window.vPeers = null;
      }
      spectate.value.user = null;
      spectate.value.isConnected = false;
      isLoadConnect.value = false;
      setCallStatus(PeerCallStatus.InitialState);
      setAnswerProcessLoader(false);
      setVoiceCallModalFlag(false);
      stopIncomingRingtone();

      activityStore.setHangingActivity(null);

      stopVoiceCallDurationCounter("AgentToAgent");
      setCallPlacedFlag(false);
      setCallMissedFlag(true);
      setCurrentCTRId(null);
      setRange(null);

      setTimeout(() => {
        activityStore.onFetchRecentActivity({
          InstanceId: agentStore.agentSummary.InstanceId,
          Username: agentStore.agentSummary.Username,
        });
      }, 3000);
    }

    async function fireInitiator(payload) {
      const logItemPayloadMonitor = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        messageContext: {},
        text: "",
      };

      logItemPayloadMonitor.messageContext.data = {
        token: Socket.specialToken?.encodedToken,
        callUUID: payload.uuid,
        instanceId: payload.userData.InstanceId,
        callee: payload.userData.Username,
        initiator: currentUserStore.getProfileData.Username,
        localState: spectate.value,
      };

      try {
        if (window.mPeerInitiator || (spectate.value?.isConnected ?? false)) {
          destroyInitiator({ type: "whisperCoach" });
        }

        spectate.value.user = payload.userData;
        isLoadConnect.value = true;
        setCallStatus(PeerCallStatus.Connecting);
        let iceServers = getNetworkTraversal(coturnCreds.value);

        let local_stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: { deviceId: "communications" },
        });

        window.local_track = local_stream.getAudioTracks()[0];

        if (payload.action === "silentMonitor") {
          window.local_track.enabled = false;
        } else {
          window.local_track.enabled = true;
        }

        const params = {
          initiator: true,
          trickle: false,
          streams: [local_stream],
          config: {
            iceServers: iceServers,
          },
        };

        window.mPeerInitiator = new Peer(params);

        window.mPeerInitiator.on("signal", (data) => {
          logItemPayloadMonitor.level = "INFO";
          logItemPayloadMonitor.messageContext.data.remoteData = data;
          logItemPayloadMonitor.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [fireInitiator] : Monitoring signal recieved from remote peer - Initiator`;
          logStore.addSocketLog(logItemPayloadMonitor);

          if (data.type === "offer") {
            try {
              let requestData = {
                action: "createMonitor",
                token: Socket.specialToken?.encodedToken,
                callUUID: payload.uuid,
                instanceId: payload.userData.InstanceId,
                callee: payload.userData.Username,
                OfferSDP: JSON.stringify(data),
                initiator: currentUserStore.getProfileData.Username,
              };
              Socket.send(JSON.stringify(requestData));
            } catch (error) {
              console.error("Error in createMonitor Emit: ", error);
              throw error;
            }
          }
        });

        window.mPeerInitiator.on("data", (data) => {
          logItemPayloadMonitor.level = "INFO";
          logItemPayloadMonitor.messageContext.data.remoteData = data;
          logItemPayloadMonitor.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [fireInitiator] : Message recieved from remote peer being monitored - Initiator`;
          logStore.addSocketLog(logItemPayloadMonitor);

          if (String.fromCharCode.apply(null, new Uint16Array(data)) === "callEnded") {
            destroyInitiator({ type: spectate.value.type });
            clearTimeout(timeout);
          } else {
            window.mPeerInitiator.signal(data);
          }
        });

        window.mPeerInitiator.on("error", function (error) {
          logItemPayloadMonitor.level = "ERROR";
          logItemPayloadMonitor.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
          logItemPayloadMonitor.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [fireInitiator] : Error occured in the monitor initiators's peer connection.`;
          logStore.addSocketLog(logItemPayloadMonitor);

          Notice.error({
            title: i18n.t("notifications.connectionLost"),
            name: "error-notice-silent-monitor",
            desc: i18n.t("notifications.thereWasANetworkIssue"),
            duration: 5,
          });
          destroyInitiator({ type: "whisperCoach" });
        });

        window.mPeerInitiator.on("close", function () {
          logItemPayloadMonitor.level = "INFO";
          logItemPayloadMonitor.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [fireInitiator] : Peer connection closed for monitor's initiator.`;
          logStore.addSocketLog(logItemPayloadMonitor);

          isLoadConnect.value = false;
        });

        window.mPeerInitiator.on("connect", function () {
          logItemPayloadMonitor.level = "INFO";
          logItemPayloadMonitor.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [fireInitiator] : Peer connection readied for a monitoring session - Initiator`;
          logStore.addSocketLog(logItemPayloadMonitor);

          isLoadConnect.value = false;
          spectate.value.isConnected = true;
          spectate.value.type = payload.action;
          window.mPeerInitiator.send(payload.action);
          setCallStatus(PeerCallStatus.Connected);
          clearTimeout(timeout);
        });

        window.mPeerInitiator.on("stream", function (stream) {
          logItemPayloadMonitor.level = "INFO";
          logItemPayloadMonitor.messageContext.data.remoteData = stream;
          logItemPayloadMonitor.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [fireInitiator] : Recieved the remote stream for monitoring - Initiator.`;
          logStore.addSocketLog(logItemPayloadMonitor);

          window.mediaTech.audio.srcObject = stream;
          window.mediaTech.audio.play();
        });
      } catch (error) {
        logItemPayloadMonitor.level = "ERROR";
        logItemPayloadMonitor.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        logItemPayloadMonitor.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [fireInitiator] : Error occured in fireInitiator.`;
        logStore.addSocketLog(logItemPayloadMonitor);

        isLoadConnect.value = false;
        destroyInitiator({ type: payload?.action });
        Notice.error({
          title: i18n.t("notifications.unableToConnect"),
          name: "error-notice-silent-monitor",
          desc: i18n.t("notifications.thereWasANetworkIssue"),
          duration: 5,
        });
      }

      const timeout = setTimeout(() => {
        let isConnectionEstablished = spectate.value.isConnected;
        if (!isConnectionEstablished) {
          destroyInitiator({ type: payload?.action });
          Notice.error({
            title: i18n.t("notifications.unableToConnect"),
            name: "error-notice-silent-monitor",
            desc: i18n.t("notifications.timedOut"),
            duration: 5,
          });
        }
        clearTimeout(timeout);
      }, 15000);
    }

    async function onInitiatePeer(payload) {
      const logItemPayloadMonitorCallee = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        messageContext: {},
        text: "",
      };

      logItemPayloadMonitorCallee.messageContext.data = {
        token: Socket.specialToken?.jwtToken,
        instanceId: payload.instanceId,
        callUUID: payload.callUUID,
        callee: payload.callee,
        initiator: payload.initiator,
      };

      try {
        setMonitorInit(payload);
        let iceServers = getNetworkTraversal(coturnCreds.value);
        ac.resume();

        let remote_stream = window.mediaTech.stream;

        let local_stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: { deviceId: "communications" },
        });

        window.mediaTech.localStream = local_stream;

        if (!remote_stream.active || !local_stream.active) {
          throw new Error("One of the streams is not active");
        }

        let remote_stream_source = ac.createMediaStreamSource(remote_stream);
        let local_stream_source = ac.createMediaStreamSource(local_stream);

        const dest = ac.createMediaStreamDestination();

        remote_stream_source.connect(dest);
        local_stream_source.connect(dest);

        window.mPeers = new Peer({
          trickle: false,
          streams: [dest.stream],
          config: {
            iceServers: iceServers,
          },
        });

        window.mPeers.signal(JSON.parse(payload.OfferSDP));

        window.mPeers.on("signal", (data) => {
          setCallStatus(PeerCallStatus.Connecting);

          logItemPayloadMonitorCallee.level = "INFO";
          logItemPayloadMonitorCallee.messageContext.data.remoteData = data;
          logItemPayloadMonitorCallee.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [onInitiatePeer] : Monitoring signal recieved from remote peer - Callee.`;
          logStore.addSocketLog(logItemPayloadMonitorCallee);

          if (data.type === "answer") {
            try {
              if (Object.prototype.hasOwnProperty.call(monitorInit.value, "callee")) {
                let requestData = {
                  action: "updateMonitor",
                  token: Socket.specialToken?.jwtToken,
                  instanceId: payload.instanceId,
                  callUUID: payload.callUUID,
                  AnswerSDP: JSON.stringify(data),
                  callee: payload.callee,
                  initiator: payload.initiator,
                };
                Socket.send(JSON.stringify(requestData));
              }
            } catch (error) {
              console.error("Error in updateMonitor Emit: ", error);
              setCallStatus(PeerCallStatus.InitialState);
              return Promise.reject(error);
            }
          } else {
            window.mPeers.send(data);
          }
        });

        window.mPeers.on("error", (error) => {
          logItemPayloadMonitorCallee.level = "ERROR";
          logItemPayloadMonitorCallee.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
          logItemPayloadMonitorCallee.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [onInitiatePeer] : Error occured in the monitor callee's peer connection..`;
          logStore.addSocketLog(logItemPayloadMonitorCallee);
          console.error("PEER ERROR: ", error);
        });

        window.mPeers.on("close", () => {
          setCallStatus(PeerCallStatus.InitialState);
          setMonitorInit({});
          logItemPayloadMonitorCallee.level = "INFO";
          logItemPayloadMonitorCallee.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [onInitiatePeer] : Peer connection closed for monitoring - Callee.`;
          logStore.addSocketLog(logItemPayloadMonitorCallee);
        });

        window.mPeers.on("stream", function (stream) {
          window.mediaTech.coachAudio.srcObject = stream;

          logItemPayloadMonitorCallee.level = "INFO";
          logItemPayloadMonitorCallee.messageContext.data.remoteData = stream;
          logItemPayloadMonitorCallee.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [onInitiatePeer] : Recieved the remote stream for monitoring - Callee.`;
          logStore.addSocketLog(logItemPayloadMonitorCallee);
        });

        window.mPeers.on("data", async (data) => {
          logItemPayloadMonitorCallee.level = "INFO";
          logItemPayloadMonitorCallee.messageContext.data.remoteData = data;
          logItemPayloadMonitorCallee.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [onInitiatePeer] : Message recieved from remote monitoring peer - Callee.`;
          logStore.addSocketLog(logItemPayloadMonitorCallee);

          if (data.includes("silentMonitor")) {
            setCallStatus(PeerCallStatus.Connected);

            await updateUserMutation({
              InstanceId: payload.instanceId,
              isBeingMonitored: true,
              Username: payload.callee,
            });

            return;
          } else if (data.includes("whisperCoach")) {
            setCallStatus(PeerCallStatus.Connected);
            window.mediaTech.coachAudio.play();
            Notice.info({
              title: i18n.t("notifications.whisperCoachInProgress"),
              name: "announcement-notice-whisper-coach",
              desc: `${payload.initiator} ${i18n.t("notifications.isWhisperCoachingYouOnlyYouCanHearThem")}`,
              duration: 10,
            });

            await updateUserMutation({
              InstanceId: payload.instanceId,
              isBeingCoached: true,
              Username: payload.callee,
            });
          } else if (data.includes("destroyCoach")) {
            setCallStatus(PeerCallStatus.InitialState);
            setMonitorInit({});
            Notice.info({
              title: i18n.t("notifications.whisperCoachHasEnded"),
              name: "announcement-notice-whisper-coach-destroy",
              desc: `${payload.initiator} ${i18n.t("notifications.isNoLongerCoachingYou")}`,
              duration: 10,
            });

            await updateUserMutation({
              InstanceId: payload.instanceId,
              isBeingCoached: false,
              Username: payload.callee,
            });

            return;
          } else if (data.includes("destroyMonitor")) {
            setCallStatus(PeerCallStatus.InitialState);
            setMonitorInit({});

            await updateUserMutation({
              InstanceId: payload.instanceId,
              isBeingMonitored: false,
              Username: payload.callee,
            });

            return;
          }
        });
      } catch (error) {
        logItemPayloadMonitorCallee.level = "ERROR";
        logItemPayloadMonitorCallee.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        logItemPayloadMonitorCallee.text = `[LAMBDA_SOCKET_CLIENT] [${currentUserStore.getProfileData.Username}] [onInitiatePeer] : Error occured in onInitiatePeer.`;
        logStore.addSocketLog(logItemPayloadMonitorCallee);

        setCallStatus(PeerCallStatus.InitialState);
        setMonitorInit({});
      }
    }

    function onVoiceCallPeer(payload) {
      playIncomingRingtone();
      setVoiceCallModalFlag(true);
      setVoiceCallData(payload);
      setCallStatus(PeerCallStatus.Connecting);

      const agent = getAgentByUsername(payload.initiator);
      chatStore.setDrawer(true);
      chatStore.setUserSelected(agent);
    }

    async function acceptVoiceCallPeer() {
      const Username = agentStore.agentSummary.Username;
      const logItemPayloadCalleePeer = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        messageContext: {
          data: voiceCallData.value,
          source: "acceptVoiceCallPeer",
        },
      };

      try {
        stopIncomingRingtone();
        let iceServers = getNetworkTraversal(coturnCreds.value);

        let local_stream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });

        setCurrentStream(local_stream);

        window.vPeers = new Peer({
          trickle: false,
          streams: [local_stream],
          config: {
            iceServers: iceServers,
          },
        });

        window.vPeers.signal(JSON.parse(voiceCallData.value.OfferSDP));

        window.vPeers.on("signal", async (data) => {
          logItemPayloadCalleePeer.level = "INFO";
          logItemPayloadCalleePeer.messageContext.remoteOffer = data;
          logItemPayloadCalleePeer.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [acceptVoiceCallPeer] - Signal recieved from remote peer - Callee.`;
          logStore.addSocketLog(logItemPayloadCalleePeer);
          if (data.type === "answer") {
            setCallStatus(PeerCallStatus.Connecting);
            let requestData = {
              action: "updateVoiceCall",
              token: Socket.specialToken?.encodedToken,
              instanceId: window.special_token.payload["custom:InstanceId"],
              callUUID: voiceCallData.value.callUUID,
              AnswerSDP: JSON.stringify(data),
              initiator: voiceCallData.value.initiator,
            };

            try {
              Socket.send(JSON.stringify(requestData));
            } catch (error) {
              console.error("Error in updateVoiceCall Emit: ", error);
            }
          } else if (data.candidate) {
            return await newPeerCandidateMutation({
              ConversationId: voiceCallData.value.id,
              Candidate: data.candidate,
            });
          }
        });

        window.vPeers.on("error", (error) => {
          logItemPayloadCalleePeer.level = "ERROR";
          logItemPayloadCalleePeer.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
          logItemPayloadCalleePeer.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [acceptVoiceCallPeer] : Error occured in the callee's peer connection. `;
          logStore.addSocketLog(logItemPayloadCalleePeer);
          showErrorMessageAction("An error has occurred while establishing the call.");
          destroyPeer();
        });

        window.vPeers.on("connect", () => {
          spectate.value.isConnected = true;
          setCallMissedFlag(false);
          startVoiceCallDurationCounter("AgentToAgent");

          setCallStatus(PeerCallStatus.Connected);
          logItemPayloadCalleePeer.level = "INFO";
          logItemPayloadCalleePeer.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [acceptVoiceCallPeer] : Peer connection readied for a peer call - Callee. `;
          logStore.addSocketLog(logItemPayloadCalleePeer);
        });

        window.vPeers.on("stream", function (stream) {
          window.mediaTech.audio.srcObject = stream;
          window.mediaTech.audio.play();
          logItemPayloadCalleePeer.level = "INFO";
          logItemPayloadCalleePeer.messageContext.stream = stream;
          logItemPayloadCalleePeer.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [acceptVoiceCallPeer] : Remote stream recieved for a peer call - Callee. `;
          logStore.addSocketLog(logItemPayloadCalleePeer);
        });

        window.vPeers.on("close", () => {
          destroyPeer();
          setCallMissedFlag(true);
          logItemPayloadCalleePeer.level = "INFO";
          logItemPayloadCalleePeer.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [acceptVoiceCallPeer] - Peer connection has been closed - Callee.`;
          logStore.addSocketLog(logItemPayloadCalleePeer);
        });
      } catch (error) {
        logItemPayloadCalleePeer.level = "ERROR";
        logItemPayloadCalleePeer.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        logItemPayloadCalleePeer.text = `[LAMBDA_SOCKET_CLIENT] [${Username}] [acceptVoiceCallPeer] : Error occured in acceptVoiceCallPeer`;
        logStore.addSocketLog(logItemPayloadCalleePeer);
      }
    }
    async function onFetchListOfUsers({
      InstanceId,
      ListToSearch = null,
      UserId = null,
      isRecentUsers = false,
      Search = null,
      SingleUser = false,
    }) {
      const params = {
        InstanceId,
        Limit: USERS_DEFAULT_LIMIT,
        ListToSearch,
        Search,
        UserId,
      };
      const res = await listTeamQuery(params);

      if (SingleUser) {
        const user = res.listTeam.Items.length ? res.listTeam.Items[0] : null;
        setSingleUser(user);
      } else {
        if (isRecentUsers) {
          setRecentUsersRecently(res?.listTeam.Items);
        } else {
          setFavoritesUsers(res?.listTeam.Items);
        }
      }
    }

    function checkUpdateUsers({ InstanceId, UserId, userNameContact }) {
      let params = {
        InstanceId,
        UserId,
        ListToSearch: null,
        isRecentUsers: false,
      };
      const findUser = (list) => {
        return list.find((user) => user.Username === userNameContact);
      };
      if (list.value.items.length) {
        if (findUser(list.value.items)) {
          onFetchTeam(params);
        }
      }
      if (favoriteUsers.value.length) {
        if (findUser(favoriteUsers.value)) {
          params.ListToSearch = favoriteUsers.value.map((user) => user.Username);
          onFetchListOfUsers(params);
        }
      }
      if (recentUsersRecently.value.length) {
        if (findUser(recentUsersRecently.value)) {
          params.ListToSearch = recentUsersRecently.value.map((user) => user.Username);
          params.isRecentUsers = true;
          onFetchListOfUsers(params);
        }
      }
    }

    async function onFetchTeam({ InstanceId, ListToSearch = null, UserId = null, Search = null }) {
      try {
        let listCompleteOfUsers = [];
        let listUsers = [];
        let filteredExternalUsers = [];
        const pageDefault = 1;
        const isTeamIntegrationActive = integrationsStore.getIsTeamIntegrationActive;

        const params = {
          InstanceId,
          Limit: isTeamIntegrationActive ? USERS_INTEGRATIONS_LIMIT : USERS_DEFAULT_LIMIT,
          PageNumber: Search ? pageDefault : pageNumber.value,
          Search,
          ListToSearch,
          UserId,
        };

        const res = await listTeamQuery(params);

        let teamCount = res?.listTeam.Count;
        updateAmountOfUsers(teamCount);

        listUsers = res?.listTeam.Items;

        if (isTeamIntegrationActive) {
          const emailsFromDextrUsers = new Set(listUsers.map((obj) => obj.IdentityInfo.Email));
          filteredExternalUsers = externalUsersIntegrationsList.value.filter((obj) => !emailsFromDextrUsers.has(obj.Email));
        }
        listUsers = [...listUsers, ...filteredExternalUsers];

        listCompleteOfUsers = sortUsersByFullName({ users: listUsers });

        setTeamList(listCompleteOfUsers);
      } catch (error) {
        console.error(error);
      }
    }
    function updatePageNumber(payload) {
      setPageNumber(payload);
    }
    function resetlListOfUsers() {
      setTeamList([]);
      setExternalUsersFromIntegrationsList([]);
    }
    async function onFetchAgents(context, payload) {
      const result = await listSkillUsersQuery(payload);
      if (result) {
        setAllAgentsList(result?.listSkillUsers.items);
      }
      return result?.listSkillUsers?.items;
    }

    function startVoiceCallDurationCounter(payload = "ACD") {
      if (payload === "ACD") {
        const voiceCallDurationCounterIntervalId = setInterval(() => {
          incrementVoiceCallDuration("ACD");
        }, 1000);
        setVoiceCallDurationCounterIntervalId(voiceCallDurationCounterIntervalId);
      }
      if (payload === "AgentToAgent") {
        const voiceCallDurationAgentToAgentCounterIntervalId = setInterval(() => {
          incrementVoiceCallDuration("AgentToAgent");
        }, 1000);
        setVoiceCallDurationAgentToAgentCounterIntervalId(voiceCallDurationAgentToAgentCounterIntervalId);
      }
    }

    function stopVoiceCallDurationCounter(payload = "ACD") {
      if (payload === "ACD") {
        window.clearInterval(voiceCallDurationCounterIntervalId.value);
        initVoiceCallDuration();
      }
      if (payload === "AgentToAgent") {
        window.clearInterval(voiceCallDurationAgentToAgentCounterIntervalId.value);
        voiceCallDurationAgentToAgent.value = 0;
      }
    }

    function restartVoiceCallDurationCounter() {
      stopVoiceCallDurationCounter();
      startVoiceCallDurationCounter();
    }

    function muteCaller(mute) {
      const audioTrack = currentStream.value && currentStream.value.getAudioTracks()[0];
      audioTrack.enabled = !mute;
    }

    function muteReceiver(mute) {
      const audioTrack = currentStream.value && currentStream.value.getAudioTracks()[0];
      audioTrack.enabled = !mute;
    }

    function playIncomingRingtone() {
      const ringToneName = _.get(apiStore, "getUser.Preferences.PrimaryRingerPeer.Sound") || "default";
      let deviceId = _.get(apiStore, "getUser.Preferences.PrimaryRingerPeer.DeviceId") || "default";

      let find = mediaDeviceStore.audioOutputDevices.find((i) => deviceId === i.deviceId);
      if (find) {
        deviceId = find.deviceId;
      } else {
        deviceId = "default";
      }

      const ding = new Audio(availableTones[ringToneName]);
      if (typeof ding.setSinkId === "function") {
        ding.setSinkId(deviceId);
      }
      ding.volume = (_.get(apiStore, "getUser.Preferences.PrimaryRingerPeer.Volume") || 50) / 100;
      ding.loop = true;
      ding.play();
      inComingRingtone.value = ding;

      if (_.get(apiStore, "getUser.Preferences.SecondaryRingerPeer.isEnabled")) {
        const secondaryRingtoneName = _.get(apiStore, "getUser.Preferences.SecondaryRingerPeer.Sound") || "default";
        let deviceIdSecondary = _.get(apiStore, "getUser.Preferences.SecondaryRingerPeer.DeviceId") || "default";

        const dingSec = new Audio(tonesSecondary[secondaryRingtoneName]);
        dingSec.volume = (_.get(apiStore, "getUser.Preferences.SecondaryRingerPeer.Volume") || 50) / 100;

        find = mediaDeviceStore.audioOutputDevices.find((i) => deviceIdSecondary === i.deviceId);
        if (find) {
          deviceIdSecondary = find.deviceId;
        } else {
          deviceIdSecondary = "default";
        }

        if (typeof dingSec.setSinkId === "function") {
          dingSec.setSinkId(deviceIdSecondary);
        }
        dingSec.loop = true;
        dingSec.play();
        secondaryIncomingRingtone.value = dingSec;
      }
    }

    function stopIncomingRingtone() {
      const ding = inComingRingtone.value;
      if (ding) {
        ding.pause();
        inComingRingtone.value = null;
      }
      const dingSec = secondaryIncomingRingtone.value;
      if (dingSec) {
        dingSec.pause();
        secondaryIncomingRingtone.value = null;
      }
    }

    function playOutgoingRingtone() {
      const ding = new Audio(OutgoingRingtone);
      ding.loop = true;
      ding.volume = indexStore.soundVolume / 100;
      ding.play();
      outgoingRingtone.value = ding;
    }

    function stopOutgoingRingtone() {
      const ding = outgoingRingtone.value;
      if (ding) {
        ding.pause();
        outgoingRingtone.value = null;
      }
    }

    async function createCTR(payload) {
      try {
        const response = await createActivityMutation(payload);
        if (callStatus.value === PeerCallStatus.InitialState && response?.createActivity.InitiationMethod === "OUTBOUND") {
          const updateCTR = {
            id: response?.createActivity.id,
            range: response?.createActivity.range,
            InstanceId: response?.createActivity.InstanceId,
            DisconnectTimestamp: getTimeObject().offsetToISOString(),
          };
          updateCTR(updateCTR);
          setDisconnectTimestamp(null);
        } else {
          setCurrentCTRId(_.get(response, "createActivity.id"));
          setRange(_.get(response, "createActivity.range"));
        }
        activityStore.onFetchRecentActivity({
          InstanceId: agentStore.agentSummary.InstanceId,
          Username: agentStore.agentSummary.Username,
        });
      } catch (ex) {
        console.error(ex);
      }
    }

    async function updateCTR(payload) {
      try {
        await updateActivityMutation(payload);
        activityStore.onFetchRecentActivity({
          InstanceId: agentStore.agentSummary.InstanceId,
          Username: agentStore.agentSummary.Username,
        });
      } catch (ex) {
        console.error(ex);
      }
    }

    function endVoiceCall() {
      try {
        callRejectedFlag.value = false;
        let requestData = {
          action: "destroyVoiceCall",
          callUUID: voiceCallData.value.callUUID,
          instanceId: window.special_token.payload["custom:InstanceId"],
          endedBy: "initiator",
          callee: voiceCallData.value.callee,
          initiator: voiceCallData.value.initiator,
          rejected: false,
          token: Socket.specialToken?.encodedToken,
        };

        Socket.send(JSON.stringify(requestData));
      } catch (error) {
        console.error("Error in destroyVoiceCall Emit: ", error);
      }
    }

    function endVoiceCallFromCallee({ rejected = false }) {
      let requestData = {
        action: "destroyVoiceCall",
        callUUID: voiceCallData.value.callUUID,
        instanceId: window.special_token.payload["custom:InstanceId"],
        endedBy: "callee",
        initiator: voiceCallData.value.initiator,
        callee: voiceCallData.value.callee,
        rejected: rejected,
        token: Socket.specialToken?.encodedToken,
      };
      try {
        Socket.send(JSON.stringify(requestData));
      } catch (error) {
        console.error("Error in endVoiceCallFromCallee Emit: ", error);
      }
    }

    function startCallTimer({ InstanceId }) {
      const maxWaitingTime = 30000;
      const timeOutId = window.setTimeout(async () => {
        if (callStatus.value !== PeerCallStatus.Connected) {
          await agentCallStore.cleanupAgentToAgentCall({
            InstanceId: InstanceId,
            MeetingId: agentCallStore.getMeetingInfo.MeetingId,
            Receiver: agentCallStore.getMeetingInfo.Receiver,
            Initiator: agentCallStore.getMeetingInfo.Initiator,
            Status: AGENT_TO_AGENT_CHIME_CALL.CALLMISSED,
          });
          destroyVoiceCall();
          showErrorMessageAction("The connection has timed out.");
          setVoiceCallData(null);
        }
      }, maxWaitingTime);
      setCallTimerId(timeOutId);
    }

    function destroyCallTimer() {
      window.clearTimeout(timeOutId.value);
    }

    function showErrorMessageAction(errorMessage) {
      setShowErrorMessage(true);
      setErrorMessage(errorMessage);
    }

    function setUserUnreadMessageStatus({ Username, SetCount, Key }) {
      const showChatDrawer = chatStore.showChatDrawer;
      updateUnreadList({
        Username,
        HasUnread: true,
        SetCount,
      });
      let shouldShowChatToast = false;
      if (!showChatDrawer) {
        shouldShowChatToast = true;
      }
      if (Username !== previousUserName.value) {
        shouldShowChatToast = true;
      }
      updateKey({
        Key,
        Username,
      });
      setUnreadStatusMutation({
        HasUnread: shouldShowChatToast,
      });

      favicon.badge("\uf23A");
    }

    function ResetKey({ Key }) {
      ResetKeyMutation({
        Key,
      });
    }

    function UserUnreadMessageStatus({ Username, SetCount }) {
      updateUnreadList({
        Username,
        HasUnread: true,
        SetCount,
      });

      favicon.badge("\uf23A");
    }

    function setUnreadStatus(unread) {
      setUnreadStatusMutation({
        HasUnread: unread,
      });
    }

    function clearUserUnreadMessageStatus({ Username }) {
      updateUnreadList({
        Username,
        HasUnread: false,
      });

      setUnreadStatusMutation({
        HasUnread: false,
      });
      ResetKeyMutation({
        Key: false,
      });
      setPreviousUsername({
        previousUserName: Username,
      });
      favicon.reset();
    }

    async function favoriteUser({ InstanceId, CurrentUsername, FavoriteUsername }) {
      try {
        toggleFavoriteUser(FavoriteUsername);

        const response = await updateFavoriteItemsMutation({
          InstanceId,
          Username: CurrentUsername,
          TeamMember: FavoriteUsername,
        });

        addNewFavoriteUser(_.get(response, "updateFavoriteItems.FavoriteTeamMembers", []));
      } catch (ex) {
        showErrorMessageAction("Failed to add to favorite.");
      }
    }

    function deleteMonitorFromCallee() {
      let requestData = {
        action: "deleteMonitor",
        instanceId: window.special_token.payload["custom:InstanceId"],
        token: Socket.specialToken?.encodedToken,
        initiator: monitorInit.value.initiator,
        callee: monitorInit.value.callee,
        endedBy: "callee",
      };
      if (monitorInit.value?.initiator && monitorInit.value?.callee) {
        Socket.send(JSON.stringify(requestData));
      }
    }

    async function getExternalUserFromIntegrationsSubs({
      integrationType,
      instanceId,
      IdentityInfo = null,
      pageNumber = 1,
      search = null,
    }) {
      try {
        const response = await listUsersIntegrationsQuery({
          input: {
            InstanceId: instanceId,
            IntegrationType: integrationType,
            Options: {
              Limit: USERS_INTEGRATIONS_LIMIT,
              PageNumber: pageNumber,
              Search: search,
            },
          },
        });

        const totalOfUsers = response.listUsersIntegrations.Count ? Number(response.listUsersIntegrations.Count) : 0;
        updateAmountOfUsersIntegrations(totalOfUsers);

        if (response.listUsersIntegrations.Items) {
          const listFiltered = response.listUsersIntegrations.Items.filter((user) => user.Email !== IdentityInfo?.Email);
          addNewExternalUserFromIntegration(listFiltered);
        }
      } catch (error) {
        console.error("Error > store > teams > onUserIntegrationsSubsFail > ", error);
      }
    }

    function onCreateExternalUserFromIntegrations(payload) {
      try {
        addNewExternalUserFromIntegration(payload);
      } catch (error) {
        console.error("Error > store > teams > onCreateUserIntegrations > ", error);
      }
    }

    function onUpdateExternalUserFromIntegrations(payload) {
      try {
        updateExternalUserFromIntegration(payload);
      } catch (error) {
        console.error("Error > store > teams > onUpdateUserIntegrations > ", error);
      }
    }

    function onDeleteExternalUserFromIntegrations(payload) {
      try {
        deleteExternalUserFromIntegration(payload);
      } catch (error) {
        console.error("Error > store > teams > onDeleteUserIntegrations > ", error);
      }
    }

    async function initTeamsIntegrations({ instanceId }) {
      await getExternalUserFromIntegrationsSubs({ integrationType: "office365", instanceId });
      createExternalUsersFromIntegrationsSubs({ instanceId });
      deleteExternalUsersFromIntegrationsSubs({ instanceId });
      updateExternalUsersFromIntegrationsSubs({ instanceId });
    }

    function endTeamsIntegrations() {
      setExternalUsersFromIntegrationsListMutation([]);
      indexStore.removeSubscriptionFromList({ id: "createExternalUsersFromIntegrationsSubs" });
      indexStore.removeSubscriptionFromList({ id: "deleteExternalUsersFromIntegrationsSubs" });
      indexStore.removeSubscriptionFromList({ id: "updateExternalUsersFromIntegrationsSubs" });
    }

    function createExternalUsersFromIntegrationsSubs({ instanceId }) {
      const observable = onCreateUserIntegrationsSubscription({ InstanceId: instanceId });
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onCreateUserIntegrations;
          onCreateExternalUserFromIntegrations(newData);
          return;
        },
        error: (err) => {
          console.error("Error >  createExternalUsersFromIntegrationsSubs >  ", err);
        },
      };
      indexStore.addSubscriptionToList({
        id: "createExternalUsersFromIntegrationsSubs",
        observable,
        handler,
        linkedQuery: {
          name: "team/getExternalUserFromIntegrationsSubs",
          params: { instanceId, integrationType: "office365" },
        },
      });
    }

    function deleteExternalUsersFromIntegrationsSubs({ instanceId }) {
      const observable = onDeleteUserIntegrationsSubscription({ InstanceId: instanceId });
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onDeleteUserIntegrations;
          onDeleteExternalUserFromIntegrations(newData);
          return;
        },
        error: (err) => {
          console.error("Error > deleteExternalUsersFromIntegrationsSubs > ", err);
        },
      };
      indexStore.addSubscriptionToList({
        id: "deleteExternalUsersFromIntegrationsSubs",
        observable,
        handler,
        linkedQuery: {
          name: "team/getExternalUserFromIntegrationsSubs",
          params: { instanceId, integrationType: "office365" },
        },
      });
    }

    function updateExternalUsersFromIntegrationsSubs({ instanceId }) {
      const observable = onUpdateUserIntegrationsSubscription({ InstanceId: instanceId });
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateUserIntegrations;
          onUpdateExternalUserFromIntegrations(newData);
          return;
        },
        error: (err) => {
          console.error("Error > updateExternalUsersFromIntegrationsSubs > ", err);
        },
      };
      indexStore.addSubscriptionToList({
        id: "updateExternalUsersFromIntegrationsSubs",
        observable,
        handler,
        linkedQuery: {
          name: "team/getExternalUserFromIntegrationsSubs",
          params: { instanceId, integrationType: "office365" },
        },
      });
    }

    function addNewExternalUserFromIntegration(payload) {
      const indexUserIntegration = externalUsersIntegrationsList.value.findIndex((i) => i.Id === payload.Id);
      if (indexUserIntegration === -1) {
        externalUsersIntegrationsList.value = payload;
      }
    }
    function updateExternalUserFromIntegration(payload) {
      const indexUserIntegration = externalUsersIntegrationsList.value.findIndex((i) => i.Id === payload.Id);
      Vue.set(externalUsersIntegrationsList.value, indexUserIntegration, payload);
    }
    function deleteExternalUserFromIntegration(payload) {
      const indexUserIntegration = externalUsersIntegrationsList.value.findIndex((i) => i.Id === payload.Id);
      if (indexUserIntegration !== -1) {
        Vue.delete(externalUsersIntegrationsList.value, indexUserIntegration);
      }
    }
    function setExternalUsersFromIntegrationsListMutation(payload) {
      externalUsersIntegrationsList.value = payload;
    }
    function setTeamList(payload) {
      list.value = {
        items: payload,
      };
    }
    function setAllAgentsList(payload) {
      allAgentsList.value = {
        items: payload.filter((u) => u && u.Username && u.IdentityInfo && !u.Username.includes("IAM")),
      };
    }
    function removedUserFromTeam(payload) {
      let indx = list.value.items.findIndex((i) => i.Id === payload.Id);
      indx !== -1 && Vue.delete(list.value.items, indx);
    }
    function updateTeam(payload) {
      if (payload.length === 0) return;
      const currentUsers = [...list.value.items];

      currentUsers.map((currentUser, currentUserIndex) => {
        payload.map((updatedUser) => {
          if (currentUser.Username === updatedUser.Username) {
            currentUsers[currentUserIndex] = {
              ...currentUsers[currentUserIndex],
              ...updatedUser,
            };
          }
        });
      });

      list.value.items = [];
      list.value.items = currentUsers;
    }

    function deleteTeamMember(payload) {
      let index = list.value.items.findIndex((i) => {
        return i.Username === payload.Username;
      });
      if (index > -1) list.value.items.splice(index, 1);
    }
    function newTeamMember(payload) {
      let index = list.value.items.findIndex((i) => {
        return i.Username === payload.Username;
      });
      if (index === -1) {
        list.value.items.push(payload);
      }
    }
    function teamNeedHelp(payload) {
      for (let x = 0; x < list.value.items.length; x++) {
        if (list.value.items[x].Id === payload.Id) {
          list.value.items[x].NeedsHelp = payload.NeedsHelp;
          break;
        }
      }
    }
    function setVoiceCallModalFlag(payload) {
      showVoiceCallModalFlag.value = payload;
    }
    function setCallMissedFlag(payload) {
      callMissedFlag.value = payload;
    }
    function setCallPlacedFlag(payload) {
      callPlacedFlag.value = payload;
    }
    function setCoturnCredentials(payload) {
      coturnCreds.value = payload;
    }
    function setAnswerProcessLoader(payload) {
      answerProcessLoader.value = payload;
    }
    function setVoiceCallData(payload) {
      voiceCallData.value = payload;
    }
    function setCurrentAgentCallFarEndUserInfo(payload) {
      spectate.value.user = payload;
    }
    function setVoiceCallDurationCounterIntervalId(payload) {
      voiceCallDurationCounterIntervalId.value = payload;
    }
    function setVoiceCallDurationAgentToAgentCounterIntervalId(payload) {
      voiceCallDurationAgentToAgentCounterIntervalId.value = payload;
    }
    function initVoiceCallDuration() {
      voiceCallDuration.value = 0;
    }
    function incrementVoiceCallDuration(payload = "ACD") {
      if (payload === "ACD") {
        voiceCallDuration.value += 1000;
      }
      if (payload === "AgentToAgent") {
        voiceCallDurationAgentToAgent.value += 1000;
      }
    }
    function setCurrentStream(payload) {
      currentStream.value = payload;
    }
    function setCallStatus(payload) {
      callStatus.value = payload;
    }
    function setCurrentCTRId(payload) {
      currentCTRId.value = payload;
    }
    function setRange(payload) {
      range.value = payload;
    }
    function setDisconnectTimestamp(payload) {
      DisconnectTimestamp.value = payload;
    }
    function setCurrentCallId(payload) {
      callId.value = payload;
    }
    function setCallTimerId(payload) {
      timeOutId.value = payload;
    }
    function setShowErrorMessage(payload) {
      showErrorMessage.value = payload;
    }
    function setErrorMessage(payload) {
      errorMessage.value = payload;
    }
    function updateUnreadList({ Username, HasUnread, SetCount }) {
      const current = unreadList.value;
      const foundIndex = current.findIndex((x) => x.Username === Username);
      const currentItem = current[foundIndex];

      if (foundIndex === -1) {
        const newCount = HasUnread ? (SetCount ? SetCount : 1) : 0;
        const newItem = { Username, HasUnread, Count: newCount };

        unreadList.value.push(newItem);
      } else if (SetCount) {
        const currentCount = SetCount;
        const newItem = {
          Username,
          HasUnread,
          Count: HasUnread ? currentCount : 0,
        };

        unreadList.value.splice(foundIndex, 1, newItem);
      } else {
        const currentCount = currentItem.Count;
        const newCount = currentCount + 1;
        const newItem = {
          Username,
          HasUnread,
          Count: HasUnread ? newCount : 0,
        };

        unreadList.value.splice(foundIndex, 1, newItem);
      }
    }
    function updateKey({ Key, Username }) {
      updatedUnread.value = Key;
      userName.value = Username;
    }
    function ResetKeyMutation({ Key }) {
      updatedUnread.value = Key;
    }
    function setPreviousUsername(payload) {
      previousUserName.value = payload.previousUserName;
    }

    function setUnreadStatusMutation(unreadStatus) {
      unreadStatus.value = unreadStatus;
    }
    function addNewFavoriteUser(users) {
      favoriteList.value = users;
    }
    function toggleFavoriteUser(username) {
      const foundIndex = favoriteList.value.findIndex((x) => x === username);

      if (foundIndex === -1) {
        favoriteList.value.push(username);
      } else {
        favoriteList.value.splice(foundIndex, 1);
      }
    }
    function setUnreadList(value) {
      unreadList.value = value;
    }
    function updateState(value) {
      if (value) {
        list.value = value.list;
      }
    }
    function setMonitorInit(payload) {
      monitorInit.value = payload;
    }
    function setPageNumber(payload) {
      pageNumber.value = payload;
    }

    function updateAmountOfUsers(payload) {
      totalAmountOfUsers.value = payload;
    }

    function updateAmountOfUsersIntegrations(payload) {
      totalAmountOfUsersIntegration.value = payload;
    }

    function setlistToSearch(payload) {
      listToSearch.value = payload;
    }
    function setRecentUsersRecently(payload) {
      recentUsersRecently.value = payload;
    }
    function setSingleUser(payload) {
      singleUser.value = payload;
    }
    function setFavoritesUsers(payload) {
      favoriteUsers.value = payload;
    }
    return {
      //States
      unreadList,
      updatedUnread,
      userName,
      previousUserName,
      favoriteList,
      list,
      allAgentsList,
      isLoadConnect,
      spectate,
      showVoiceCallModalFlag,
      callMissedFlag,
      callRejectedFlag,
      callPlacedFlag,
      answerProcessLoader,
      voiceCallData,
      voiceCallDuration,
      voiceCallDurationCounterIntervalId,
      voiceCallDurationAgentToAgent,
      voiceCallDurationAgentToAgentCounterIntervalId,
      currentStream,
      inComingRingtone,
      secondaryIncomingRingtone,
      outgoingRingtone,
      callStatus,
      currentCTRId,
      range,
      callId,
      DisconnectTimestamp,
      timeOutId,
      showErrorMessage,
      errorMessage,
      coturnCreds,
      monitorInit,
      unreadStatus,
      externalUsersIntegrationsList,
      allUsers,
      totalAmountOfUsers,
      totalAmountOfUsersIntegration,
      countUserTeam,
      pageNumber,
      teamIntegrationCursor,
      //Getters
      allExternalUsersFromIntegrations,
      allUsersList,
      listUsersWithoutCurrentUser,
      externalUsersIntegrationsListWithoutCurrentUser,
      isConnectingInitiator,
      spectating,
      voiceCallDataGetter,
      getAnswerProcessLoader,
      teamList,
      team,
      getAllAgentsList,
      getAgentByUsername,
      getCallStatus,
      getUserName,
      getTeamIntegratiopCursor,
      getTeamCursor,
      getTotalAmountOfUsers,
      getTotalAmountOfUsersIntegrations,
      getRecordsPerPage,
      getPageNumber,
      getRecentUsersRecently,
      getFavoriteUsers,
      getSingleUser,
      //Actions
      setExternalUsersFromIntegrationsList,
      setupVoiceCall,
      destroyInitiator,
      deleteMonitorInitiator,
      destroyVoiceCall,
      destroyPeer,
      fireInitiator,
      onInitiatePeer,
      onVoiceCallPeer,
      acceptVoiceCallPeer,
      onFetchListOfUsers,
      checkUpdateUsers,
      onFetchTeam,
      updatePageNumber,
      resetlListOfUsers,
      onFetchAgents,
      startVoiceCallDurationCounter,
      stopVoiceCallDurationCounter,
      restartVoiceCallDurationCounter,
      muteCaller,
      muteReceiver,
      playIncomingRingtone,
      stopIncomingRingtone,
      playOutgoingRingtone,
      stopOutgoingRingtone,
      createCTR,
      updateCTR,
      endVoiceCall,
      endVoiceCallFromCallee,
      startCallTimer,
      destroyCallTimer,
      showErrorMessageAction,
      setUserUnreadMessageStatus,
      ResetKey,
      UserUnreadMessageStatus,
      setUnreadStatus,
      clearUserUnreadMessageStatus,
      favoriteUser,
      deleteMonitorFromCallee,
      getExternalUserFromIntegrationsSubs,
      onCreateExternalUserFromIntegrations,
      onUpdateExternalUserFromIntegrations,
      onDeleteExternalUserFromIntegrations,
      initTeamsIntegrations,
      endTeamsIntegrations,
      createExternalUsersFromIntegrationsSubs,
      deleteExternalUsersFromIntegrationsSubs,
      updateExternalUsersFromIntegrationsSubs,
      //Mutations
      addNewExternalUserFromIntegration,
      updateExternalUserFromIntegration,
      deleteExternalUserFromIntegration,
      setExternalUsersFromIntegrationsListMutation,
      setTeamList,
      setAllAgentsList,
      removedUserFromTeam,
      updateTeam,
      deleteTeamMember,
      newTeamMember,
      teamNeedHelp,
      setVoiceCallModalFlag,
      setCallMissedFlag,
      setCallPlacedFlag,
      setCoturnCredentials,
      setAnswerProcessLoader,
      setVoiceCallData,
      setCurrentAgentCallFarEndUserInfo,
      setVoiceCallDurationCounterIntervalId,
      setVoiceCallDurationAgentToAgentCounterIntervalId,
      initVoiceCallDuration,
      incrementVoiceCallDuration,
      setCurrentStream,
      setCallStatus,
      setCurrentCTRId,
      setRange,
      setDisconnectTimestamp,
      setCurrentCallId,
      setCallTimerId,
      setShowErrorMessage,
      setErrorMessage,
      updateUnreadList,
      updateKey,
      ResetKeyMutation,
      setPreviousUsername,
      setUnreadStatusMutation,
      addNewFavoriteUser,
      toggleFavoriteUser,
      setUnreadList,
      updateState,
      setMonitorInit,
      updateAmountOfUsers,
      setlistToSearch,
      setRecentUsersRecently,
      setSingleUser,
      setFavoritesUsers,
    };
  },
  {
    persist: true,
  }
);
