import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import { i18n } from "@/plugins/language";
import { INTEGRATION_PROPERTIES, LIST_INTEGRATIONS, STEP_MACHINE_EVENT_NAMES } from "@connectpath/common";
import {
  createSmsTroughPinpointIntegrationMutation,
  listIntegrationsQuery,
  getIntegrationsQuery,
  checkIntegrationCloudFormationStatusQuery,
  updateSmsTroughPinpointIntegrationMutation,
  deleteIntegrationMutation,
  listContactFlowsQuery,
  createOffice365IntegrationMutation,
  updateOffice365IntegrationMutation,
  createOfficeDynamicsIntegrationMutation,
  updateOfficeDynamicsIntegrationMutation,
  checkEmailIdentitiesVerificationStatusQuery,
  listCustomStacksIntegrationsQuery,
  getCustomStackIntegrationQuery,
  deployCustomStackIntegrationMutation,
  deleteCustomStackIntegrationMutation,
  createGoogleAuthIntegrationMutation,
  updateGoogleAuthIntegrationMutation,
  createQuickSightQuery,
  replicateInstance,
  replicateCrossRegionResources,
} from "@/common/services/graphql.service";
import { useUiStore } from "./ui";
import { useTeamStore } from "./team";
import { useAgentsStore } from "./agents";
export const useIntegrationsStore = defineStore(
  "integrations",
  () => {
    const uiStore = useUiStore();
    const teamStore = useTeamStore();
    const agentStore = useAgentsStore();
    const integrations = ref([]);
    const customStackIntegrations = ref([]);
    const integrationCloudFormationStatus = ref("");
    const contactFlows = ref([]);
    const error = ref("");
    const domainStatus = ref([]);

    const getShouldUserLoginTeams = computed(() => {
      return integrations.value.some(
        (i) => i.Type === "office365" && i.Provider === "Microsoft" && i.Integration.Teams && !i.Integration.HasDelegatedSession
      );
    });
    const getIsTeamIntegrationActive = computed(() => {
      return integrations.value.some(
        (i) => i.Type === "office365" && i.Provider === "Microsoft" && i.Integration.Teams && i.Integration.HasDelegatedSession
      );
    });
    const getListIntegrations = computed(() => integrations.value);
    const getListContactFlows = computed(() => contactFlows.value);
    const getError = computed(() => error.value);
    const getIntegrationCloudFormationStatus = computed(() => integrationCloudFormationStatus.value);
    const getSmsTroughPinpointIntegration = computed(() =>
      integrations.value?.find((integration) => integration.Type === "sms" && integration.Provider === "pinpoint")
    );
    const getOffice365Integration = computed(() =>
      integrations.value?.find((integration) => integration.Type === "office365" && integration.Provider === "Microsoft")
    );
    const getDomainStatus = computed(() => domainStatus.value);
    const getOfficeDynamicsIntegration = computed(() =>
      integrations.value?.find((integration) => integration.Type === "officeDynamics" && integration.Provider === "Microsoft")
    );

    const getCustomStackIntegrations = computed(() => customStackIntegrations.value);
    const getGoogleAuthIntegration = computed(() => {
      const integrationKey = LIST_INTEGRATIONS.GOOGLE_AUTH;
      const integrationInfo = INTEGRATION_PROPERTIES[integrationKey];

      return integrations.value?.find(
        (integration) => integration.Type === integrationInfo.TYPE && integration.Provider === integrationInfo.PROVIDER
      );
    });

    async function createSmsTroughPinpointIntegration({ instanceId, name, contactFlowId, phoneNumber, pinpointAppId }) {
      const integrationType = "sms";
      const integrationProvider = "pinpoint";
      setError("");
      try {
        const integration = await createSmsTroughPinpointIntegrationMutation({
          InstanceId: instanceId,
          Name: name,
          Type: integrationType,
          Provider: integrationProvider,
          Integration: {
            ContactFlowId: contactFlowId,
            PhoneNumber: phoneNumber,
            PinpointAppId: pinpointAppId,
          },
        });
        updateListIntegrationItem(integration?.createSmsTroughPinpointIntegration);
        return;
      } catch (error) {
        setError(error || "");
        console.error(`Error store integrations createSmsTroughPinpointIntegration`, error);
      }
    }

    async function listIntegrations({ instanceId }) {
      try {
        const integrations = await listIntegrationsQuery();
        setListIntegrations(integrations?.listIntegrations || []);
        const isEnabled = integrations?.listIntegrations.some(
          (i) =>
            i.Type === "office365" && i.Provider === "Microsoft" && i.Integration.Teams && i.Integration.HasDelegatedSession
        );
        if (isEnabled) {
          await teamStore.initTeamsIntegrations({ instanceId });
        }
      } catch (error) {
        uiStore.messageEndpointError({ message: i18n.t("integration.thereWasAnErrorLoadingTheIntegrations") });
        console.error(`Error store integrations listIntegrations`, error);
      }
    }
    async function getIntegration(payload) {
      try {
        await getIntegrationsQuery({
          IntegrationId: payload.integrationId,
        });
      } catch (error) {
        console.error(`Error store integrations getIntegration`, error);
      }
    }

    async function checkIntegrationCloudFormationStatus() {
      cleanMessages();
      try {
        const status = await checkIntegrationCloudFormationStatusQuery({
          IntegrationId: getSmsTroughPinpointIntegration.value.Id,
        });
        setIntegrationCloudFormationStatus(status?.checkIntegrationCloudFormationStatus?.StackStatus || {});
      } catch (error) {
        setError(error || "");
        console.error(`Error store integrations checkIntegrationCloudFormationStatus`, error);
      }
    }

    async function updateSmsTroughPinpointIntegration({ instanceId, name, contactFlowId, phoneNumber, pinpointAppId }) {
      const integrationType = "sms";
      const integrationProvider = "pinpoint";
      setError("");
      try {
        const integration = await updateSmsTroughPinpointIntegrationMutation({
          Id: getSmsTroughPinpointIntegration.value.Id,
          InstanceId: instanceId,
          Name: name,
          Type: integrationType,
          Provider: integrationProvider,
          Integration: {
            ContactFlowId: contactFlowId,
            PhoneNumber: phoneNumber,
            PinpointAppId: pinpointAppId,
          },
        });

        updateListIntegrationItem(integration.updateSmsTroughPinpointIntegration);
        return;
      } catch (error) {
        setError(error || "");
        console.error(`Error store integrations updateSmsTroughPinpointIntegration`, error);
      }
    }

    async function deleteIntegration({ id }) {
      try {
        await deleteIntegrationMutation({
          IntegrationId: id,
        });
      } catch (error) {
        console.error(`Error store integrations deleteIntegration`, error);
      }
    }

    async function getContactFlows(payload) {
      try {
        const flows = await listContactFlowsQuery({
          InstanceId: payload.instanceId,
        });

        setListContactFlows(flows?.listContactFlows.items || []);
      } catch (error) {
        console.error(`Error store integrations getContactFlows`, error);
      }
    }

    async function createOffice365Integration({
      instanceId,
      name,
      clientSecret,
      clientId,
      tenantId,
      smtp,
      teams,
      enabledChangeStatusInConnect,
      ConnectToTeamsStatusesMap,
      TeamsToConnectStatusesMap,
    }) {
      const type = "office365";
      const provider = "Microsoft";
      await createOffice365IntegrationMutation({
        InstanceId: instanceId,
        Name: name,
        Type: type,
        Provider: provider,
        Integration: {
          ClientSecret: clientSecret,
          ClientId: clientId,
          TenantId: tenantId,
          SMTP: smtp,
          Teams: teams,
          HasDelegatedSession: false,
          EnabledChangeStatusInConnect: enabledChangeStatusInConnect,
          ConnectToTeamsStatusesMap,
          TeamsToConnectStatusesMap,
        },
      });
    }

    async function updateOffice365Integration({
      instanceId,
      name,
      clientSecret,
      clientId,
      tenantId,
      smtp,
      teams,
      enabledChangeStatusInConnect,
      ConnectToTeamsStatusesMap,
      TeamsToConnectStatusesMap,
    }) {
      const type = "office365";
      const provider = "Microsoft";

      try {
        await updateOffice365IntegrationMutation({
          InstanceId: instanceId,
          Id: getOffice365Integration.value.Id,
          Name: name,
          Type: type,
          Provider: provider,
          Integration: {
            ClientSecret: clientSecret,
            ClientId: clientId,
            TenantId: tenantId,
            SMTP: smtp,
            Teams: teams,
            HasDelegatedSession: false,
            EnabledChangeStatusInConnect: enabledChangeStatusInConnect,
            ConnectToTeamsStatusesMap,
            TeamsToConnectStatusesMap,
          },
        });
        uiStore.messageEndpointSuccess({ message: i18n.t("integration.office365.office365IntegrationUpdated") });
      } catch (error) {
        uiStore.messageEndpointError({
          message: i18n.t("integration.office365.thereWasAnErrorUpdatingTheIntegrationWithOffice365"),
        });
        console.error(`Error - store - integrations - updateOffice365Integration`, error);
      }
    }

    async function createOfficeDynamicsIntegrations({
      Name,
      Uri,
      Version,
      appId,
      tenantId,
      clientId,
      clientSecret,
      Scope,
      IntegrationSteps,
      RecordingBucketName,
    }) {
      const type = "officeDynamics";
      const provider = "Microsoft";
      try {
        await createOfficeDynamicsIntegrationMutation({
          Type: type,
          Provider: provider,
          Integration: {
            Name: Name,
            Uri: Uri,
            Version: Version,
            AppId: appId,
            TenantId: tenantId,
            ClientId: clientId,
            ClientSecret: clientSecret,
            Scope: Scope,
            RecordingBucketName: RecordingBucketName,
            IntegrationSteps: [...IntegrationSteps],
          },
        });

        uiStore.messageEndpointSuccess({ message: i18n.t("integration.office365.office365IntegrationCreated") });
      } catch (error) {
        uiStore.messageEndpointError({
          message: i18n.t("integration.office365.thereWasAnErrorCreatingTheIntegrationWithOffice365"),
        });
        console.error(`Error - store - integrations - createOffice365Integration`, error);
      }
    }
    async function updateOfficeDynamicsIntegration({
      Name,
      Uri,
      Version,
      appId,
      tenantId,
      clientId,
      clientSecret,
      Scope,
      IntegrationSteps,
      RecordingBucketName,
    }) {
      const type = "officeDynamics";
      const provider = "Microsoft";

      try {
        await updateOfficeDynamicsIntegrationMutation({
          Id: getOfficeDynamicsIntegration.value.Id,
          Type: type,
          Provider: provider,
          Integration: {
            Name: Name,
            Uri: Uri,
            Version: Version,
            AppId: appId,
            TenantId: tenantId,
            ClientId: clientId,
            ClientSecret: clientSecret,
            Scope: Scope,
            RecordingBucketName: RecordingBucketName,
            IntegrationSteps: [...IntegrationSteps],
          },
        });

        uiStore.messageEndpointSuccess({ message: i18n.t("integration.office365.office365IntegrationUpdated") });
      } catch (error) {
        uiStore.messageEndpointError({
          message: i18n.t("integration.office365.thereWasAnErrorUpdatingTheIntegrationWithOffice365"),
        });
        console.error(`Error - store - integrations - updateOffice365Integration`, error);
      }
    }
    async function integrationChanged({ instanceId, integration }) {
      if (integration.Type === "office365" && integration.Provider === "Microsoft") {
        if (integration.Integration.Teams && integration.Integration.HasDelegatedSession) {
          await teamStore.initTeamsIntegrations({ instanceId });
        } else {
          teamStore.endTeamsIntegrations({});
        }
      }
      updateListIntegrationItem(integration);
    }

    function integrationDeleted({ integration }) {
      if (integration.Integration?.Teams) {
        teamStore.endTeamsIntegrations({});
      }
      deleteListIntegrationItem(integration);
    }

    async function checkIdentitiesVerificationStatus(payload) {
      try {
        const response = await checkEmailIdentitiesVerificationStatusQuery({
          input: {
            InboundDomainIdentity: payload.inboundDomainIdentity,
            OutboundDomainIdentity: payload.outboundDomainIdentity,
            SesRegion: payload.sesRegion,
          },
        });

        setDomainStatus(response.checkEmailIdentitiesVerificationStatus);
        return response.checkEmailIdentitiesVerificationStatus;
      } catch (error) {
        console.error(error);
        setDomainStatus([]);
        throw error;
      }
    }

    async function listCustomStackIntegrations() {
      const {
        listCustomStacksIntegrations: { items },
      } = await listCustomStacksIntegrationsQuery();

      setCustomStackIntegrations(items || []);
    }

    async function getCustomStackIntegration(templateId) {
      const { getCustomStackIntegration: res } = await getCustomStackIntegrationQuery({
        input: {
          id: templateId,
        },
      });

      updateCustomStackIntegration(res);
    }

    async function deployCustomStackIntegration({ templateId, templateParameters }) {
      const { deployCustomStackIntegration: res } = await deployCustomStackIntegrationMutation({
        id: templateId,
        templateParameters,
      });

      updateCustomStackIntegration(res);
    }

    async function deleteCustomStackIntegration(templateId) {
      const { deleteCustomStackIntegration: res } = await deleteCustomStackIntegrationMutation({
        id: templateId,
      });

      updateCustomStackIntegration(res);
    }

    async function createGoogleAuthIntegration({ Name, ClientId, ClientSecret, RefreshToken }) {
      try {
        const integrationKey = LIST_INTEGRATIONS.GOOGLE_AUTH;
        const integrationInfo = INTEGRATION_PROPERTIES[integrationKey];

        await createGoogleAuthIntegrationMutation({
          InstanceId: agentStore.getInstanceId,
          Name,
          Type: integrationInfo.TYPE,
          Provider: integrationInfo.PROVIDER,
          Integration: {
            ClientId,
            ClientSecret,
            RefreshToken,
          },
        });

        uiStore.messageEndpointSuccess({ message: i18n.t("integration.googleAuth.creationSuccess") });
      } catch (error) {
        uiStore.messageEndpointError({ message: i18n.t("integration.googleAuth.creationError") });
        console.error(`Error - store - integrations - createGoogleAuthIntegration`, error);
      }
    }

    async function updateGoogleAuthIntegration({ Name, ClientId, ClientSecret, RefreshToken }) {
      try {
        const integrationKey = LIST_INTEGRATIONS.GOOGLE_AUTH;
        const integrationInfo = INTEGRATION_PROPERTIES[integrationKey];

        await updateGoogleAuthIntegrationMutation({
          InstanceId: agentStore.getInstanceId,
          Id: getGoogleAuthIntegration.value.Id,
          Name,
          Type: integrationInfo.TYPE,
          Provider: integrationInfo.PROVIDER,
          Integration: {
            ClientId,
            ClientSecret,
            RefreshToken,
          },
        });

        uiStore.messageEndpointSuccess({ message: i18n.t("integration.googleAuth.updateSuccess") });
      } catch (error) {
        uiStore.messageEndpointError({ message: i18n.t("integration.googleAuth.updateError") });
        console.error(`Error - store - integrations - updateGoogleAuthIntegration`, error);
      }
    }

    async function createQuickSightResources(payload) {
      try {
        await createQuickSightQuery({
          input: {
            InstanceId: payload.InstanceId,
            Email: payload.Email,
            FirstName: payload.FirstName,
            LastName: payload.LastName,
            UserName: payload.UserName,
            AccountName: payload.AccountName,
            ContactNumber: payload.ContactNumber,
            CustomStackId: payload.CustomStackId,
            SuccessEventName: STEP_MACHINE_EVENT_NAMES.SUCCESS,
            FailEventName: STEP_MACHINE_EVENT_NAMES.FAILURE,
            Timezone: payload.Timezone,
            Region: payload.Region,
          },
        });
      } catch (error) {
        console.error("Error - store - integrations - createQuickSightResources", error);
        throw error;
      }
    }
    async function createReplicaInstance(payload) {
      try {
        await replicateInstance({
          input: {
            InstanceId: payload.InstanceId,
            ReplicaRegion: "us-east-1",
            CustomStackId: payload.CustomStackId,
            ReplicaAlias: payload.ReplicaAlias,
          },
        });
      } catch (error) {
        console.error("Error - store - integrations - createReplicaInstance", error);
        throw error;
      }
    }

    async function createCrossRegionResources(payload) {
      try {
        await replicateCrossRegionResources({
          input: {
            InstanceId: payload.InstanceId,
            ReplicaRegion: "us-east-1",
            CustomStackId: payload.CustomStackId,
            ReplicaAlias: payload.ReplicaAlias,
          },
        });
      } catch (error) {
        console.error("Error - store - integrations - replicateCrossRegionResources", error);
        throw error;
      }
    }

    function deleteListIntegrationItem(payload) {
      const indexIntegration = integrations.value.findIndex((i) => i.Id === payload.Id);
      if (indexIntegration !== -1) {
        Vue.delete(integrations.value, indexIntegration);
      }
    }

    function updateListIntegrationItem(payload) {
      const indexIntegration = integrations.value.findIndex((i) => i.Id === payload.Id);
      if (indexIntegration !== -1) {
        Vue.set(integrations.value, indexIntegration, payload);
      } else {
        Vue.set(integrations.value, integrations.value.length, payload);
      }
    }

    function setListIntegrations(payload) {
      integrations.value = payload;
    }

    function setListContactFlows(payload) {
      contactFlows.value = payload;
    }
    function setError(payload) {
      error.value = payload;
    }
    function setIntegrationCloudFormationStatus(payload) {
      integrationCloudFormationStatus.value = payload;
    }
    function cleanMessages() {
      error.value = "";
      integrationCloudFormationStatus.value = "";
    }

    function setDomainStatus(payload) {
      domainStatus.value = payload;
    }
    function setCustomStackIntegrations(payload) {
      customStackIntegrations.value = payload;
    }
    function updateCustomStackIntegration(payload) {
      const { id, ...updatedData } = payload;
      const index = customStackIntegrations.value.findIndex((integration) => integration.id === id);
      if (index !== -1) {
        Vue.set(customStackIntegrations.value, index, { ...customStackIntegrations.value[index], ...updatedData });
      }
    }

    return {
      //States
      integrations,
      customStackIntegrations,
      integrationCloudFormationStatus,
      contactFlows,
      error,
      domainStatus,
      //Getters
      getShouldUserLoginTeams,
      getIsTeamIntegrationActive,
      getListIntegrations,
      getListContactFlows,
      getError,
      getIntegrationCloudFormationStatus,
      getSmsTroughPinpointIntegration,
      getOffice365Integration,
      getDomainStatus,
      getOfficeDynamicsIntegration,
      getCustomStackIntegrations,
      getGoogleAuthIntegration,
      //Actions
      createSmsTroughPinpointIntegration,
      listIntegrations,
      getIntegration,
      checkIntegrationCloudFormationStatus,
      updateSmsTroughPinpointIntegration,
      deleteIntegration,
      getContactFlows,
      createOffice365Integration,
      updateOffice365Integration,
      createOfficeDynamicsIntegrations,
      updateOfficeDynamicsIntegration,
      integrationChanged,
      integrationDeleted,
      checkIdentitiesVerificationStatus,
      listCustomStackIntegrations,
      getCustomStackIntegration,
      deployCustomStackIntegration,
      deleteCustomStackIntegration,
      createGoogleAuthIntegration,
      updateGoogleAuthIntegration,
      createQuickSightResources,
      createReplicaInstance,
      createCrossRegionResources,
      //Mutations
      deleteListIntegrationItem,
      updateListIntegrationItem,
      setListIntegrations,
      setListContactFlows,
      setError,
      setIntegrationCloudFormationStatus,
      cleanMessages,
      setDomainStatus,
      setCustomStackIntegrations,
      updateCustomStackIntegration,
    };
  },
  {
    persist: true,
  }
);
