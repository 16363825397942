import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { existPartnerTemplate } from "../common/partners-templates";
import { Notice } from "view-design";
import { i18n } from "@/plugins/language";

export const useUiStore = defineStore(
  "ui",
  () => {
    const isSidebarShown = ref(true);
    const isFullScreen = ref(false);
    const agentPerformanceExpand = ref(false);
    const partnerTemplate = ref(null);
    const list = ref([
      {
        name: "first-section",
        topDivision: false,
        key: "links",
        child: [
          {
            label: i18n.t("tooltip.home"),
            name: "dashboard",
            route: "/dashboard",
            icon: "fa-home",
            tooltip: "Home",
            labelTranslated: "tooltip.home",
            id: "childDashboard",
          },
          {
            label: i18n.t("tooltip.customerEngagement"),
            name: "engage",
            route: "/engage",
            icon: "fa-user-headset",
            tooltip: "",
            labelTranslated: "tooltip.customerEngagement",
            id: "childEngage",
          },
          {
            label: i18n.t("tooltip.queues"),
            name: "queues",
            route: "/queues",
            icon: "fa-home",
            tooltip: "Queues",
            labelTranslated: "tooltip.queues",
            id: "childQueues",
          },
          {
            label: i18n.t("tooltip.activitySearch"),
            name: "activity",
            route: "/activity",
            icon: "fa-search",
            tooltip: "",
            labelTranslated: "tooltip.activitySearch",
            id: "childActivity",
          },
          {
            label: i18n.t("tooltip.newActivitySearch"),
            name: "activityNew",
            route: "/activitynew",
            icon: "fa-search",
            tooltip: "",
            labelTranslated: "tooltip.newActivitySearch",
            id: "newChildActivity",
          },
          {
            label: i18n.t("tooltip.historicalReporting"),
            name: "reports",
            route: "/reports",
            icon: "fa-chart-bar",
            tooltip: "",
            labelTranslated: "tooltip.historicalReporting",
            id: "childReports",
          },
          {
            label: i18n.t("tooltip.newHistoricalReporting"),
            name: "reportsNew",
            route: "/reportsnew",
            icon: "fa-chart-bar",
            tooltip: "",
            labelTranslated: "tooltip.newHistoricalReporting",
            id: "childReports",
          },
          {
            label: i18n.t("tooltip.directory"),
            name: "contacts",
            route: "/contacts",
            icon: "fa-address-book",
            tooltip: "",
            labelTranslated: "tooltip.directory",
            id: "childDirectory",
          },
          {
            label: i18n.t("tooltip.userManagement"),
            name: "users",
            route: "/users",
            icon: "fa-users",
            tooltip: "",
            labelTranslated: "tooltip.userManagement",
            id: "childUserManagement",
          },
          {
            label: i18n.t("tooltip.support"),
            name: "support",
            visible: false,
            route: "/support",
            icon: "fa-wrench",
            tooltip: "",
            labelTranslated: "tooltip.support",
            id: "childSupport",
          },
        ],
      },
      {
        name: "second-section",
        topDivision: false,
        key: "analytics",
        lastItem: true,
        child: [
          {
            label: i18n.t("settings.customLinks.title"),
            name: "link-solid",
            visible: true,
            route: "",
            icon: "fa-link",
            tooltip: "",
            dropdown: true,
            dropdownTitle: i18n.t("settings.customLinks.title"),
            dropdownLinks: [],
            isDropdownGrouped: true,
            id: "externalLink",
          },
          {
            label: "Connect",
            name: "connect",
            visible: true,
            route: "",
            icon: "",
            tooltip: "",
            dropdown: true,
            dropdownTitle: i18n.t("settings.connect.analytics"),
            dropdownLinks: [
              {
                label: i18n.t("settings.connect.realTimeMetrics"),
                url: "/real-time-metrics/landing",
              },
              {
                label: i18n.t("settings.connect.historicalMetrics"),
                url: "/historical-metrics/landing",
              },
              {
                label: i18n.t("settings.connect.contactSearch"),
                url: "/contact-search",
              },
              {
                label: i18n.t("settings.connect.loginLogoutReport"),
                url: "/login-logout-report",
              },
              {
                label: i18n.t("settings.connect.savedReports"),
                url: "/metrics-reports",
              },
            ],
            id: "childConnect",
          },
          {
            label: "Settings",
            name: "settings",
            visible: true,
            route: "/settings",
            icon: "fa-wrench",
            tooltip: "",
            id: "childSettings",
          },
        ],
      },
    ]);
    const isLoadingVisible = ref(false);

    const showSideBar = computed(() => isSidebarShown.value);
    const getAgentPerformanceExpand = computed(() => agentPerformanceExpand.value);
    const getIsFullScreen = computed(() => isFullScreen.value);
    const getMenuList = computed(() => list.value);
    const getPartnerTemplate = computed(() => partnerTemplate.value);
    const getIsLoadingVisible = computed(() => isLoadingVisible.value);

    function messageEndpointSuccess({ message }) {
      Notice.destroy();
      Notice.success({
        title: message,
        duration: 5,
      });
    }

    function messageEndpointError({ message }) {
      Notice.destroy();
      Notice.error({
        title: message,
        duration: 5,
      });
    }

    function setLoadingVisible(payload) {
      updateLoadingVisible(payload);
    }
    function addCustomLinksToMenuBar({ customLinks }) {
      const customLinksGroupedByType = [];
      customLinks.forEach((item) => {
        const typeIndex = customLinksGroupedByType.findIndex((group) => group.type === item.type);
        if (typeIndex !== -1) {
          customLinksGroupedByType[typeIndex].items.push(item);
        } else {
          customLinksGroupedByType.push({
            type: item.type,
            items: [item],
          });
        }
      });
      updateCustomLinksOfMenu(customLinksGroupedByType);
    }

    function setSidebarShown(val) {
      isSidebarShown.value = val;
    }

    function setFullScreen(payload) {
      isFullScreen.value = payload;
    }

    function setAgentPerformanceExpand() {
      agentPerformanceExpand.value = !agentPerformanceExpand.value;
    }

    function initUI() {
      const template = existPartnerTemplate();
      if (template) {
        partnerTemplate.value = {};
        partnerTemplate.value = template;
      } else {
        partnerTemplate.value = null;
      }
      isSidebarShown.value = true;
      isFullScreen.value = false;
      agentPerformanceExpand.value = false;
    }

    function updateState(value) {
      if (!partnerTemplate.value) {
        partnerTemplate.value = value.partnerTemplate;
      }
      isSidebarShown.value = value.isSidebarShown;
      isFullScreen.value = value.isFullScreen;
      agentPerformanceExpand.value = value.agentPerformanceExpand;
    }

    function updateLoadingVisible(value) {
      isLoadingVisible.value = value;
    }

    function updateCustomLinksOfMenu(state, value) {
      list.value.forEach((element) => {
        if (element.key === "analytics") {
          element.child[0].dropdownLinks = value;
        }
      });
    }

    return {
      //States
      isSidebarShown,
      isFullScreen,
      agentPerformanceExpand,
      partnerTemplate,
      list,
      isLoadingVisible,
      //Getters
      showSideBar,
      getAgentPerformanceExpand,
      getIsFullScreen,
      getMenuList,
      getPartnerTemplate,
      getIsLoadingVisible,
      //Actions
      messageEndpointSuccess,
      messageEndpointError,
      setLoadingVisible,
      addCustomLinksToMenuBar,
      //Mutations
      setSidebarShown,
      setFullScreen,

      setAgentPerformanceExpand,
      initUI,
      updateState,
      updateLoadingVisible,
      updateCustomLinksOfMenu,
    };
  },
  {
    persist: true,
  }
);
