import { defineStore } from "pinia";

import { computed, ref } from "vue";
import { i18n } from "@/plugins/language";

import { Modal, Notice } from "view-design";
import Vue from "vue";
import { abilityPlugin } from "@/plugins/ability";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { verifyCredsSubscription } from "@/common/services/graphql.service";
import { useLogsStore } from "./logs";
import { useDashboardStore } from "./dashboard";
import { useQueueStore } from "./queue";
import { useCurrentUserStore } from "./current-user";

function setPermissionGroups(model) {
  let permissionGroup = [];

  if (!model) return permissionGroup;

  for (let x = 0; x < model.length; x++) {
    let securityGroup = model[x];

    if (securityGroup.PermissionItems) {
      for (let x = 0; x < securityGroup.PermissionItems.length; x++) {
        let securityItem = securityGroup.PermissionItems[x];
        if (securityItem.Values) {
          Object.keys(securityItem.Values).forEach((key) => {
            let value = securityItem.Values[key];
            permissionGroup.push({
              actions: key.toLocaleLowerCase(),
              subject: securityItem.Action,
              inverted: !value,
            });
          });
        }
      }
    }
  }

  return permissionGroup;
}

function getMyPermissions(model) {
  if (!model) return [];
  return setPermissionGroups(model.PermissionGroups);
}

export const useIndexStore = defineStore(
  "index",
  () => {
    const logStore = useLogsStore();
    const dashboardStore = useDashboardStore();
    const queueStore = useQueueStore();
    const currentUserStore = useCurrentUserStore();

    const staticNumbers = ref({
      "us-east-1": "+18042221111",
      "us-west-2": "+18042221111",
      "eu-central-1": "+448000154245",
      "ap-southeast-2": "+61291920995",
      "ap-northeast-1": "+81332249999",
    });
    const appsyncSubscriptions = ref([]);
    const failedSubscriptions = ref([]);
    const showMessageFailedSubscriptions = ref(true);
    const webRTCDetection = ref({
      hasMicrophone: false,
      hasSpeakers: false,
      hasWebcam: false,
      isMicrophoneAlreadyCaptured: false,
      isWebcamAlreadyCaptured: false,
      MediaDevices: [],
      isHTTPs: location.protocol === "https:",
      canEnumerate: false,
    });
    const wWidth = ref(window.innerWidth);
    const wHeight = ref(window.innerHeight);
    const role = ref({
      isSupervisor: false,
      isAgent: false,
    });
    const initialized = ref(false);
    const isAuthenticating = ref(false);
    const isConnected = ref(false);
    const isFetchingAuthenticating = ref(false);
    const ccpInstanceId = ref(null);
    const isDissmissTestCall = ref(false);
    const isLoading = ref(false);
    const isModalActive = ref(false);
    const isVolumnSilent = ref(false);
    const isNitroMode = ref(false);
    const isVolumnControllShow = ref(false);
    const isMicControllShow = ref(false);
    const isMicSilent = ref(false);
    const isShowTeamStatus = ref(false);
    const isShowAlertsDrawer = ref(false);
    const hasAlerts = ref(false);
    const rules = ref([]);
    const enableBtnAws = ref(false);
    const lockDashboardDrag = ref(false);
    const soundVolume = ref(50);
    const api = ref({});
    const globalCallDuration = ref(false);
    const dashboardCards = ref({
      colOne: [
        {
          id: 5,
          name: "Contacts In Queue",
          action: "contacts_in_queue",
          component: "contacts-in-queue",
          isShow: true,
        },
        {
          id: 7,
          name: "Contacts",
          action: "contacts",
          component: "contact-metrics",
          isShow: true,
        },
      ],
      colTwo: [
        {
          id: 2,
          name: "My Recent Activity",
          action: "my_recent_activity",
          component: "my-recent-activity",
          isShow: true,
        },
        {
          id: 1,
          name: "My Performance",
          action: "my_performance",
          component: "my-performance",
          isShow: true,
        },
      ],
      colThree: [
        {
          id: 3,
          name: "Agent Availabilty",
          action: "agent_availability",
          component: "agent-availability",
          isShow: true,
        },
        {
          id: 4,
          name: "Wait Time",
          action: "wait_time",
          component: "wait-time",
          isShow: true,
        },
      ],
    });
    const showChangeAgentStatusDialog = ref(false);
    const selectedAgentForStatusChange = ref(null);
    const mobileView = ref(false);
    const externalContactLeft = ref(false);

    const getExternalContactLeft = computed(() => externalContactLeft.value);
    const getRole = computed(() => role.value);
    const getIsSupervisor = computed(() => role.value.isSupervisor);
    const getIsLoading = computed(() => isLoading.value);
    const getCcpInstanceId = computed(() => ccpInstanceId.value);
    const getInitialized = computed(() => initialized.value);
    const getIsNitroMode = computed(() => isNitroMode.value);
    const getWinWidth = computed(() => wWidth.value);
    const getWinHeight = computed(() => wHeight.value);
    const getWebRTCDetection = computed(() => webRTCDetection.value);
    const getGlobalCallDuration = computed(() => globalCallDuration.value);
    const getIsDissmissTestCall = computed(() => isDissmissTestCall.value);
    const getStaticNumbersPerRegion = computed(() => staticNumbers.value);
    const getShowMessageFailedSubscriptions = computed(() => showMessageFailedSubscriptions.value);

    async function handleFailedSubscriptions({ dispatch }) {
      let needToRefetchQueueMetrics = false;

      for (let i = 0; i < failedSubscriptions.value.length; i++) {
        try {
          var v = failedSubscriptions.value[i];
          logStore.addItem({
            component: "APPSYNC",
            level: "DEBUG",
            text: `Re-subscribing - ${v.id}`,
          });
          v.sub = v.observable.subscribe(v.handler);

          logStore.addItem({
            component: "APPSYNC",
            level: "INFO",
            text: `Successfully resubscribed - ${v.id}`,
          });

          if (v.linkedQuery) {
            if (v.linkedQuery.name === "queue/onFetchQueue") {
              needToRefetchQueueMetrics = true;
            } else {
              await dispatch(v.linkedQuery.name, v.linkedQuery.params);
            }
          }

          failedSubscriptions.value.splice(i, 1);
        } catch (err) {
          console.error(err);
          logStore.addItem({
            component: "APPSYNC",
            level: "WARN",
            text: `Failed to re-subscribe - ${v.id}`,
          });
          if (getShowMessageFailedSubscriptions.value) messageFailedSubscriptions({});
        }
      }

      if (needToRefetchQueueMetrics) {
        let startDate = getTimeObject().getOffsetTime();
        startDate.setHours(0);
        startDate.setMinutes(0);

        dashboardStore.setStartDate(startDate);
        dashboardStore.setEndDate(getTimeObject().getOffsetTime());
        try {
          let { InstanceId, Queues } = currentUserStore ? currentUserStore : {};
          await queueStore.onFetchQueue({
            InstanceId,
            Queues,
            DateRange: dashboardStore.dateRange,
          });
        } catch (err) {
          console.error("error re-fetching queue metrics: ", err);
          if (getShowMessageFailedSubscriptions.value) messageFailedSubscriptions({});
        }
      }
    }

    function resubscribeAll() {
      appsyncSubscriptions.value.forEach((session) => {
        session.sub.unsubscribe();
      });

      appsyncSubscriptions.value.forEach((session) => {
        if (session.sub["_state"] !== "ready") {
          session.observable.subscribe(session.handler);
        }
      });
    }
    function unsubscribeAll() {
      appsyncSubscriptions.value.forEach((session) => {
        session.sub.unsubscribe();
      });
    }
    function setCcpInstanceId(ccpInstanceId) {
      commitCcpInstanceId(ccpInstanceId);
    }

    function INITIALIZE_RULES({ agent }) {
      if (agent && Object.prototype.hasOwnProperty.call(agent, "SecurityProfile")) {
        let userPermisisons = getMyPermissions(agent.SecurityProfile);
        CREATE_SESSION_RULES(userPermisisons);
      }
    }
    function SET_CURRENT_USER_RULES(payload) {
      if (payload && Object.prototype.hasOwnProperty.call(payload, "PermissionGroups")) {
        let userPermisisons = getMyPermissions(payload);
        CREATE_SESSION_RULES(userPermisisons);
      }
    }

    function UPDATE_RULES({ profile }) {
      if (profile && Object.prototype.hasOwnProperty.call(profile, "SecurityProfile")) {
        const userPermisisons = getMyPermissions(profile.SecurityProfile);
        CREATE_SESSION_RULES(userPermisisons);
      } else {
        const userPermisisons = getMyPermissions(profile);
        CREATE_SESSION_RULES(userPermisisons);
      }
    }

    async function verifyCredentials(payload) {
      try {
        const res = await verifyCredsSubscription(payload);
        setNitroMode(true);
        return res;
      } catch (error) {
        console.error(error);
      }
    }
    function micCheck() {
      Notice.warning({
        name: "mic-permisision",
        title: i18n.t("notifications.pleaseAllowAccessToMicrophone"),
        duration: 0,
        desc: i18n.t("notifications.theDescWillHideWhenYouSetRender"),
        render: (h) => {
          return h("span", [
            i18n.t("notifications.unableToMakeOrReceiveCallsClick") + " ",
            h(
              "a",
              {
                on: {
                  click: async () => {
                    try {
                      await navigator.mediaDevices.getUserMedia({ audio: true });
                      webRTCDetection.value.hasMicrophone = true;
                      Notice.close("mic-permisision");
                    } catch (err) {
                      webRTCDetection.value.hasMicrophone = false;
                      Modal.info({
                        title: i18n.t("modal.changeSitesMicrophonePermissions"),
                        onOk: () => {
                          Notice.close("mic-permisision");
                        },
                        render: (h) => {
                          return h("div", [
                            h("p", `1. ${i18n.t("modal.openChrome")}`),
                            h("p", [
                              `2. ${i18n.t("modal.atTheTopRightClickMore")} `,
                              h("i", {
                                class: ["fa fa-ellipsis-v"],
                              }),
                              ` > ${i18n.t("modal.settings")}.`,
                            ]),
                            h("p", [`3. ${i18n.t("modal.atTheBottomClick")} `, h("b", `${i18n.t("modal.advanced")} `)]),
                            h("p", [
                              `4. ${i18n.t("modal.underPrivacyAndSecurityClick")} `,
                              h("b", `${i18n.t("modal.contentSettings")}.`),
                            ]),
                            h("p", [`5. ${i18n.t("modal.click")}`, h("b", ` ${i18n.t("modal.microphone")}.`)]),
                            h("p", [
                              `6. ${i18n.t("modal.turn")}`,
                              h("b", ` ${i18n.t("modal.askBeforeAccessing")}`),
                              ` ${i18n.t("modal.onOrOff")}.`,
                            ]),
                          ]);
                        },
                      });
                      Notice.close("mic-permisision");
                    }
                  },
                },
              },
              i18n.t("notifications.here")
            ),
          ]);
        },
      });
    }

    function handleStoragePutError(error) {
      if (error.code === "RequestTimeTooSkewed") {
        Notice.error({
          name: "s3-upload-time-skewed",
          title: i18n.t("notifications.uploadFailed"),
          duration: 0,
          render: (h) => {
            return [
              h("p", `${i18n.t("modal.itLooksLikeSomethingsWrongWithTheTimeInYourMachine")}.`),
              h("br"),
              h("p", `${i18n.t("modal.pleaseCheckTheTimeIfItsCorrect")}.`),
              h("br"),
              h("p", `${i18n.t("modal.ifYourMachineTimeIsCorrectMaybeTheTimezoneWasNotSetCorrectly")}.`),
            ];
          },
        });
      }
    }

    function detectWebRTC() {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        navigator.enumerateDevices = async function (callback) {
          try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            callback(devices);
          } catch (error) {
            console.error(error);
          }
        };
      }
      if (typeof MediaStreamTrack !== "undefined" && "getSources" in MediaStreamTrack) {
        webRTCDetection.value.canEnumerate = true;
      } else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
        webRTCDetection.value.canEnumerate = true;
      }
      checkDeviceSupport();
    }
    function updateAuthentication(payload) {
      SET_FETCHING_AUTHENTICATION(payload);
    }

    function messageFailedSubscriptions() {
      Notice.destroy();
      Notice.error({
        title: i18n.t("subscriptions.errorTitle"),
        desc: i18n.t("subscriptions.errorDescription"),
        duration: 0,
        onClose: () => {
          setShowMessageFailedSubscriptions(true);
        },
      });
      setShowMessageFailedSubscriptions(false);
    }

    function markExternalContactLeft() {
      externalContactLeft.value = true;
    }
    function unMarkExternalContact() {
      externalContactLeft.value = false;
    }
    function addSubscriptionToList(v) {
      if (v.id.endsWith("undefined")) {
        return;
      }
      let index = appsyncSubscriptions.value.findIndex((s) => s.id === v.id);
      if (index === -1) {
        v.sub = v.observable.subscribe(v.handler);
        v.tries = 0;
        appsyncSubscriptions.value.push(v);
      }
    }
    function removeSubscriptionFromList({ id }) {
      let index = appsyncSubscriptions.value.findIndex((s) => s.id === id);
      if (index > -1) {
        appsyncSubscriptions.value[index].sub.unsubscribe();
        appsyncSubscriptions.value.splice(index, 1);
      }
    }
    function onDissmissTestCall(v) {
      isDissmissTestCall.value = v;
    }
    function setGlobalCallDuration(v) {
      globalCallDuration.value = v;
    }
    function setWindowSize(v) {
      wWidth.value = v.innerWidth;
      wHeight.value = v.innerHeight;
      mobileView.value = v < 1024 ? true : false;
    }
    function SET_AUTH_AWS(v) {
      enableBtnAws.value = v;
    }
    function setNitroMode(v) {
      isNitroMode.value = v;
    }
    function SET_CCP_INSTANCE_ALIAS(v) {
      ccpInstanceId.value = v;
    }

    function SET_VOLUME(v) {
      soundVolume.value = v;
    }

    function SET_DRAGGABLE_LOCK(v) {
      lockDashboardDrag.value = v;
    }

    function CREATE_SESSION_RULES(v) {
      rules.value = v;
    }

    function SET_HYBERNATE(v) {
      isAuthenticating.value = v;
    }

    function SET_FETCHING_AUTHENTICATION(v) {
      isFetchingAuthenticating.value = v;
    }

    function setShowAlertsDrawer(v) {
      isShowAlertsDrawer.value = v;
    }

    function setShowTeamStatus(v) {
      isShowTeamStatus.value = v;
    }

    function setVolumnSilence(v) {
      isVolumnSilent.value = v;
    }

    function setVolumnControll(v) {
      isVolumnControllShow.value = v;
    }

    function setMicControll(v) {
      isMicControllShow.value = v;
    }

    function setMicSilence(v) {
      isMicSilent.value = v;
    }

    function setModalActive(v) {
      isModalActive.value = v;
    }

    function commitCcpInstanceId(ccpInstanceId) {
      ccpInstanceId.value = ccpInstanceId;
    }

    function SET_INITIALIZED(value) {
      initialized.value = value;
    }

    function initState() {
      staticNumbers.value = {
        "us-east-1": "+18042221111",
        "us-west-2": "+18042221111",
        "eu-central-1": "+448000154245",
        "ap-southeast-2": "+61291920995",
        "ap-northeast-1": "+81332249999",
      };
      webRTCDetection.value = {
        hasMicrophone: false,
        hasSpeakers: false,
        hasWebcam: false,
        isMicrophoneAlreadyCaptured: false,
        isWebcamAlreadyCaptured: false,
        MediaDevices: [],
        isHTTPs: location.protocol === "https:",
        canEnumerate: false,
      };
      wWidth.value = window.innerWidth;
      role.value = {
        isSupervisor: false,
        isAgent: false,
      };
      initialized.value = false;
      isAuthenticating.value = false;
      isConnected.value = false;
      isFetchingAuthenticating.value = false;
      ccpInstanceId.value = null;
      isDissmissTestCall.value = false;
      isLoading.value = false;
      isModalActive.value = false;
      isVolumnSilent.value = false;
      isNitroMode.value = false;
      isVolumnControllShow.value = false;
      isMicControllShow.value = false;
      isMicSilent.value = false;
      isShowTeamStatus.value = false;
      isShowAlertsDrawer.value = false;
      hasAlerts.value = false;
      rules.value = [];
      enableBtnAws.value = false;
      lockDashboardDrag.value = true;
      soundVolume.value = 50;
      api.value = {};
      globalCallDuration.value = 0;
      dashboardCards.value = {
        colOne: [
          {
            id: 5,
            name: "Contacts In Queue",
            action: "contacts_in_queue",
            component: "contacts-in-queue",
            isShow: true,
          },
          {
            id: 7,
            name: "Contacts",
            action: "contacts",
            component: "contact-metrics",
            isShow: true,
          },
        ],
        colTwo: [
          {
            id: 2,
            name: "My Recent Activity",
            action: "my_recent_activity",
            component: "my-recent-activity",
            isShow: true,
          },
          {
            id: 1,
            name: "My Performance",
            action: "my_performance",
            component: "my-performance",
            isShow: true,
          },
        ],
        colThree: [
          {
            id: 3,
            name: "Agent Availabilty",
            action: "agent_availability",
            component: "agent-availability",
            isShow: true,
          },
          {
            id: 4,
            name: "Wait Time",
            action: "wait_time",
            component: "wait-time",
            isShow: true,
          },
        ],
      };
    }

    function updateState(value) {
      staticNumbers.value = value.staticNumbers;
      wWidth.value = value.wWidth;
      role.value = value.role;
      initialized.value = value.initialized;
      isAuthenticating.value = value.isAuthenticating;
      isConnected.value = value.isConnected;
      isFetchingAuthenticating.value = value.isFetchingAuthenticating;
      ccpInstanceId.value = value.ccpInstanceId;
      isDissmissTestCall.value = value.isDissmissTestCall;
      isLoading.value = value.isLoading;
      isModalActive.value = value.isModalActive;
      isVolumnSilent.value = value.isVolumnSilent;
      isNitroMode.value = value.isNitroMode;
      isVolumnControllShow.value = value.isVolumnControllShow;
      isMicControllShow.value = value.isMicControllShow;
      isMicSilent.value = value.isMicSilent;
      isShowTeamStatus.value = value.isShowTeamStatus;
      isShowAlertsDrawer.value = value.isShowAlertsDrawer;
      hasAlerts.value = value.hasAlerts;
      rules.value = value.rules;
      enableBtnAws.value = value.enableBtnAws;
      lockDashboardDrag.value = value.lockDashboardDrag;
      soundVolume.value = value.soundVolume;
      api.value = value.api;
      globalCallDuration.value = value.globalCallDuration;
      dashboardCards.value = value.dashboardCards;
    }

    function setShowChangeAgentStatusDialog(v) {
      showChangeAgentStatusDialog.value = v;
    }

    function setSelectedAgentForStatusChange(v) {
      selectedAgentForStatusChange.value = v;
    }

    function setShowMessageFailedSubscriptions(value) {
      showMessageFailedSubscriptions.value = value;
    }

    let checkDeviceSupport = function (callback) {
      if (!webRTCDetection.value.canEnumerate) return;
      if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources)
        navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);

      if (!navigator.enumerateDevices && navigator.enumerateDevices)
        navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);

      if (!navigator.enumerateDevices) {
        if (callback) callback();
        return;
      }

      webRTCDetection.value.MediaDevices = [];
      navigator.enumerateDevices(function (devices) {
        devices.forEach(function (_device) {
          var device = {};
          for (var d in _device) {
            device[d] = _device[d];
          }

          if (device.kind === "audio") device.kind = "audioinput";

          if (device.kind === "video") device.kind = "videoinput";

          var skip;
          webRTCDetection.value.MediaDevices.forEach(function (d) {
            if (d.id === device.id && d.kind === device.kind) skip = true;
          });

          if (skip) return;

          if (!device.deviceId) device.deviceId = device.id;

          if (!device.id) device.id = device.deviceId;

          if (device.label) {
            if (device.kind === "videoinput" && !webRTCDetection.value.isWebcamAlreadyCaptured) {
              webRTCDetection.value.isWebcamAlreadyCaptured = true;
            }
            if (device.kind === "audioinput" && !webRTCDetection.value.isMicrophoneAlreadyCaptured) {
              webRTCDetection.value.isMicrophoneAlreadyCaptured = true;
            }
          }

          if (device.kind === "audioinput" && device.label) {
            webRTCDetection.value.hasMicrophone = true;
          }

          if (device.kind === "audiooutput") webRTCDetection.value.hasSpeakers = true;

          if (device.kind === "videoinput") webRTCDetection.value.hasWebcam = true;

          webRTCDetection.value.MediaDevices.push(device);
        });

        if (callback) {
          callback();
        }
      });
    };

    setInterval(() => {
      let subs = appsyncSubscriptions.value;

      let closedSubs = checkForClosedSubs(subs);

      if (closedSubs.length > 0) {
        failedSubscriptions.value = closedSubs;
        handleFailedSubscriptions();
      }
    }, 2000);

    function checkForClosedSubs(subs) {
      if (subs.length > 0) {
        let closedSubs = subs.filter((sub) => {
          return sub.sub["_state"] !== "ready";
        });
        return closedSubs;
      }
      return [];
    }

    return {
      //States
      staticNumbers,
      appsyncSubscriptions,
      failedSubscriptions,
      showMessageFailedSubscriptions,
      webRTCDetection,
      wWidth,
      wHeight,
      role,
      initialized,
      isAuthenticating,
      isConnected,
      isFetchingAuthenticating,
      ccpInstanceId,
      isDissmissTestCall,
      isLoading,
      isModalActive,
      isVolumnSilent,
      isNitroMode,
      isVolumnControllShow,
      isMicControllShow,
      isMicSilent,
      isShowTeamStatus,
      isShowAlertsDrawer,
      hasAlerts,
      rules,
      enableBtnAws,
      lockDashboardDrag,
      soundVolume,
      api,
      globalCallDuration,
      dashboardCards,
      showChangeAgentStatusDialog,
      selectedAgentForStatusChange,
      mobileView,
      externalContactLeft,
      //Getters
      getExternalContactLeft,
      getRole,
      getIsSupervisor,
      getIsLoading,
      getCcpInstanceId,
      getInitialized,
      getIsNitroMode,
      getWinWidth,
      getWinHeight,
      getWebRTCDetection,
      getGlobalCallDuration,
      getIsDissmissTestCall,
      getStaticNumbersPerRegion,
      getShowMessageFailedSubscriptions,
      //Actions
      handleFailedSubscriptions,
      resubscribeAll,
      unsubscribeAll,
      setCcpInstanceId,
      INITIALIZE_RULES,
      SET_CURRENT_USER_RULES,
      UPDATE_RULES,
      verifyCredentials,
      micCheck,
      handleStoragePutError,
      detectWebRTC,
      updateAuthentication,
      messageFailedSubscriptions,
      //Mutations
      markExternalContactLeft,
      unMarkExternalContact,
      addSubscriptionToList,
      removeSubscriptionFromList,
      onDissmissTestCall,
      setGlobalCallDuration,
      setWindowSize,
      SET_AUTH_AWS,
      setNitroMode,
      SET_CCP_INSTANCE_ALIAS,
      SET_VOLUME,
      SET_DRAGGABLE_LOCK,
      CREATE_SESSION_RULES,
      SET_HYBERNATE,
      SET_FETCHING_AUTHENTICATION,
      setShowAlertsDrawer,
      setShowTeamStatus,
      setVolumnSilence,
      setVolumnControll,
      setMicControll,
      setMicSilence,
      setModalActive,
      commitCcpInstanceId,
      SET_INITIALIZED,
      initState,
      updateState,
      setShowChangeAgentStatusDialog,
      setSelectedAgentForStatusChange,
      setShowMessageFailedSubscriptions,
    };
  },
  {
    persist: true,
  }
);
