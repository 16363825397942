import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { i18n } from "@/plugins/language";
import { NOT_APPLICABLE } from "@connectpath/common-frontend";
import {
  createReportMutation,
  updateReportMutation,
  listReportsQuery,
  deleteReportMutation,
  getReportQuery,
  getListDashboardsQuery,
  updateReportingV2AccessMutation,
} from "@/common/services/graphql.service";
import { useUiStore } from "./ui";

let tempSubReport = null;

export const useReportsStore = defineStore(
  "reports",
  () => {
    const uiStore = useUiStore();
    const list = ref([]);
    const loadedReport = ref(null);
    const loadedReportXlsx = ref(null);
    const showPdfTemplate = ref(false);
    const unseenReportNotifications = ref(0);
    const listDashboards = ref([]);

    const getListDashboards = computed(() => listDashboards.value);
    const getReportsList = computed(() => list.value);

    async function createReport(payload) {
      const res = await createReportMutation(payload, window.special_token.jwtToken);

      if (res?.createReport.Status === "error") {
        throw Error(res?.createReport.Message);
      }
      res.createReport.HideNA = payload.HideNA;
      addReportToList(res?.createReport);
      this.isLoading = false;
    }

    async function updateReport(payload) {
      const res = await updateReportMutation(payload, window.special_token.jwtToken);

      res.updateReport.HideNA = payload.HideNA;
      setUpdateReport(res?.updateReport);
      this.isLoading = false;
    }
    async function listReports(payload) {
      try {
        const res = await listReportsQuery(payload);

        setReportList((res?.listReports || []).items);
        unseenReportNotifications.value = 0;
      } catch (error) {
        console.error("err fetching reports: ", error);
        throw error;
      }
    }
    async function deleteReport(payload) {
      try {
        await deleteReportMutation({ id: payload.id });
        removeReportFromList(payload);
      } catch (error) {
        console.error("err deleting report: ", error);
        throw error;
      }
    }
    async function getReport(payload) {
      try {
        const res = await getReportQuery({ id: payload.id });

        if (payload.type === "export") {
          let exported = await fetch(res?.getReport.Location.replace(".json", ""));
          exported = await exported.text();
          let anchor = document.createElement("a");
          anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(exported);
          anchor.target = "_blank";
          anchor.download = `ActivityExport-${getTimeObject().offsetToISOString()}.csv`;
          anchor.click();
        }

        let report = await fetch(res?.getReport.Location);

        if (report.status !== 200) {
          throw new Error("Report error");
        }
        const filters = res?.getReport.Filters;

        let reportJson = await report.json();
        reportJson.Filters = filters;

        if (payload.hideNA) {
          const indexAgentNA = reportJson.data.charts.agentDistribution.labels.indexOf(NOT_APPLICABLE);
          if (indexAgentNA > -1) {
            reportJson.data.charts.agentDistribution.labels.splice(indexAgentNA, 1);
            reportJson.data.charts.agentDistribution.series.splice(indexAgentNA, 1);
          }
          const indexQueueNA = reportJson.data.charts.queueDistribution.labels.indexOf("N/A");
          if (indexQueueNA > -1) {
            reportJson.data.charts.queueDistribution.labels.splice(indexQueueNA, 1);
            reportJson.data.charts.queueDistribution.series.splice(indexQueueNA, 1);
          }
        }

        setLoadedReport({
          payload: reportJson,
          xlsx: res?.getReport.LocationXlsx,
        });
      } catch (err) {
        console.error("err: ", err);
        throw err;
      }
    }

    async function listDashboard({ InstanceId, SecurityProfileId }) {
      try {
        const response = await getListDashboardsQuery({
          input: {
            InstanceId,
            SecurityProfileId,
          },
        });

        setListDashboards(response.listDashboards?.DashboardSummaryList || []);
      } catch (error) {
        setListDashboards([]);
        uiStore.messageEndpointError({ message: `${i18n.t("historicalReportsv2.listingError")}` });
        console.error(`Error listDashboards`, error);
      }
    }
    async function updateReportingV2Access({ InstanceId, SecurityProfileId, ReportingV2Access }) {
      try {
        await updateReportingV2AccessMutation({
          InstanceId,
          SecurityProfileId,
          ReportingV2Access,
        });

        uiStore.messageEndpointSuccess({ message: `${i18n.t("historicalReportsv2.updateSuccessfully")}` });
      } catch (error) {
        uiStore.messageEndpointError({ message: `${i18n.t("historicalReportsv2.updatingError")}` });
        console.error(`Error updateReportingV2Access`, error);
      }
    }
    function setUpdateReport(payload) {
      if (!payload.Parent) {
        let indexOfParent = list.value.findIndex((r) => r.id === payload.ParentId);

        if (indexOfParent < 0) {
          tempSubReport = payload;
        } else {
          let indexOfChild = list.value[indexOfParent].ChildReports.findIndex((c) => c.id === payload.id);

          if (indexOfChild === -1) {
            list.value[indexOfParent].ChildReports.unshift(payload);
            unseenReportNotifications.value += 1;
          } else {
            list.value[indexOfParent].ChildReports.splice(indexOfChild, 1, payload);
          }
        }
      } else {
        let index = list.value.findIndex((r) => r.id === payload.id);
        if (index < 0) {
          let newReport = payload;
          newReport.ChildReports = [tempSubReport];
          list.value.unshift(newReport);
          tempSubReport = null;
          unseenReportNotifications.value += 1;
        } else {
          payload.ChildReports = list.value[index].ChildReports;
          const previousExpandStatus = list.value[index]._expanded;
          payload["_expanded"] = previousExpandStatus;
          list.value.splice(index, 1, payload);
        }
      }
    }
    function setLoadedReport({ payload, xlsx }) {
      loadedReport.value = payload;
      loadedReportXlsx.value = xlsx;
    }
    function setReportList(payload) {
      list.value = payload;
    }
    function addReportToList(payload) {
      if (payload.Parent) list.value.unshift(payload);
      else {
        let parentIndex = list.value.findIndex((report) => report.id === payload.ParentId);

        if (list.value[parentIndex].ChildReports) {
          list.value[parentIndex].ChildReports.unshift(payload);
        } else {
          list.value[parentIndex].ChildReports = [payload];
        }
      }
    }
    function removeReportFromList(payload) {
      if (payload.ParentId) {
        let indexOfParent = list.value.findIndex((parent) => parent.id === payload.ParentId);

        if (indexOfParent > -1) {
          let indexOfChild = list.value[indexOfParent].ChildReports.findIndex((r) => r.id === payload.id);

          if (indexOfChild > -1) {
            list.value[indexOfParent].ChildReports.splice(indexOfChild, 1);
          }
        }
      } else {
        list.value = list.value.filter((report) => report.id !== payload.id);
      }
    }
    function setPdfTemplateVisibility(value) {
      showPdfTemplate.value = value;
    }
    function setListDashboards(listDashboards) {
      listDashboards.value = listDashboards;
    }

    return {
      //States
      list,
      loadedReport,
      loadedReportXlsx,
      showPdfTemplate,
      unseenReportNotifications,
      listDashboards,
      //Getters
      getListDashboards,
      getReportsList,
      //Actions
      createReport,
      updateReport,
      listReports,
      deleteReport,
      getReport,
      listDashboard,
      updateReportingV2Access,
      //Mutations
      setUpdateReport,
      setLoadedReport,
      setReportList,
      addReportToList,
      removeReportFromList,
      setPdfTemplateVisibility,
      setListDashboards,
    };
  },
  {
    persist: true,
  }
);
