import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Spin } from "view-design";
import _ from "lodash";
import { deleteRemnantsOfProp } from "@/common/support";
import { i18n } from "@/plugins/language";
import { CUSTOM_LINKS } from "@connectpath/common-frontend";
import {
  onUpdateSettingsSubscription,
  onNewDispositionSubscription,
  getSettingsQuery,
  updateSettingsMutation,
  createDispositionMutation,
  updateDispositionMutation,
  createLevelListMutation,
  createScriptMutation,
  createPublicAPIBearerTokenMutation,
  deletePublicAPIBearerTokenMutation,
  listPublicAPIBearerTokenQuery,
  updateLevelListMutation,
  updateScriptMutation,
  listDispositionsQuery,
  deleteDispositionMutation,
  createCustomCRMMutation,
  getCustomCRMQuery,
  updateCustomCRMMutation,
  deleteCustomCRMMutation,
  createCustomUrlMutation,
  updateCustomUrlMutation,
  getCustomsLinksQuery,
  deleteCustomUrlMutation,
} from "@/common/services/graphql.service";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { useChannelStore } from "./channel";
import { useIndexStore } from "./index";
import { useUiStore } from "./ui";

if (!window.localStorage.getItem("language")) {
  window.localStorage.setItem("language", i18n.locale === "en-US" ? "en" : "es");
}

export const useSettingsStore = defineStore(
  "settings",
  () => {
    const uiStore = useUiStore();
    const indexStore = useIndexStore();
    const channelStore = useChannelStore();
    const isUpdatingConfig = ref(false);
    const configuration = ref({
      ContactLens: {
        isEnabled: null,
        isAllowed: null,
      },
      Poly: {
        isEnabled: false,
        isAllowed: null,
      },
      Theme: null,
      MultiLevelSeries: "INACTIVE",
      PresentedNotAnswered: {
        Label: null,
        Status: null,
        Duration: null,
      },
      CallDispositions: {
        isEnabled: false,
        allowCustomInput: false,
        isMandatory: false,
        isMultiLevelMandatory: false,
      },
      CustomCRM: {
        isAllowed: false,
        isEnabled: false,
        id: "",
      },
      OutboundMessages: {
        isEnabled: false,
        ContactFlow: "",
      },
      ThirdPartyIntegrations: null,
      DefaultOutboundChannels: {
        EmailChannelId: "",
        SmsChannelId: "",
      },
      IdleActive: false,
      IdleStatus: "",
      IdleTime: 1,
      LegacyWebhookChannelBehavior: false,
      DefaultTaskTemplateId: null,
      QuickSightReadOnlyUser: "",
      QuickSightPricing: false,
      ContactAttributesFilter: [],
    });
    const callDispositions = ref([]);
    const darkMode = ref(false);
    const transitionName = ref("");
    const availableCallDispositions = ref([]);
    const language = ref(window.localStorage.getItem("language"));
    const availableLanguages = ref([
      { iso: "en", name: "English" },
      { iso: "es", name: "Spanish" },
      { iso: "pt", name: "Portuguese" },
      { iso: "fr", name: "French" },
      { iso: "af", name: "Afrikaans" },
    ]);
    const ccpUrlDeepLink = ref(null);
    const customLinks = ref([]);
    const connectParameters = ref({});
    const emailAliases = ref([]);

    const dialogVisibility = ref(false);
    const widgetName = ref("");
    const currentColumns = ref([]);
    const agentPerformance = ref([
      {
        title: "Name",
        visibility: true,
        key: "Name",
        fixed: true,
      },
      {
        title: "Status",
        visibility: true,
        key: "StatusName",
      },
      {
        title: "Duration",
        visibility: true,
        key: "StatusStartTimestamp",
      },
      {
        title: "Handled In",
        visibility: true,
        key: "HandledIn",
      },
      {
        title: "Handled Out",
        visibility: true,
        key: "HandledOut",
      },
      {
        title: "Callbacks",
        visibility: true,
        key: "HandledCallbacks",
      },
      {
        title: "SMS",
        visibility: false,
        key: "HandledSms",
      },
      {
        title: "Email",
        visibility: false,
        key: "HandledEmail",
      },
      {
        title: "Chat",
        visibility: false,
        key: "HandledChat",
      },
      {
        title: "AHT",
        visibility: true,
        key: "AHT",
      },
      {
        title: "Holds",
        visibility: true,
        key: "Holds",
      },
      {
        title: "Hold Duration",
        visibility: true,
        key: "HoldDuration",
      },
      {
        title: "Longest Hold",
        visibility: true,
        key: "LongestHold",
      },
      {
        title: "Missed",
        visibility: true,
        key: "Missed",
      },
      {
        title: "Avg ACW",
        visibility: true,
        key: "AvgACW",
      },
    ]);
    const queuePerformance = ref([
      {
        title: "Name",
        visibility: true,
        key: "Name",
        fixed: true,
      },
      {
        title: "Open",
        visibility: true,
        key: "Open",
      },
      {
        title: "Total Queued",
        visibility: true,
        key: "ContactsQueued",
      },
      {
        title: "Handled In",
        visibility: true,
        key: "HandledIn",
      },
      {
        title: "Handled Out",
        visibility: true,
        key: "HandledOut",
      },
      {
        title: "Callbacks",
        visibility: true,
        key: "HandledCallbacks",
      },
      {
        title: "SMS",
        visibility: false,
        key: "HandledSms",
      },
      {
        title: "Email",
        visibility: false,
        key: "HandledEmail",
      },
      {
        title: "Chat",
        visibility: false,
        key: "HandledChat",
      },
      {
        title: "AHT",
        visibility: true,
        key: "AHT",
      },
      {
        title: "Missed",
        visibility: true,
        key: "Missed",
      },
      {
        title: "Abandoned",
        visibility: true,
        key: "Abandoned",
      },
      {
        title: "Avg ACW",
        visibility: true,
        key: "AvgACW",
      },
      {
        title: "Holds",
        visibility: true,
        key: "Holds",
      },
      {
        title: "Hold Duration",
        visibility: true,
        key: "HoldDuration",
      },
      {
        title: "Longest Hold",
        visibility: true,
        key: "LongestHold",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 10",
        visibility: false,
        key: "ServiceLevel_10",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 15",
        visibility: false,
        key: "ServiceLevel_15",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 20",
        visibility: false,
        key: "ServiceLevel_20",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 30",
        visibility: false,
        key: "ServiceLevel_30",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 60",
        visibility: false,
        key: "ServiceLevel_60",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 90",
        visibility: false,
        key: "ServiceLevel_90",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 120",
        visibility: false,
        key: "ServiceLevel_120",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 180",
        visibility: false,
        key: "ServiceLevel_180",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 240",
        visibility: false,
        key: "ServiceLevel_240",
      },
      {
        title: i18n.t("dashboard.serviceLevel") + " 300",
        visibility: false,
        key: "ServiceLevel_300",
      },
      {
        title: i18n.t("dashboard.agentsaftercontactwork"),
        visibility: false,
        key: "AgentsAfterContactWork",
      },
      {
        title: i18n.t("dashboard.agentsavailable"),
        visibility: false,
        key: "AgentsAvailable",
      },
      {
        title: i18n.t("dashboard.agentserror"),
        visibility: false,
        key: "AgentsError",
      },
      {
        title: i18n.t("dashboard.agentsnonproductive"),
        visibility: false,
        key: "AgentsNonProductive",
      },
      {
        title: i18n.t("dashboard.agentsoncall"),
        visibility: false,
        key: "AgentsOnCall",
      },
      {
        title: i18n.t("dashboard.agentsoncontact"),
        visibility: false,
        key: "AgentsOnContact",
      },
      {
        title: i18n.t("dashboard.agentsonline"),
        visibility: false,
        key: "AgentsOnline",
      },
      {
        title: i18n.t("dashboard.agentsstaffed"),
        visibility: false,
        key: "AgentsStaffed",
      },
      {
        title: i18n.t("dashboard.contactsinqueue"),
        visibility: false,
        key: "ContactsInQueue",
      },
      {
        title: i18n.t("dashboard.contactsscheduled"),
        visibility: false,
        key: "ContactsScheduled",
      },
    ]);
    const dnisDetailColumns = ref([
      {
        title: "DNIS",
        key: "SystemEndpoint",
        visibility: true,
        firstColumn: true,
      },
      {
        title: "Contacts Handled",
        key: "ContactsHandled",
        visibility: true,
      },
      {
        title: "Missed",
        key: "Missed",
        visibility: true,
      },
      {
        title: "Avg. Talk Time",
        key: "AHT",
        visibility: true,
      },
      {
        title: "Talk Time",
        key: "TalkTime",
        visibility: true,
      },
      {
        title: "Avg. ACW",
        key: "AvgACW",
        visibility: true,
      },
      {
        title: "Holds",
        key: "Holds",
        visibility: true,
      },
      {
        title: "Hold Duration",
        key: "HoldDuration",
        visibility: true,
      },
      {
        title: "Longest Hold",
        key: "LongestHold",
        visibility: true,
      },
    ]);
    const activityColumns = ref([
      {
        title: "Contact",
        key: "CustomerEndpoint",
        visibility: true,
      },
      {
        title: "Agent",
        key: "Username",
        visibility: true,
      },
      {
        title: "Date/Time",
        key: "InitiationTimestamp",
        visibility: true,
      },

      {
        title: "Direction",
        key: "InitiationMethod",
        visibility: true,
      },
      {
        title: "Queue",
        key: "Queue",
        visibility: true,
      },
      {
        title: "Type",
        key: "Channel",
        visibility: true,
      },
      {
        title: "Disposition",
        key: "Disposition",
        visibility: true,
      },
      {
        title: "Durations",
        key: "Duration",
        visibility: true,
      },
    ]);
    const queuePerformanceDetailColumns = ref([
      {
        title: "Queue",
        key: "Queue",
        visibility: true,
        firstColumn: true,
      },
      {
        title: "Contacts Handled",
        key: "ContactsHandled",
        visibility: true,
      },
      {
        title: "Abandoned",
        key: "Abandoned",
        visibility: true,
      },
      {
        title: "Missed",
        key: "Missed",
        visibility: true,
      },
      {
        title: "Avg. Talk Time",
        key: "AHT",
        visibility: true,
      },
      {
        title: "Talk Time",
        key: "TalkTime",
        visibility: true,
      },
      {
        title: "Avg. ACW",
        key: "AvgACW",
        visibility: true,
      },
      {
        title: "Holds",
        key: "Holds",
        visibility: true,
      },
      {
        title: "Hold Duration",
        key: "HoldDuration",
        visibility: true,
      },
      {
        title: "Longest Hold",
        key: "LongestHold",
        visibility: true,
      },
    ]);

    const hourPerformanceDetailColumns = ref([
      {
        title: "Hour",
        key: "Hour",
        visibility: true,
        firstColumn: true,
      },
      {
        title: "Contacts Handled",
        key: "ContactsHandled",
        visibility: true,
      },
      {
        title: "Abandoned",
        key: "Abandoned",
        visibility: true,
      },
      {
        title: "Missed",
        key: "Missed",
        visibility: true,
      },
      {
        title: "Avg. Talk Time",
        key: "AHT",
        visibility: true,
      },
      {
        title: "Talk Time",
        key: "TalkTime",
        visibility: true,
      },
      {
        title: "Avg. ACW",
        key: "AvgACW",
        visibility: true,
      },
      {
        title: "Holds",
        key: "Holds",
        visibility: true,
      },
      {
        title: "Hold Duration",
        key: "HoldDuration",
        visibility: true,
      },
      {
        title: "Longest Hold",
        key: "LongestHold",
        visibility: true,
      },
    ]);

    const dispositionDetailColumns = ref([
      {
        title: "Disposition",
        key: "Disposition",
        visibility: true,
        firstColumn: true,
      },
      {
        title: "Contacts Handled",
        key: "ContactsHandled",
        visibility: true,
      },
      {
        title: "Abandoned",
        key: "Abandoned",
        visibility: true,
      },
      {
        title: "Missed",
        key: "Missed",
        visibility: true,
      },
      {
        title: "Avg. Talk Time",
        key: "AHT",
        visibility: true,
      },
      {
        title: "Talk Time",
        key: "TalkTime",
        visibility: true,
      },
      {
        title: "Avg. ACW",
        key: "AvgACW",
        visibility: true,
      },
      {
        title: "Holds",
        key: "Holds",
        visibility: true,
      },
      {
        title: "Hold Duration",
        key: "HoldDuration",
        visibility: true,
      },
      {
        title: "Longest Hold",
        key: "LongestHold",
        visibility: true,
      },
    ]);

    const agentPerformanceDetailColumns = ref([
      {
        title: "Agent",
        key: "Agent",
        visibility: true,
        firstColumn: true,
      },
      {
        title: "Contacts Handled",
        key: "ContactsHandled",
        visibility: true,
      },
      {
        title: "Missed",
        key: "Missed",
        visibility: true,
      },
      {
        title: "Avg. Talk Time",
        key: "AHT",
        visibility: true,
      },
      {
        title: "Talk Time",
        key: "TalkTime",
        visibility: true,
      },
      {
        title: "Avg. ACW",
        key: "AvgACW",
        visibility: true,
      },
      {
        title: "Holds",
        key: "Holds",
        visibility: true,
      },
      {
        title: "Hold Duration",
        key: "HoldDuration",
        visibility: true,
      },
      {
        title: "Longest Hold",
        key: "LongestHold",
        visibility: true,
      },
    ]);

    const rebalancingAgentPerformance = ref([
      {
        title: "Name",
        visibility: true,
        key: "Name",
        fixed: true,
      },
      {
        title: "Status",
        visibility: true,
        key: "StatusName",
      },
      {
        title: "Duration",
        visibility: true,
        key: "StatusStartTimestamp",
      },
      {
        title: "Handled In",
        visibility: true,
        key: "HandledIn",
      },
      {
        title: "Handled Out",
        visibility: true,
        key: "HandledOut",
      },
      {
        title: "Callbacks",
        visibility: true,
        key: "HandledCallbacks",
      },
      {
        title: "AHT",
        visibility: true,
        key: "AHT",
      },
      {
        title: "Missed",
        visibility: true,
        key: "Missed",
      },
      {
        title: "Holds",
        visibility: true,
        key: "Holds",
      },
      {
        title: "Hold Duration",
        visibility: true,
        key: "HoldDuration",
      },
      {
        title: "Longest Hold",
        visibility: true,
        key: "LongestHold",
      },
      {
        title: "Avg ACW",
        visibility: true,
        key: "AvgACW",
      },
    ]);
    const activity = ref(null);
    const usageRecords = ref([]);

    const isDarkMode = computed(() => darkMode.value);
    const isCallDispositionEnabled = computed(() => "configuration.value.CallDispositions.isEnabled");

    const isAvailableCallDispositions = computed(() => !!availableCallDispositions.value.length);

    const availableCallDispositionsGetter = computed(() => availableCallDispositions.value);

    const isMultilevelDispositionEnabled = computed(() => configuration.value.MultiLevelSeries === "ACTIVE");
    const isCustomInputDispositionEnabled = computed(() => configuration.value.CallDispositions.allowCustomInput);

    const currentUserLanguage = computed(() => language.value || window.localStorage.getItem("language"));
    const isUpdatingConfigGetter = computed(() => isUpdatingConfig.value);
    const configurations = computed(() => configuration.value);
    const callDispositionAllowCustomInput = computed(() => configuration.value.CallDispositions.allowCustomInput);
    const isOutboundMessagesEnabled = computed(() => configuration.value.OutboundMessages.isEnabled);

    const getCallDispositions = computed(() => callDispositions.value);
    const isContactLensEnabled = computed(() => !!configuration.value.ContactLens);

    const getCcpUrlDeepLink = computed(() => ccpUrlDeepLink.value);
    const getDefaultOutboundChannels = computed(() => configuration.value?.DefaultOutboundChannels || {});

    const getDefaultOutboundEmailId = computed(() => configuration.value?.DefaultOutboundChannels?.EmailChannelId);

    const getDefaultOutboundSmsId = computed(() => configuration.value?.DefaultOutboundChannels?.SmsChannelId);

    const isReportingAllowed = computed(() => {
      if (configuration.value?.Reporting?.isAllowed) {
        return configuration.value.Reporting.isAllowed;
      }
      return false;
    });

    const getIdleActive = computed(() => configuration.value.IdleActive);

    const getIdleStatus = computed(() => configuration.value.IdleStatus);

    const getIdleTime = computed(() => configuration.value.IdleTime);

    const getCustomLinks = computed(() => customLinks.value);

    const getLegacyWebhookChannelBehavior = computed(() => configuration.value.LegacyWebhookChannelBehavior);

    const getConnectParmeters = computed(() => connectParameters.value);

    const getDefaulTaskTemplateId = computed(() => configuration.value?.DefaultTaskTemplateId || "");

    const getQuickSightReadOnlyUserID = computed(() => configuration.value.QuickSightReadOnlyUser);

    const getQuickSightPricing = computed(() => configuration.value.QuickSightPricing);

    const getUserCallDispositions = computed(() => configuration.value.CallDispositions);

    const getEmailAliases = computed(() => emailAliases.value ?? []);

    const getDefaultOutboundEmailChannel = computed(() => {
      const emailWebhooks = channelStore.getEmailWebhooks;
      if (!emailWebhooks.length) return null;

      if (getDefaultOutboundEmailId.value) {
        const emailWebhook = emailWebhooks.find((webhook) => webhook.id === getDefaultOutboundEmailId.value);
        return emailWebhook;
      }

      if (emailWebhooks.length === 1) {
        return emailWebhooks[0];
      }

      return null;
    });

    const getContactAttributesFilter = computed(() => configuration.value.ContactAttributesFilter || []);

    const getUsageRecords = computed(() => usageRecords.value);
    const getDefaultContactFlow = computed(() => {
      return configuration.value.DefaultContactFlow;
    });

    function updateAvailableCallDispositions(dispositions) {
      setAvailableCallDispositions(dispositions);
    }

    function setAvailableCallDispositionsByQueue({ queueArn }) {
      const callDispositions = getCallDispositions.value;
      const availableCallDispositions = callDispositions.filter((disposition) => {
        const { Queues } = disposition;
        const queues = Queues.map((queue) => queue.Arn);
        return queues.includes(queueArn);
      });
      setAvailableCallDispositions(availableCallDispositions);
    }

    function settingsSubs(payload) {
      const observable = onUpdateSettingsSubscription({
        InstanceId: payload,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateSettings;
          if (newData) {
            setSettings(newData);
          }
          return Promise.resolve(newData);
        },
      };

      indexStore.addSubscriptionToList({ id: "settingsSubs", observable, handler });
    }

    function dispositionSubs(payload) {
      const observable = onNewDispositionSubscription({
        InstanceId: payload,
      });

      const handler = {
        next: (eventData) => {
          const newDisposition = eventData.value.data.onNewDisposition;
          if (newDisposition) {
            Spin.show();
            let currentDispositions = callDispositions.value;
            if (!currentDispositions.find((x) => x.id === newDisposition.id)) {
              currentDispositions.push({
                Name: newDisposition.Name,
                Queues: [...newDisposition.Queues],
                id: newDisposition.id,
              });
              setCallDispositions(currentDispositions);
            }
          }
          Spin.hide();
          return Promise.resolve(eventData);
        },
      };

      indexStore.addSubscriptionToList({ id: "dispositionSubs", observable, handler });
    }

    async function getConfigs(payload) {
      const res = await getSettingsQuery({
        InstanceId: payload,
      });

      setSettings(res?.getSettings);
      return res;
    }

    async function updateConfigs(payload) {
      isUpdatingConfig.value = true;
      deleteRemnantsOfProp(payload, ["__typename"]);
      try {
        const res = await updateSettingsMutation(payload);
        setSettings(res?.updateSettings);
        return res;
      } finally {
        isUpdatingConfig.value = false;
      }
    }

    async function addNewCallDispositions(payload) {
      isUpdatingConfig.value = true;
      try {
        const res = await createDispositionMutation(payload);
        return res;
      } finally {
        isUpdatingConfig.value = false;
      }
    }

    async function updateSelectedCallDisposition(payload) {
      isUpdatingConfig.value = true;
      try {
        const res = await updateDispositionMutation(payload);
        return res;
      } finally {
        isUpdatingConfig.value = false;
      }
    }
    async function createNewSeries(payload) {
      isUpdatingConfig.value = true;
      try {
        const res = await createLevelListMutation(payload);
        return res;
      } finally {
        isUpdatingConfig.value = false;
      }
    }
    async function createNewScript(payload) {
      isUpdatingConfig.value = true;
      try {
        const res = await createScriptMutation(payload);
        return res;
      } finally {
        isUpdatingConfig.value = false;
      }
    }
    async function createNewToken(payload) {
      isUpdatingConfig.value = true;
      isUpdatingConfig.value = false;
      try {
        const response = await createPublicAPIBearerTokenMutation(payload);
        isUpdatingConfig.value = false;
        return response;
      } catch (e) {
        isUpdatingConfig.value = false;
        return e;
      }
    }

    async function deleteToken(payload) {
      return await deletePublicAPIBearerTokenMutation({
        id: payload.id,
        InstanceId: payload.InstanceId,
        Username: payload.Username,
      });
    }

    async function getTokenData(payload) {
      return await listPublicAPIBearerTokenQuery({
        InstanceId: payload.InstanceId,
        Username: payload.Username,
      });
    }

    async function updateSeriesData(payload) {
      isUpdatingConfig.value = true;
      try {
        const res = await updateLevelListMutation(payload);
        return res;
      } finally {
        isUpdatingConfig.value = false;
      }
    }

    async function updateScriptData(payload) {
      isUpdatingConfig.value = true;
      try {
        const res = await updateScriptMutation(payload);
        return res;
      } finally {
        isUpdatingConfig.value = false;
      }
    }

    async function fetchCallDipositions(payload) {
      const res = await listDispositionsQuery(payload);
      setCallDispositions(_.get(res, "listDispositions.items", []));
      return res;
    }

    async function deleteCallDisposition(payload) {
      const res = await deleteDispositionMutation(payload);
      return res;
    }

    async function createCustomCRM(payload) {
      const res = await createCustomCRMMutation(payload);
      updateCustomCRMMutations({
        isEnabled: true,
        isAllowed: true,
        id: res?.createCustomCRM.id,
      });
      return res;
    }

    async function getCustomCRM(payload) {
      const res = await getCustomCRMQuery(payload);
      return res;
    }

    async function updateCustomCRM(payload) {
      const res = await updateCustomCRMMutation(payload);
      return res;
    }

    async function deleteCustomCRM(payload) {
      const res = await deleteCustomCRMMutation(payload);
      updateCustomCRMMutations({
        isEnabled: false,
        isAllowed: true,
        id: null,
      });
      return res;
    }

    function addCcpUrlDeepLink(value) {
      updateCcpUrlDeepLink(value);
    }

    async function updateDefaultOutboundChannels({ DefaultOutboundChannels, InstanceId }) {
      try {
        await updateConfigs({
          input: {
            InstanceId,
            DefaultOutboundChannels,
          },
        });
        setDefaultOutboundChannels(DefaultOutboundChannels);
      } catch (error) {
        console.error(error);
      }
    }

    function setInactivitySettings({ IdleActive, IdleStatus, IdleTime }) {
      setIdleActive(IdleActive);
      setIdleStatus(IdleStatus);
      setIdleTime(IdleTime);
    }

    async function saveInactivitySettings({ InstanceId, IdleActive, IdleStatus, IdleTime }) {
      try {
        await updateConfigs({
          input: {
            InstanceId,
            IdleActive,
            IdleStatus,
            IdleTime,
          },
        });
        setInactivitySettings({ IdleActive, IdleStatus, IdleTime });
        uiStore.messageEndpointSuccess({ message: `${i18n.t("settings.inactivitySettingsUpdated")}` });
      } catch (error) {
        uiStore.messageEndpointError({ message: `${i18n.t("settings.inactivitySettingsError")}` });
        console.error(`saveInactivitySettings -> `, error);
      }
    }

    async function createCustomLink({ name, url, type, browserTab, scope, awsAgentId }) {
      try {
        return await createCustomUrlMutation({
          Name: name,
          BaseUrl: url,
          Type: type,
          BrowserTab: browserTab,
          Scope: scope,
          AWSAgentID: awsAgentId,
        });
      } catch (error) {
        console.error(error);
      }
    }

    async function updateCustomLinks({ id, name, url, type, browserTab, scope, awsAgentId }) {
      try {
        return await updateCustomUrlMutation({
          Id: id,
          Name: name,
          BaseUrl: url,
          Type: type,
          BrowserTab: browserTab,
          Scope: scope,
          AWSAgentID: awsAgentId,
        });
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchCustomLinks({ awsAgentId }) {
      try {
        const response = await getCustomsLinksQuery();

        const customLinksFilteredByScope = response?.listCustomUrl.filter(
          (link) =>
            !link.Scope ||
            link.Scope === CUSTOM_LINKS.GLOBAL_SCOPE ||
            (CUSTOM_LINKS.PRIVATE_SCOPE && link.AWSAgentID === awsAgentId)
        );
        return customLinksFilteredByScope.map((item) => {
          return {
            id: item.Id,
            name: item.Name,
            url: item.BaseUrl,
            type: item.Type,
            browserTab: item.BrowserTab,
            scope: item.Scope,
            awsAgentId: item.AWSAgentID,
          };
        });
      } catch (error) {
        console.error(error);
      }
    }

    async function deleteCustomLink({ id }) {
      try {
        return await deleteCustomUrlMutation({
          Id: id,
        });
      } catch (error) {
        console.error(error);
      }
    }

    function appendCustomLinks(payload) {
      updateCustomLinksMutation(payload);
    }

    async function updateDefaultTaskTemplateId({ InstanceId, DefaultTaskTemplateId }) {
      try {
        await updateConfigs({
          input: {
            InstanceId,
            DefaultTaskTemplateId,
          },
        });
        setDefaultTaskTemplateId(DefaultTaskTemplateId);
        uiStore.messageEndpointSuccess({ message: `${i18n.t("settings.defaultTaskTemplateUpdateSuccess")}` });
      } catch (error) {
        console.error(error);
        uiStore.messageEndpointError({ message: `${i18n.t("settings.defaultTaskTemplateUpdateError")}` });
      }
    }

    function resetDefaultTaskTemplateId() {
      setDefaultTaskTemplateId("");
    }

    function buildUsageRecord() {
      let now = getTimeObject().offsetToISOString();

      if (window.myLocation) {
        let coords = window.myLocation.coords;
        now = now + `_${coords.latitude},${coords.longitude}`;
      }

      addUsageRecord(now);
    }

    async function updateDefaultFlowId({ InstanceId, FlowId, FlowName }) {
      try {
        const res = await updateSettingsMutation({
          input: {
            InstanceId,
            DefaultContactFlow: {
              FlowId,
              FlowName,
            },
          },
        });

        setDefaultFlowId({
          FlowId,
          FlowName,
        });
        return res;
      } catch (error) {
        console.error(error);
      }
    }

    function updateAgentPerformance(payload) {
      setAgentPerformance(payload);
    }

    function updateQueuePerformance(payload) {
      setQueuePerformance(payload);
    }

    function updateEmailAliases(payload) {
      setEmailAliases(payload);
    }

    function updateContactAttributesFilter(contactAttributesFilter) {
      setContactAttributesFilter(contactAttributesFilter);
    }

    function updateOutboundMessages(v) {
      configuration.value.OutboundMessages.isEnabled = v;
    }

    function removedDisposition(v) {
      for (let x = 0; x < callDispositions.value.length; x++) {
        if (callDispositions.value.id == v.id) {
          callDispositions.value.splice(x, 1);
          return false;
        }
      }
    }

    function setSettings(v) {
      v.MultiLevelSeries = v.MultiLevelSeries ?? "INACTIVE";
      configuration.value = {
        ...v,
      };

      //TODO: Include this in configuration state object
      emailAliases.value = v.EmailAliases;

      const timeout = setTimeout(() => {
        Spin.hide();
        clearTimeout(timeout);
      }, 1000);
    }

    function setCallDispositions(payload) {
      callDispositions.value = payload;
      const timeout = setTimeout(() => {
        Spin.hide();
        clearTimeout(timeout);
      }, 1000);
    }
    function setDarkMode(value) {
      darkMode.value = value;
    }

    function setTransitionName(value) {
      transitionName.value = value;
    }

    function initSettings() {
      isUpdatingConfig.value = false;
      configuration.value = {
        Theme: null,
        ContactLens: {
          isEnabled: null,
          isAllowed: null,
        },
        PresentedNotAnswered: {
          Label: null,
          Status: null,
          Duration: null,
        },
        CallDispositions: {
          isEnabled: false,
          allowCustomInput: false,
        },
        CustomCRM: {
          isAllowed: false,
          isEnabled: false,
        },
        OutboundMessages: {
          isEnabled: false,
          ContactFlow: "",
        },
        ThirdPartyIntegrations: null,
        DefaultOutboundChannels: {
          EmailChannelId: "",
          SmsChannelId: "",
        },
        IdleActive: false,
        IdleStatus: "",
        IdleTime: 1,
        LegacyWebhookChannelBehavior: false,
        DefaultTaskTemplateId: null,
        QuickSightPricing: false,
        QuickSightReadOnlyUser: "",
      };
      callDispositions.value = [];
      darkMode.value = false;
      transitionName.value = "";
      language.value = window.localStorage.getItem("language");
      emailAliases.value = emailAliases.value || [];
    }

    function updateState(value) {
      isUpdatingConfig.value = value.isUpdatingConfig;
      configuration.value = value.configuration;
      callDispositions.value = value.callDispositions;
      darkMode.value = value.darkMode;
      transitionName.value = value.transitionName;
      language.value = value.language;
    }

    function updateCustomCRMMutations(value) {
      configuration.value.CustomCRM = value;
    }

    function setAvailableCallDispositions(value) {
      availableCallDispositions.value = value;
    }

    function setLanguage(value) {
      language.value = value;
    }

    function updateCcpUrlDeepLink(payload) {
      ccpUrlDeepLink.value = payload;
    }

    function setDefaultOutboundChannels(value) {
      configuration.value.DefaultOutboundChannels = value;
    }

    function setIdleActive(IdleActive) {
      configuration.value.IdleActive = IdleActive;
    }

    function setIdleStatus(IdleStatus) {
      configuration.value.IdleStatus = IdleStatus;
    }

    function setIdleTime(IdleTime) {
      configuration.value.IdleTime = IdleTime;
    }
    function updateCustomLinksMutation(payload) {
      customLinks.value = payload;
    }
    function setConnectParameters(payload) {
      connectParameters.value = payload;
    }
    function setDefaultTaskTemplateId(DefaultTaskTemplateId) {
      configuration.value.DefaultTaskTemplateId = DefaultTaskTemplateId;
    }

    function setQuickSightPricing(payload) {
      configuration.value.QuickSightPricing = payload;
    }

    function setEmailAliases(payload) {
      emailAliases.value = payload;
    }

    function setContactAttributesFilter(payload) {
      configuration.value.ContactAttributesFilter = payload;
    }

    function setAgentPerformance(value) {
      agentPerformance.value = value;
    }
    function setQueuePerformance(value) {
      queuePerformance.value = value;
    }
    function updateAgentState(value) {
      agentPerformance.value = value.agentPerformance;
      activity.value = value.activity;
      activityColumns.value = value.activityColumns;
    }
    function setDialogVisibility(value) {
      dialogVisibility.value = value;
    }
    function setCurrentColumns(value) {
      currentColumns.value = value;
    }
    function setActivity(value) {
      activity.value = value;
    }
    function setDnisDetailColumns(value) {
      dnisDetailColumns.value = value;
    }
    function setDispositionDetailColumns(value) {
      dispositionDetailColumns.value = value;
    }
    function setHourDetailColumns(value) {
      hourPerformanceDetailColumns.value = value;
    }
    function setActivityColumns(value) {
      activityColumns.value = value;
    }
    function setQueuePerformanceDetailColumns(value) {
      queuePerformanceDetailColumns.value = value;
    }
    function setAgentPerformanceDetailColumns(value) {
      agentPerformanceDetailColumns.value = value;
    }
    function updateColumnConfig(payload) {
      payload.forEach((config) => {
        config.value.Type = JSON.parse(config.Data);
      });
    }

    function addUsageRecord(payload) {
      usageRecords.value.push(payload);
    }
    function initUsage() {
      usageRecords.value = [];
    }

    function updateUsageState(value) {
      usageRecords.value = value.usageRecords;
    }

    function setDefaultFlowId(payload) {
      configuration.value.DefaultContactFlow = payload;
    }
    return {
      //States
      isUpdatingConfig,
      configuration,
      callDispositions,
      darkMode,
      transitionName,
      availableCallDispositions,
      language,
      availableLanguages,
      ccpUrlDeepLink,
      customLinks,
      connectParameters,
      emailAliases,
      dialogVisibility,
      widgetName,
      currentColumns,
      agentPerformance,
      queuePerformance,
      dnisDetailColumns,
      activityColumns,
      queuePerformanceDetailColumns,
      hourPerformanceDetailColumns,
      dispositionDetailColumns,
      agentPerformanceDetailColumns,
      rebalancingAgentPerformance,
      usageRecords,

      //Getters
      isDarkMode,
      isCallDispositionEnabled,
      isAvailableCallDispositions,
      availableCallDispositionsGetter,
      isMultilevelDispositionEnabled,
      isCustomInputDispositionEnabled,
      currentUserLanguage,
      isUpdatingConfigGetter,
      configurations,
      callDispositionAllowCustomInput,
      isOutboundMessagesEnabled,
      getCallDispositions,
      isContactLensEnabled,
      getCcpUrlDeepLink,
      getDefaultOutboundChannels,
      getDefaultOutboundEmailId,
      getDefaultOutboundSmsId,
      isReportingAllowed,
      getIdleActive,
      getIdleStatus,
      getIdleTime,
      getCustomLinks,
      getLegacyWebhookChannelBehavior,
      getConnectParmeters,
      getDefaulTaskTemplateId,
      getQuickSightReadOnlyUserID,
      getQuickSightPricing,
      getUserCallDispositions,
      getEmailAliases,
      getDefaultOutboundEmailChannel,
      getContactAttributesFilter,
      getUsageRecords,
      getDefaultContactFlow,
      //Actions
      updateAvailableCallDispositions,
      setAvailableCallDispositionsByQueue,
      settingsSubs,
      dispositionSubs,
      getConfigs,
      updateConfigs,
      addNewCallDispositions,
      updateSelectedCallDisposition,
      createNewSeries,
      createNewScript,
      createNewToken,
      deleteToken,
      getTokenData,
      updateSeriesData,
      updateScriptData,
      fetchCallDipositions,
      deleteCallDisposition,
      createCustomCRM,
      getCustomCRM,
      updateCustomCRM,
      deleteCustomCRM,
      addCcpUrlDeepLink,
      updateDefaultOutboundChannels,
      setInactivitySettings,
      saveInactivitySettings,
      createCustomLink,
      updateCustomLinks,
      fetchCustomLinks,
      deleteCustomLink,
      appendCustomLinks,
      updateDefaultTaskTemplateId,
      resetDefaultTaskTemplateId,
      updateEmailAliases,
      updateContactAttributesFilter,
      updateAgentPerformance,
      updateQueuePerformance,
      buildUsageRecord,
      updateDefaultFlowId,
      //Mutations
      updateOutboundMessages,
      removedDisposition,
      setSettings,
      setCallDispositions,
      setDarkMode,
      setTransitionName,
      initSettings,
      updateState,
      updateCustomCRMMutation,
      setAvailableCallDispositions,
      setLanguage,
      updateCcpUrlDeepLink,
      setDefaultOutboundChannels,
      setIdleActive,
      setIdleStatus,
      setIdleTime,
      updateCustomLinksMutation,
      setConnectParameters,
      setDefaultTaskTemplateId,
      setQuickSightPricing,
      setEmailAliases,
      setContactAttributesFilter,
      setAgentPerformance,
      setQueuePerformance,
      updateAgentState,
      setDialogVisibility,
      setCurrentColumns,
      setActivity,
      setDnisDetailColumns,
      setDispositionDetailColumns,
      setHourDetailColumns,
      setActivityColumns,
      setQueuePerformanceDetailColumns,
      setAgentPerformanceDetailColumns,
      updateColumnConfig,
      addUsageRecord,
      initUsage,
      updateUsageState,
      setDefaultFlowId,
    };
  },
  {
    persist: true,
  }
);
