import { createPinia, getActivePinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

let pinia;
if (getActivePinia()) {
  pinia = getActivePinia();
} else {
  pinia = createPinia();
}
pinia.use(piniaPluginPersistedstate);
export default pinia;
