import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { isEmpty } from "lodash";
import { sortBy } from "lodash";
import { getStringsBetweenTwoCharacters, replaceAll } from "@/utils";
import { TEMPLATE_URLS } from "@connectpath/common/src/integrations/ThirdPartyIntegrations/templateUrls";
import {
  createIntegrationMutation,
  deleteThirdPartyIntegrationMutation,
  listThirdPartyIntegrationQuery,
  updateThirdPartyIntegrationMutation,
} from "@/common/services/graphql.service";

export const useIframeIntegrationsStore = defineStore(
  "iframeIntegrations",
  () => {
    const integrations = ref([]);
    const integrationsTabsUrlFormated = ref([]);
    const maxIntegrations = ref(20);
    const templates = ref(TEMPLATE_URLS);

    const listThirdPartyIntegration = computed(() => sortBy(integrations.value, "TabName", "asc"));
    const getThirdPartyIntegrationsTabsUrlFormated = computed(() => integrationsTabsUrlFormated.value || null);
    const getTemplates = computed(() => templates.value);
    const getThirdPartyIntegrationsToOpenInBrowserTab = computed(() =>
      integrationsTabsUrlFormated.value.filter((integration) => integration.BrowserTab)
    );
    const getThirdPartyIntegrationsToOpenInTheApp = computed(() =>
      integrationsTabsUrlFormated.value.filter((integration) => !integration.BrowserTab)
    );

    async function createIntegration({ name, tabName, type, baseUrl, queues, browserTab }) {
      try {
        await createIntegrationMutation({
          Name: name,
          TabName: tabName,
          Type: type,
          BaseUrl: baseUrl,
          Queues: queues,
          BrowserTab: browserTab,
        });
      } catch (error) {
        console.error(`Error store iframeIntegrations createIntegration`);
      }
    }
    async function deleteIntegration({ id }) {
      try {
        await deleteThirdPartyIntegrationMutation({
          Id: id,
        });
      } catch (error) {
        console.error(`Error store iframeIntegrations deleteIntegration`);
      }
    }
    async function getUrlsIntegrationFormated({ customAttributesActiveVoiceContact }) {
      if (!customAttributesActiveVoiceContact || isEmpty(customAttributesActiveVoiceContact)) return;
      if (isEmpty(integrations.value)) await listIntegrations();
      const integrationArray = integrations.value;
      if (isEmpty(integrationArray)) return;
      const formatedUrls = [];
      const urlsWithoutAttributes = [];
      integrationArray.forEach((integration) => {
        const urlsOrAttributes = getStringsBetweenTwoCharacters({ string: integration.BaseUrl, start: "{{", end: "}}" });
        const mapObj = {};
        if (typeof urlsOrAttributes === "string") {
          urlsWithoutAttributes.push({
            TabName: integration.TabName,
            Url: integration.BaseUrl,
            Queues: integration.Queues?.map((queue) => queue.Arn) || null,
            BrowserTab: integration.BrowserTab,
          });
        } else {
          for (const attribute of urlsOrAttributes) {
            const incomingAttribute = customAttributesActiveVoiceContact[attribute]?.value;
            if (!incomingAttribute) continue;
            let isExistAttribute = incomingAttribute === "Timeout" ? "Null" : incomingAttribute;
            mapObj[`{{${attribute}}}`] = isExistAttribute;
          }
          formatedUrls.push({
            TabName: integration.TabName,
            Url: replaceAll({ string: integration.BaseUrl, values: mapObj }),
            Queues: integration.Queues?.map((queue) => queue.Arn) || null,
            BrowserTab: integration.BrowserTab,
          });
        }
      });
      setListThirdPartyIntegrationsTabsUrlFormated([...urlsWithoutAttributes, ...formatedUrls]);
    }
    async function listIntegrations() {
      try {
        const integrations = await listThirdPartyIntegrationQuery();
        setListThirdPartyIntegrations(integrations?.listThirdPartyIntegration || []);
      } catch (error) {
        console.error(`Error store iframeIntegrations listIntegrations`);
      }
    }
    async function updateIntegration({ id, name, tabName, type, baseUrl, queues, browserTab }) {
      try {
        await updateThirdPartyIntegrationMutation({
          Id: id,
          Name: name,
          TabName: tabName,
          Type: type,
          BaseUrl: baseUrl,
          Queues: queues,
          BrowserTab: browserTab,
        });
      } catch (error) {
        console.error(`Error store iframeIntegrations updateIntegration`);
      }
    }
    function cleanListThirdPartyIntegrationsTabsUrlFormated() {
      setListThirdPartyIntegrationsTabsUrlFormated([]);
    }
    function setListThirdPartyIntegrations(payload) {
      integrations.value = payload;
    }
    function setListThirdPartyIntegrationsTabsUrlFormated(state, payload) {
      integrationsTabsUrlFormated.value = payload;
    }

    return {
      //States
      integrations,
      integrationsTabsUrlFormated,
      maxIntegrations,
      templates,
      //Getters
      listThirdPartyIntegration,
      getThirdPartyIntegrationsTabsUrlFormated,
      getTemplates,
      getThirdPartyIntegrationsToOpenInBrowserTab,
      getThirdPartyIntegrationsToOpenInTheApp,
      //Actions
      createIntegration,
      deleteIntegration,
      getUrlsIntegrationFormated,
      listIntegrations,
      updateIntegration,
      cleanListThirdPartyIntegrationsTabsUrlFormated,
      //Mutations
      setListThirdPartyIntegrations,
      setListThirdPartyIntegrationsTabsUrlFormated,
    };
  },
  {
    persist: true,
  }
);
