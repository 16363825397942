import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import { Storage } from "aws-amplify";
import { i18n } from "@/plugins/language";
import {
  syncUsersMutation,
  promoteUserMutation,
  listUsersCompactQuery,
  heartbeatMutation,
  deleteUserMutation,
  createConnectUserMutation,
  updateUserMutation,
  updateUserSecurityProfileMutation,
} from "@/common/services/graphql.service";
import { useCurrentUserStore } from "./current-user";
import { useApiStore } from "./api";
import { useHealthStore } from "./health";
import { useIndexStore } from ".";
import { useTeamStore } from "./team";

export const useUsersStore = defineStore(
  "users",
  () => {
    const currentUserStore = useCurrentUserStore();
    const apiStore = useApiStore();
    const healthStore = useHealthStore();
    const indexStore = useIndexStore();
    const teamStore = useTeamStore();
    const list = ref({ items: [] });
    const usersTableLoader = ref(false);
    const isProcessingUser = ref(false);
    const routingProfiles = ref([]);
    const userRoles = ref([
      {
        name: "Administrator",
        val: "admin",
      },
      {
        name: "Agent",
        val: "agents",
      },
      {
        name: "Guest",
        val: "guests",
      },
      {
        name: "Instance Admin",
        val: "instant-admins",
      },
      {
        name: "Super Admin",
        val: "super-admins",
      },
      {
        name: "Supervisor",
        val: "supervisors",
      },
    ]);

    const userRoleList = computed(() => userRoles.value);
    const getUsersTableLoader = computed(() => usersTableLoader.value);
    const pendingUsers = computed(() => {
      return list.value.items.filter((user) => {
        return !user.Role;
      });
    });
    const activatedUsers = computed(() => list.value.items);
    const connectUsers = computed(() => list.value.items);

    async function syncUsers({ InstanceId }) {
      try {
        setUserList([]);
        await syncUsersMutation({ result: "sync" });

        Vue.prototype.$Notice.success({
          title: i18n.t("users.syncUsers.syncUsers"),
          desc: i18n.t("users.syncUsers.syncUsersStarted"),
        });
      } catch (error) {
        console.error(`Store module users actions syncUser ${error}`);
        Vue.prototype.$Notice.error({
          title: i18n.t("users.syncUsers.syncUsers"),
          desc: i18n.t(error.errorCode),
        });
      } finally {
        await onFetchUsers({
          InstanceId,
        });
      }
    }
    function addOrUpdateUser(payload) {
      let index = list.value.items.findIndex((i) => {
        return i.Id === payload.Id || i.Username === payload.Username;
      });
      if (index === -1) {
        addUser(payload);
      } else {
        updateUser(payload);
      }
    }
    async function onSetUserAvatar(payload) {
      const res = await Storage.get(payload);
      return res;
    }
    async function onSetUserAvatarPublic(payload) {
      const res = await Storage.get(payload, { level: "public" });
      return res;
    }
    async function onSetAdminUser(payload) {
      try {
        let res = await promoteUserMutation(payload);

        return Promise.resolve(res);
      } catch (err) {
        console.error("onSetAdminUser", err);
        return Promise.reject(err);
      }
    }
    async function onFetchUsers({ InstanceId }) {
      try {
        let data = null;
        let items = [];
        let params = { InstanceId };
        do {
          usersTableLoader.value = true;
          data = (await listUsersCompactQuery(params))?.listUsers;

          items = items.concat(data.items);

          if (data.cursor) {
            params.cursor = data.cursor;
          }
        } while (data.cursor);

        items = items.sort((a, b) =>
          a.IdentityInfo.FirstName.toLowerCase().localeCompare(b.IdentityInfo.FirstName.toLowerCase())
        );

        let securityProfiles = currentUserStore.securityProfiles.items;
        let routingProfiles = currentUserStore.routingProfiles.items;

        items = items.map((user) => {
          return {
            ...user,
            SecurityProfileName: securityProfiles.find((p) => p.id === user.SecurityProfileId)?.Name,
            RoutingProfileName: routingProfiles.find((p) => p.Id === user.RoutingProfileId.Id)?.Name,
          };
        });

        setUserList(items);
        return Promise.resolve(data);
      } catch (err) {
        this.setUsersTableLoader(false);
        console.error("onFetchUsers", err);
        return Promise.reject(err);
      }
    }
    async function onUpdateUser() {}
    async function heartbeat(payload) {
      try {
        const startTime = performance.now();

        payload.BackendVersion = "";
        let res = await heartbeatMutation(payload);
        const endTime = performance.now();

        const pingLatency = endTime - startTime;

        healthStore.getHearbeatLatency(pingLatency);
        if (payload.Id === currentUserStore.profile.Id) {
          currentUserStore.setCurrentUser(res?.heartbeat);
        }
        if (payload.Id === apiStore.getApi.getUser.Id) {
          apiStore.setCurrentUser(res?.heartbeat);
        }
        updateUser(res?.heartbeat);
        return Promise.resolve(res);
      } catch (err) {
        healthStore.setHeartbeatLatencyError(true);
        if (indexStore.getShowMessageFailedSubscriptions) indexStore.messageFailedSubscriptions({});
        console.error("heartbeat", err);
        return Promise.reject(err);
      }
    }
    async function onDeleteUsers(payload) {
      try {
        await deleteUserMutation(payload);

        teamStore.removedUserFromTeam({ Id: payload.Id });
        Vue.prototype.$Notice.success({
          title: i18n.t("users.deleteUser.deleteUser"),
          desc: i18n.t("users.deleteUser.deleteUserStarted"),
        });
      } catch (error) {
        console.error(`Store module users actions onDeleteUsers ${error}`);
        Vue.prototype.$Notice.error({
          title: i18n.t("users.deleteUser.deleteUserError"),
          desc: i18n.t(error.errorCode),
        });
      }
    }
    async function onCreateUser(payload) {
      try {
        isCreatingNewUser(true);
        let res = await createConnectUserMutation(payload);

        let securityProfiles = currentUserStore.securityProfiles.items;
        let routingProfiles = currentUserStore.routingProfiles.items;

        let newUser = {
          ...res?.createConnectUser,
          SecurityProfileName: securityProfiles.find((p) => p.id === payload.SecurityProfileId)?.Name,
          RoutingProfileName: routingProfiles.find((p) => p.Id === payload.RoutingProfileId.Id)?.Name,
        };

        delete newUser.SecurityProfile;

        newUser(newUser);

        return Promise.resolve(newUser);
      } catch (err) {
        console.error("onCreateUser", err);
        return Promise.reject(err);
      } finally {
        isCreatingNewUser(false);
      }
    }
    async function onAgentRaiseHelp(payload) {
      try {
        let res = await updateUserMutation(payload);
        return Promise.resolve(res);
      } catch (err) {
        console.error("onAgentRaiseHelp", err);
        return Promise.reject(err);
      }
    }
    async function onUpdateUserSecurityProfile(payload) {
      try {
        let res = await updateUserSecurityProfileMutation(payload);

        return Promise.resolve(res);
      } catch (err) {
        console.error("onUpdateUserSecurityProfile", err);
        return Promise.reject(err);
      }
    }

    function setUserList(payload) {
      usersTableLoader.value = false;
      list.value.items = payload;
    }
    function setUsersTableLoader(payload) {
      usersTableLoader.value = payload;
    }
    function deleteUser(payload) {
      let indx = list.value.items.findIndex((i) => i.Id === payload.Id);
      indx === -1 || list.value.items.splice(indx, 1);
    }
    function newUser(payload) {
      let index = list.value.items.findIndex((i) => {
        return i.Username === payload.Username;
      });

      if (index === -1) {
        list.value.items.push(payload);
      } else {
        let merge = {
          ...payload,
          ...list.value.items[index],
        };
        list.value.items.splice(index, 1, merge);
      }
    }
    function addUser(payload) {
      list.value.items.push(payload);
    }

    function updateUser(updateUsers) {
      if (updateUsers.length === 0 || updateUsers.length === undefined) return;

      const allUsersList = [...list.value.items];

      allUsersList.map((allUser, allUsersIndex) => {
        if (allUser !== undefined) {
          updateUsers.map((updateUser) => {
            if ((allUser.Id !== undefined && allUser.Id === updateUser.Id) || allUser.Username === updateUser.Username) {
              allUsersList[allUsersIndex] = { ...allUsersList[allUsersIndex], ...updateUser };
            }
          });
        }
      });

      list.value.items = allUsersList;
    }

    function isCreatingNewUser(payload) {
      isProcessingUser.value = payload;
    }
    function initUsers() {
      list.value = { items: [] };
      isProcessingUser.value = false;
      userRoles.value = [
        {
          name: "Administrator",
          val: "admin",
        },
        {
          name: "Agent",
          val: "agents",
        },
        {
          name: "Guest",
          val: "guests",
        },
        {
          name: "Instance Admin",
          val: "instant-admins",
        },
        {
          name: "Super Admin",
          val: "super-admins",
        },
        {
          name: "Supervisor",
          val: "supervisors",
        },
      ];
    }
    function updateState(value) {
      list.value = value.list;
      usersTableLoader.value = value.usersTableLoader;
      isProcessingUser.value = value.isProcessingUser;
      userRoles.value = value.userRoles;
    }
    function setRouteList(value) {
      routingProfiles.value = value;
    }
    return {
      //States
      list,
      usersTableLoader,
      isProcessingUser,
      routingProfiles,
      userRoles,
      //Getters
      userRoleList,
      getUsersTableLoader,
      pendingUsers,
      activatedUsers,
      connectUsers,
      //Actions
      syncUsers,
      addOrUpdateUser,
      onSetUserAvatar,
      onSetUserAvatarPublic,
      onSetAdminUser,
      onFetchUsers,
      onUpdateUser,
      heartbeat,
      onDeleteUsers,
      onCreateUser,
      onAgentRaiseHelp,
      onUpdateUserSecurityProfile,
      //Mutations
      setUserList,
      setUsersTableLoader,
      deleteUser,
      newUser,
      addUser,
      updateUser,
      isCreatingNewUser,
      initUsers,
      updateState,
      setRouteList,
    };
  },
  {
    persist: true,
  }
);
