import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import { Message } from "view-design";
import ClassicRingtone from "@/assets/Ringing_Phone_2.mp3";
import availableTones from "@/assets/tones";
import availableTonesB from "@/assets/tones-b";
import { deleteRemnantsOfProp } from "@/common/support";
import { i18n } from "@/plugins/language";
import _ from "lodash";
import { worldwidePhoneNumberWithPlusSignRegex } from "@/utils";
import {
  createSmsFlowMutation,
  updateSmsFlowMutation,
  deleteSmsFlowMutation,
  listSmsFlowsQuery,
  outboundMessagesMutation,
  AcceptSmsConversationMutation,
  onNewSmsMessageSubscription,
  onStatusChangeSmsContactSubscription,
} from "@/common/services/graphql.service";
import { useSettingsStore } from "./settings";
import { useChannelStore } from "./channel";
import { useIndexStore } from ".";
import { useApiStore } from "./api";

const playAudioFile = _.debounce(
  (audio) => {
    const ding = new Audio(audio);
    ding.play();
  },
  500,
  { leading: true, trailing: false }
);

export const usePhoneStore = defineStore(
  "phone",
  () => {
    const settingStore = useSettingsStore();
    const channelStore = useChannelStore();
    const indexStore = useIndexStore();
    const apiStore = useApiStore();

    const subscriptions = ref({
      onNewSmsMessage: [],
      onNewSmsContact: null,
    });

    const loadingOwnedNumbers = ref(false);
    const removingOwnedNumber = ref(false);
    const IncomingRingtone = ref(null);
    const isSendingSms = ref(false);
    const ownedNumbers = ref({
      items: [],
    });
    const isProcessing = ref(false);
    const loadingAvailableNumbers = ref(false);
    const loadingFlows = ref(false);
    const flows = ref({
      items: [],
    });
    const availableNumbers = ref({
      items: [],
    });
    const unreadSMSThreadIds = ref([]);
    const selectedThread = ref(null);
    const numberToDial = ref(null);
    const threads = ref([]);
    const loadingThreads = ref(false);
    const acceptingSMSContact = ref(false);
    const newSMSContactReceived = ref(null);
    const showSMSContact = ref(false);
    const isEndingSMSConvo = ref(false);
    const smsContactInQueue = ref({
      phoneNumber: "",
      timeStamp: "",
    });

    const newSMSContactReceivedGetter = computed(() => newSMSContactReceived.value);
    const showSMSContactgetter = computed(() => showSMSContact.value);
    const isProcess = computed(() => isProcessing.value);
    const isLoadingFlows = computed(() => loadingFlows.value);
    const listFlows = computed(() => flows.value);
    const instanceNumbers = computed(() => {
      return _.get(ownedNumbers.value, "items", []);
    });
    const searchNumber = computed(() => {
      return _.get(availableNumbers.value, "items", []);
    });
    const threadList = computed(() => threads.value);
    const selectedThreadId = computed(() => selectedThread.value);
    const currentThread = computed(() => {
      let threadIndex = _.findIndex(threadList.value, function (v) {
        return v.id == selectedThread.value;
      });
      return threadList.value[threadIndex] || null;
    });
    const isSending = computed(() => isSendingSms.value);
    const isLoadingThreads = computed(() => loadingThreads.value);
    const getUnreadSMSThreadIds = computed(() => unreadSMSThreadIds.value);
    const isAcceptingNewSMSContact = computed(() => acceptingSMSContact.value);
    const isEndingSMSConvoGetter = computed(() => isEndingSMSConvo.value);
    const isSubscribeOnNewSmsMessageWithContactId = (ContactId) => {
      let index = _.findIndex(subscriptions.value.onNewSmsMessage, (e) => e.ContactId === ContactId);

      if (index >= 0) {
        return true;
      }
      return false;
    };

    const getSMSContactInQueue = computed(() => smsContactInQueue.value);

    async function createSmsFlow(payload) {
      try {
        process(true);
        let result = await createSmsFlowMutation(payload);
        onNewFlow(result?.createSmsFlow);
        return Promise.resolve(result?.createSmsFlow);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        process(false);
      }
    }
    async function updateSmsFlow(payload) {
      try {
        process(true);
        let result = await updateSmsFlowMutation(payload);

        onUpdateFlow(result?.updateSmsFlow);
        return Promise.resolve(result?.updateSmsFlow);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        process(false);
      }
    }
    async function deleteSmsFlow(payload) {
      try {
        isProcessing.value = true;

        let result = await deleteSmsFlowMutation({
          id: payload.id,
          InstanceId: payload.InstanceId,
        });
        onDeleteFlow(result?.deleteSmsFlow);
        return Promise.resolve(result?.deleteSmsFlow);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        isProcessing.value = false;
      }
    }
    async function listSmsFlows(payload) {
      try {
        loadingFlows.value = true;
        let result = await listSmsFlowsQuery({
          InstanceId: payload,
        });
        flows.value = result?.listSmsFlows;
        return Promise.resolve(result?.listSmsFlows);
      } catch (err) {
        return Promise.reject(err);
      } finally {
        loadingFlows.value = false;
      }
    }

    async function createMessage(payload) {
      if (!payload.To) {
        Vue.prototype.$Notice.error({
          title: i18n.t("validationMessages.noRecipient"),
        });
        return false;
      }
      if (!payload.To.match(worldwidePhoneNumberWithPlusSignRegex)) {
        Vue.prototype.$Notice.error({
          title: i18n.t("validationMessages.incorrectPhoneNumber"),
        });
        return;
      }

      const isOutboundMessagesEnabled = settingStore.isOutboundMessagesEnabled;
      if (!isOutboundMessagesEnabled) {
        Vue.prototype.$Notice.error({
          title: i18n.t("validationMessages.outboundMessagesDisabled"),
        });
        return false;
      }

      await channelStore.listWebhooks(payload.InstanceId);
      const listSmsTwilioWebhooks = channelStore.getSmsTwilioWebhooks;
      const defaultOutboundSmsId = settingStore.getDefaultOutboundSmsId;
      const smsWebhookSelected = defaultOutboundSmsId
        ? listSmsTwilioWebhooks.find((webhook) => webhook.id === defaultOutboundSmsId)
        : channelStore.getSmsTwilioWebhook;

      if (!smsWebhookSelected || !smsWebhookSelected.ProviderPhoneNumber) {
        Vue.prototype.$Notice.error({
          title: i18n.t("validationMessages.providerPhoneOrTwillioMissing"),
        });
        return;
      }
      smsContactInQueue.value = {
        phoneNumber: smsWebhookSelected.ProviderPhoneNumber,
        timeStamp: new Date(),
      };
      try {
        await outboundMessagesMutation({
          InstanceId: payload.InstanceId,
          ChannelId: smsWebhookSelected.id,
          To: smsWebhookSelected.ProviderPhoneNumber,
          From: payload.To,
        });
      } catch (err) {
        Message.error(i18n.t("validationMessages.errorSendingSMS"));
      }
    }

    async function acceptSmsConversation({ InstanceId, ContactId, Accept, Username }) {
      setAcceptingSMSContact(true);

      try {
        let result = await AcceptSmsConversationMutation({
          InstanceId,
          ContactId,
          Accept,
          Username,
        });

        return result?.acceptSmsConversation;
      } catch (err) {
        return Promise.reject(err);
      } finally {
        setAcceptingSMSContact(false);
      }
    }

    function onNewSmsMessage({ InstanceId, ContactId }) {
      if (isSubscribeOnNewSmsMessageWithContactId.value(ContactId)) {
        return;
      }

      const observable = onNewSmsMessageSubscription({
        ContactId,
        InstanceId,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onNewSmsMessage;

          onNewMessageReceived(newData);
          playNewMessageRingtone();

          if (ContactId !== selectedThread.value) {
            addUnreadSMSThread(newData.ContactId);
          }

          return Promise.resolve(newData);
        },
      };
      indexStore.addSubscriptionToList({ id: "onNewSmsMessage:" + ContactId, observable, handler });
    }

    function onStatusChangeSmsContact({ InstanceId, ContactId }) {
      const observable = onStatusChangeSmsContactSubscription({
        InstanceId,
        id: ContactId,
      });
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onStatusChangeSmsContact;
          let Messages = [];

          let indx = threads.value.findIndex((thread) => thread.id === newData.id);
          if (indx >= 0) Messages = threads.value[indx].Messages;

          deleteRemnantsOfProp(newData, ["__typename"]);

          endConvo({ id: ContactId });
          endOnNewSmsMessageSubscription({ ContactId });

          if (indx >= 0) {
            threads.value[indx] = newData;
            threads.value[indx].Messages = Messages;
          }

          return Promise.resolve(newData);
        },
      };

      indexStore.addSubscriptionToList({ id: "onStatusChangeSmsContact", observable, handler });
    }

    function playIncomingSmsRingtone() {
      stopIncomingSmsRingtone();

      const ringToneName = _.get(apiStore, "getUser.Preferences.AgentCallTone") || "default";
      const ringTone = availableTones[ringToneName] || ClassicRingtone;
      const ding = new Audio(ringTone);
      ding.play();
      ding.loop = true;
      IncomingRingtone.value = ding;
    }

    function stopIncomingSmsRingtone() {
      const ding = IncomingRingtone.value;
      if (ding) {
        ding.pause();
        IncomingRingtone.value = null;
      }
    }

    function playNewMessageRingtone() {
      const ringToneName = _.get(apiStore, "getUser.Preferences.AgentMessageTone") || "default";

      const ringTone = availableTonesB[ringToneName] || ClassicRingtone;

      playAudioFile(ringTone);
    }

    function setPhoneSelectedThread(payload) {
      setSelectedThread(payload);
    }

    function setNumberToDial(payload) {
      numberToDial.value = payload;
    }
    function setSubscription({ name, subscription }) {
      subscriptions.value[name] = subscription;
    }

    function endSubscription({ name }) {
      if (subscriptions.value[name]) {
        subscriptions.value[name].unsubscribe();
      }
      subscriptions.value[name] = null;
    }

    function addOnNewSmsMessageSubscription({ ContactId, subscription }) {
      let onNewSmsMessage = {
        ContactId,
        subscription,
      };

      let index = _.findIndex(subscriptions.value.onNewSmsMessage, (e) => e.ContactId === ContactId);

      if (index < 0) {
        subscriptions.value.onNewSmsMessage.push(onNewSmsMessage);
      } else if (subscription) {
        subscription.unsubscribe();
      }
    }

    function endOnNewSmsMessageSubscription({ ContactId }) {
      let index = _.findIndex(subscriptions.value.onNewSmsMessage, (e) => e.ContactId === ContactId);

      if (index >= 0 && subscriptions.value.onNewSmsMessage[index]) {
        subscriptions.value.onNewSmsMessage[index].subscription.unsubscribe();

        subscriptions.value.onNewSmsMessage.splice(index, 1);
      }
    }

    function setAcceptingSMSContact(payload) {
      acceptingSMSContact.value = payload;
    }

    function setIsEndingSMSConvo(payload) {
      isEndingSMSConvo.value = payload;
    }
    function newSMSContactReceivedMutation(payload) {
      newSMSContactReceived.value = payload;
    }

    function setShowSMSContact(payload) {
      showSMSContact.value = payload;
    }
    function setSelectedThread(payload) {
      selectedThread.value = payload;
    }
    function process(payload) {
      isProcessing.value = payload;
    }
    function onNewContactReceived(payload) {
      let found = threads.value.find((contact) => contact.id === payload.ContactId);
      if (!found) threads.value.unshift(payload);
    }
    function removeConvo(payload) {
      let indx = threads.value.findIndex((thread) => thread.id === payload.id);
      if (indx >= 0) {
        threads.value.splice(indx, 1);
      }
    }

    function endConvo(payload) {
      let indx = threads.value.findIndex((thread) => thread.id === payload.id);
      if (indx >= 0) {
        threads.value.splice(indx, 1, {
          ...payload,
          Messages: threads.value[indx].Messages,
        });
      }
    }
    function onNewMessageReceived(payload) {
      let indx = threads.value.findIndex((thread) => thread.id === payload.ContactId);
      if (indx != -1) {
        threads.value[indx].Messages.unshift(payload);
      }
    }
    function addUnreadSMSThread(ContactId) {
      unreadSMSThreadIds.value.push(ContactId);
    }
    function removeUnreadSMSThread(ContactId) {
      unreadSMSThreadIds.value = unreadSMSThreadIds.value.filter((threadId) => threadId !== ContactId);
    }
    function clearUnreadSMSThread() {
      unreadSMSThreadIds.value = [];
    }
    function removeOwnedPhoneNumber(payload) {
      let index = ownedNumbers.value.items.findIndex((i) => i.id === payload);
      index === -1 || ownedNumbers.value.items.splice(index, 1);
    }
    function updateOwnedPhoneNumber(payload) {
      let index = ownedNumbers.value.items.findIndex((i) => i.id === payload.id);
      index === -1 || ownedNumbers.value.items.splice(index, 1, payload);
    }
    function addNewOwnedPhoneNumber(payload) {
      ownedNumbers.value.items.push(payload);
    }
    function setRemovingOwnedNumber(payload) {
      removingOwnedNumber.value = payload;
    }
    function setLoadingAvailableNumbers(payload) {
      loadingAvailableNumbers.value = payload;
    }
    function setLoadingOwnedNumbers(payload) {
      loadingOwnedNumbers.value = payload;
    }
    function setAvailableNumber(payload) {
      availableNumbers.value = payload;
    }
    function setOwnedNumbers(payload) {
      ownedNumbers.value = payload;
    }
    function onNewFlow(payload) {
      flows.value.items.push(payload);
    }
    function onUpdateFlow(payload) {
      let flowsIndex = _.findIndex(flows.value.items, function (v) {
        return v.id == payload.id;
      });

      flows.value.items.splice(flowsIndex, 1, payload);
    }
    function onDeleteFlow(payload) {
      let flowsIndex = _.findIndex(flows.value.items, function (v) {
        return v.id == payload.id;
      });

      flows.value.items.splice(flowsIndex, 1);
    }
    function initPhone() {
      subscriptions.value = {
        onNewSmsMessage: null,
        onNewSmsContact: null,
      };
      loadingOwnedNumbers.value = false;
      removingOwnedNumber.value = false;
      IncomingRingtone.value = null;
      isSendingSms.value = false;
      ownedNumbers.value = {
        items: [],
      };
      isProcessing.value = false;
      loadingAvailableNumbers.value = false;
      loadingFlows.value = false;
      flows.value = {
        items: [],
      };
      availableNumbers.value = {
        items: [],
      };
      unreadSMSThreadIds.value = [];
      selectedThread.value = null;
      threads.value = [];
      loadingThreads.value = false;
      acceptingSMSContact.value = false;
      newSMSContactReceived.value = null;
      showSMSContact.value = false;
      isEndingSMSConvo.value = false;
    }
    function updateState(value) {
      subscriptions.value = value.subscriptions;
      loadingOwnedNumbers.value = value.loadingOwnedNumbers;
      removingOwnedNumber.value = value.removingOwnedNumber;
      IncomingRingtone.value = value.IncomingRingtone;
      isSendingSms.value = value.isSendingSms;
      ownedNumbers.value = value.ownedNumbers;
      isProcessing.value = value.isProcessing;
      loadingAvailableNumbers.value = value.loadingAvailableNumbers;
      loadingFlows.value = value.loadingFlows;
      flows.value = value.flows;
      availableNumbers.value = value.availableNumbers;
      unreadSMSThreadIds.value = value.unreadSMSThreadIds;
      selectedThread.value = value.selectedThread;
      threads.value = value.threads;
      loadingThreads.value = value.loadingThreads;
      acceptingSMSContact.value = value.acceptingSMSContact;
      newSMSContactReceived.value = value.newSMSContactReceived;
      showSMSContact.value = value.showSMSContact;
      isEndingSMSConvo.value = value.isEndingSMSConvo;
    }

    function setSMSContactInQueue(payload) {
      smsContactInQueue.value = payload;
    }
    function onNewSmsContact() {
      return null;
    }
    function subscribeToAllOngoingEmailContacts() {
      return null;
    }
    function unsubscribeToAllOnNewSmsMessageSubscription() {
      return null;
    }
    function listOwnedPhoneNumbers() {
      return null;
    }
    function listSmsMessages() {
      return null;
    }
    return {
      //States
      subscriptions,
      loadingOwnedNumbers,
      removingOwnedNumber,
      IncomingRingtone,
      isSendingSms,
      ownedNumbers,
      isProcessing,
      loadingAvailableNumbers,
      loadingFlows,
      flows,
      availableNumbers,
      unreadSMSThreadIds,
      selectedThread,
      numberToDial,
      threads,
      loadingThreads,
      acceptingSMSContact,
      newSMSContactReceived,
      showSMSContact,
      isEndingSMSConvo,
      smsContactInQueue,
      //Getters
      newSMSContactReceivedGetter,
      showSMSContactgetter,
      isProcess,
      isLoadingFlows,
      listFlows,
      instanceNumbers,
      searchNumber,
      threadList,
      selectedThreadId,
      currentThread,
      isSending,
      isLoadingThreads,
      getUnreadSMSThreadIds,
      isAcceptingNewSMSContact,
      isEndingSMSConvoGetter,
      isSubscribeOnNewSmsMessageWithContactId,
      getSMSContactInQueue,
      //Actions
      createSmsFlow,
      updateSmsFlow,
      deleteSmsFlow,
      listSmsFlows,
      createMessage,
      acceptSmsConversation,
      onNewSmsMessage,
      onStatusChangeSmsContact,
      playIncomingSmsRingtone,
      stopIncomingSmsRingtone,
      playNewMessageRingtone,
      setPhoneSelectedThread,
      onNewSmsContact,
      subscribeToAllOngoingEmailContacts,
      unsubscribeToAllOnNewSmsMessageSubscription,
      listOwnedPhoneNumbers,
      listSmsMessages,
      //Mutations
      setNumberToDial,
      setSubscription,
      endSubscription,
      addOnNewSmsMessageSubscription,
      endOnNewSmsMessageSubscription,
      setAcceptingSMSContact,
      setIsEndingSMSConvo,
      newSMSContactReceivedMutation,
      setShowSMSContact,
      setSelectedThread,
      process,
      onNewContactReceived,
      removeConvo,
      endConvo,
      onNewMessageReceived,
      addUnreadSMSThread,
      removeUnreadSMSThread,
      clearUnreadSMSThread,
      removeOwnedPhoneNumber,
      updateOwnedPhoneNumber,
      addNewOwnedPhoneNumber,
      setRemovingOwnedNumber,
      setLoadingAvailableNumbers,
      setLoadingOwnedNumbers,
      setAvailableNumber,
      setOwnedNumbers,
      onNewFlow,
      onUpdateFlow,
      onDeleteFlow,
      initPhone,
      updateState,
      setSMSContactInQueue,
    };
  },
  {
    persist: true,
  }
);
