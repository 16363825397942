import { defineStore } from "pinia";
import { ref } from "vue";
import { onUpdateLivelookContactsSubscription, listLivelookContactsQuery } from "@/common/services/graphql.service";
import { useAgentsStore } from "./agents";
import { useIndexStore } from ".";

export const useLivelookStore = defineStore(
  "livelook",
  () => {
    const agentStore = useAgentsStore();
    const indexStore = useIndexStore();
    const list = ref([]);
    const debugMode = ref(false);

    function processLivelookEvent({ payload, queues }) {
      if (payload.Action === "remove") {
        removeContact(payload);
      } else {
        if (queues.includes(payload.QueueArn)) {
          if (payload.Action === "insert") insertContact(payload);
          if (payload.Action === "update") updateContact(payload);
        }
      }
    }
    async function listLivelookContacts({ InstanceId, Queues }) {
      const contacts = await listLivelookContactsQuery({ InstanceId, Queues });

      return hydrateList(contacts?.listLivelookContacts.items);
    }
    function onUpdateLivelookContacts(InstanceId) {
      const observable = onUpdateLivelookContactsSubscription({ InstanceId });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateLivelookContacts;

          if (newData) {
            let queues = agentStore.queuetags
              .filter((q) => q.queueARN)
              .map((q) => q.queueARN)
              .flat();

            processLivelookEvent({ payload: newData, queues });
          }

          return Promise.resolve(newData);
        },
      };

      indexStore.addSubscriptionToList({ id: "onUpdateLivelookContacts", observable, handler });
    }
    function insertContact(payload) {
      let contactToInsert = list.value.findIndex((contact) => contact.id === payload.id);
      if (contactToInsert !== -1) return;
      list.value.push(payload);
    }
    function updateContact(payload) {
      let contactToUpdate = list.value.findIndex((contact) => contact.id === payload.id);
      list.value.splice(contactToUpdate, 1, payload);
    }
    function removeContact(payload) {
      for (let contactIndex in list.value) {
        if (list.value[contactIndex].id === payload.id) list.value.splice(contactIndex, 1);
      }
    }
    function hydrateList(payload) {
      list.value = payload;
    }
    return {
      //States
      list,
      debugMode,
      //Actions
      processLivelookEvent,
      listLivelookContacts,
      onUpdateLivelookContacts,
      //Mutations
      insertContact,
      updateContact,
      removeContact,
      hydrateList,
    };
  },
  {
    persist: true,
  }
);
