import { defineStore } from "pinia";
import { ref } from "vue";

export const useMediaDevicesStore = defineStore(
  "mediaDevices",
  () => {
    const audioOutputDevices = ref([]);

    async function enumerateAudioOutputDevices() {
      initializeDevice([]);
      let data = await navigator.mediaDevices.enumerateDevices();
      if (data) {
        data
          .filter((d) => d.kind === "audiooutput")
          .forEach((d) => {
            let found = audioOutputDevices.value.find((i) => i.deviceId === d.deviceId);

            if (!found) {
              addDevice(d);
            }
          });
      }
    }

    function addDevice(payload) {
      audioOutputDevices.value.push(payload);
    }
    function initializeDevice(payload) {
      audioOutputDevices.value = payload;
    }
    function initMediaDevices() {
      audioOutputDevices.value = [];
    }

    function updateState(value) {
      audioOutputDevices.value = value.audioOutputDevices;
    }

    return {
      //States
      audioOutputDevices,
      //Actions
      enumerateAudioOutputDevices,
      //Mutations
      addDevice,
      initializeDevice,
      initMediaDevices,
      updateState,
    };
  },
  {
    persist: true,
  }
);
