import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { createTranslationMutation } from "@/common/services/graphql.service";

export const useTranslationsStore = defineStore(
  "translations",
  () => {
    const translationText = ref("");
    const translationActive = ref(false);
    const SmsTranslationActive = ref(false);
    const agentMessage = ref("");
    const customerSMS = ref("");
    const EmailTranslationActive = ref(false);
    const agentOriginalEmail = ref("");
    const EmailCustomerLanguage = ref("");
    const TranslationResponse = ref({});

    const getTranslation = computed(() => translationText.value);
    const getTranslationActive = computed(() => translationActive.value);
    const getAgentMessage = computed(() => agentMessage.value);
    const getcustomerSMS = computed(() => customerSMS.value);
    const getSMSTranslationActive = computed(() => SmsTranslationActive.value);
    const getEmailTranslationActive = computed(() => EmailTranslationActive.value);
    const getAgentOriginalEmail = computed(() => agentOriginalEmail.value);
    const getEmailCustomerLanguage = computed(() => EmailCustomerLanguage.value);

    async function createTranslation(payload) {
      try {
        const res = await createTranslationMutation(payload);

        let translationPayload = res?.createTranslation;
        storeTranslationResponseMutation(translationPayload);
        return translationPayload;
      } catch (error) {
        return Promise.reject("Error", error);
      }
    }
    function storeTranslation(payload) {
      storeTranslationMutation(payload);
    }
    function setTranslationActive(payload) {
      setTranslationActiveMutation(payload);
    }
    function storeAgentMessage(payload) {
      storeAgentMessageMutation(payload);
    }
    function setSMSTranslationActive(payload) {
      setSMSTranslationActiveMutation(payload);
    }
    function setEmailTranslationActive(payload) {
      setEmailTranslationActiveMutation(payload);
    }
    function setAgentOriginalEmail(payload) {
      setAgentOriginalEmailMutation(payload);
    }
    function setEmailCustomerLanguage(payload) {
      setEmailCustomerLanguageMutation(payload);
    }

    function storeTranslationMutation(payload) {
      translationText.value = payload;
    }
    function storeAgentMessageMutation(payload) {
      agentMessage.value = payload;
    }
    function setTranslationActiveMutation(payload) {
      translationActive.value = payload;
    }
    function storecustomerSMSMutation(payload) {
      customerSMS.value = payload;
    }
    function setSMSTranslationActiveMutation(payload) {
      SmsTranslationActive.value = payload;
    }
    function setEmailTranslationActiveMutation(payload) {
      EmailTranslationActive.value = payload;
    }

    function setAgentOriginalEmailMutation(payload) {
      agentOriginalEmail.value = payload;
    }
    function setEmailCustomerLanguageMutation(payload) {
      EmailCustomerLanguage.value = payload;
    }
    function storeTranslationResponseMutation(payload) {
      TranslationResponse.value = payload;
    }
    return {
      //States
      translationText,
      translationActive,
      SmsTranslationActive,
      agentMessage,
      customerSMS,
      EmailTranslationActive,
      agentOriginalEmail,
      EmailCustomerLanguage,
      TranslationResponse,
      //Getters
      getTranslation,
      getTranslationActive,
      getAgentMessage,
      getcustomerSMS,
      getSMSTranslationActive,
      getEmailTranslationActive,
      getAgentOriginalEmail,
      getEmailCustomerLanguage,
      //Actions
      createTranslation,
      storeTranslation,
      setTranslationActive,
      storeAgentMessage,
      setSMSTranslationActive,
      setEmailTranslationActive,
      setAgentOriginalEmail,
      setEmailCustomerLanguage,
      //Mutations
      storeTranslationMutation,
      storeAgentMessageMutation,
      setTranslationActiveMutation,
      storecustomerSMSMutation,
      setSMSTranslationActiveMutation,
      setEmailTranslationActiveMutation,
      setAgentOriginalEmailMutation,
      setEmailCustomerLanguageMutation,
      storeTranslationResponseMutation,
    };
  },
  {
    persist: true,
  }
);
