import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { createSupportCaseMutation, listSupportCasesQuery } from "@/common/services/graphql.service";

export const useSupportStore = defineStore(
  "support",
  () => {
    const list = ref({
      items: [],
    });

    const support = computed(() => list.value.items);

    async function createSupportCase(payload) {
      try {
        const res = await createSupportCaseMutation(payload);
        getSupportCaseData({ account: payload.account });
        return res?.createSupportCase;
      } catch (error) {
        return Promise.reject("Error", error);
      }
    }
    async function getSupportCaseData({ account }) {
      let res = await listSupportCasesQuery(account);
      setCaseList(res?.listSupportCases);
    }
    function addCase(payload) {
      list.value.items.push(payload);
    }
    function setCaseList(payload) {
      list.value = {
        items: payload.items,
      };
    }
    function setCaseDetails(payload) {
      list.value.items.push(payload);
    }

    return {
      //States
      list,
      //Getters
      support,
      //Actions
      createSupportCase,
      getSupportCaseData,
      //Mutations
      addCase,
      setCaseList,
      setCaseDetails,
    };
  },
  {
    persist: true,
  }
);
